<template>
  <div class="jh-container is-call-main jh-cols is-space-10">
    <div class="is-col-fix" style="width: 755px;">
      <Call-Cust-Info  ref="callCustInf"
        :CallCustInfo="msg"
        @print="val => printMessage(val)"
        @selSang="val => setSangdam(val)"
        @initScr="item => initMainInfo(item)"
        @selAddrDtl="item => acctDtlSrch(item)"
        @selAddr="item => setAddr(item)"
      ></Call-Cust-Info>
      <Call-Account  ref="callAccount"
        :CallAccount="msg"
        :param="param"
        :accInfo="accInfo"
        :repAccInfo="repAccInfo"
        @chgAddr="item => acctDtlSrch2(item)"
        @selCust="item => selCustInfo(item)"
      ></Call-Account>
      <Call-Cust-History  ref="callCustHist"
        :CustHistory="msg"
        :custId="srchCustNo"
        :custSpstList="custSpstList"
        :param="param"
        @chgAddr="item => searchAddrInfo(item)"
      ></Call-Cust-History>
    </div>
    <div>
      <Call-Sangdam  ref="callSangdam"
        :CallSangdam="msg"
        :cnslInfoM="cnslInfoM"
        :cnslHistNo="cnslHistNo"
        @initScr="item => initMainInfo(item)"
        @selCnslHist="item => setCnslHist(item)"
        @reqFnssSvc="item => fnSendFnss(item)"
        @openVmsPop="item => vmsPopOpen(item)"
        @openRsvCallPop="item => rsvCallPopOpen(item)"
      ></Call-Sangdam>
      <Call-Cnsl-History ref="callCnslHist"
        :CnslHistory="msg"
        :cnslhist="cnslhist"
        :cnslHistPageNo="PAGE_NO"
        :srchCustNo="srchCustNo"
        :lastPageYn="lastPageYn"
        @input="custInfo = $event"
        @selCnslHist="item => setCnslHist(item)"
        @selSangdam="item => setSangdam(item)"
      ></Call-Cnsl-History>
      <VmsPopup ref="vmsPopup" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtVMSFromWinPop="val=>onRecvWinPop(val)"></VmsPopup>
      <PT100201Pop ref="pT100201Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100201Pop>
      <PT100301Pop ref="pT100301Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100301Pop>
      <PT100401Pop ref="pT100401Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100401Pop>
      <PT100501Pop ref="pT100501Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100501Pop>
      <PT100601Pop ref="pT100601Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100601Pop>
      <PT100701Pop ref="pT100701Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100701Pop>
      <PT100801Pop ref="pT100801Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromRsvPop="val=>onRecvRsvPopFromOB(val)"></PT100801Pop>
    </div>
  </div>
</template>

<script>
import CallCustInfo from "@/views/call/CallCustInfo.vue";
import CallAccount  from "@/views/call/CallAccount.vue";
import CustHistory  from "@/views/call/CustHistory.vue";
import CallSangdam  from "@/views/call/CallSangdam.vue";
import CnslHistory  from "@/views/call/CnslHistory.vue";
import WinPop from "@/views/popup/WinPop.vue";
import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import { eventBus } from "@/store/eventBus.js";
import { mapGetters } from "vuex"

export default {
  name: "MENU_E010101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {    
    "Call-Cust-Info": CallCustInfo,
    "Call-Account": CallAccount,
    "Call-Cust-History": CustHistory,
    "Call-Sangdam": CallSangdam,
    "Call-Cnsl-History": CnslHistory,
    'VmsPopup' : WinPop,
    'PT100201Pop' : WinPop,
    'PT100301Pop' : WinPop,
    'PT100401Pop' : WinPop,
    'PT100501Pop' : WinPop,
    'PT100601Pop' : WinPop,
    'PT100701Pop' : WinPop,
    'PT100801Pop' : WinPop    
  },
  data() {
      return {
        ASP_CUST_KEY: '001',
        accInfo:   {},
        repAccInfo: {},
        custInfo:  {},  //고객 정보 객체
        cnslInfoM: {},
        cnslhist: [],
        custSpstList: [],
        param: {page: 'E010101'},
        PAGE_NO: 1,
        cnslHistPageNo: 1,
        lastPageYn: false,
        cnslHistNo: "",
        srchAcntNo: "",
        srchCustNo: "",
        accountPw: "",
        setCustMode: "",
        
        //IVR정보
        ivrInNo: "",
        ivrInPath: "",
        stdTime: "",
        errMent: "",
        msg: '메인입니다.'
      }
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geCustInfo: 'callStore/GE_CUST_INFO',
      geCtiInfo: 'callStore/GE_CTI_INFO',
      geLastCustID: 'callStore/GE_LAST_CUST_ID',
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
      geCustInfoPrev: 'callStore/GE_CNSL_CUST_PREV'      
    }),
  },
  watch: {
    geCustInfo: function(val) {
      const obj = val;

      if (obj.CLNT_NO != this.geLastCustID) {
        if (this.geCallStt == 'A_SETACW') {
          // this.ivrInNo = '';
          // this.ivrInPath = '';
          // this.stdTime = ''; 
          // this.errMent = '';
          this.$refs.callSangdam.setIvrClr();
        }
      }
    }
  },
  beforeDestroy(){
    //console.log("E010101 DESTROY!!!!");
  },
  created() {
    this.custInfo = this.$store.getters["callStore/GE_CUST_INFO"];
    //this.custInfo.SRCH_ACCOUNT = "";
    //this.custInfo.SRCH_CL_CID = "";
    eventBus.$on('sendVmsData', (state) => {
      this.sendVmsPop(state);
    });
  },
  mounted() { 
    this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y"); //상담저장여부
    this.serchCustInf();

    

    eventBus.$on('setIvrInf', (pVal) => {
      this.$refs.callSangdam.setIvrClr();
       
      if(!this.mixin_isEmpty(pVal.ivrInCd) && pVal.ivrInCd.length == 4){
        console.log("호전환 셋팅1 : "+pVal.ivrInCd);
      }
      // if(!this.mixin_isEmpty(pVal.ivrInCd) && pVal.ivrInCd.length == 4) {
      //   this.ivrInPath = pVal.ivrInCd;
      // }else{
      //   this.ivrInPath = pVal.ivrInPath;
      // }
      // this.ivrInNo   = pVal.ivrInNo;
      // this.stdTime   = pVal.stdTime;
      // this.errMent   = pVal.errMent;
      this.initEtc();
      let custInfo = {};
      if(pVal.COMP_TYPE == "1" || pVal.COMP_TYPE == "2" || pVal.COMP_TYPE == "X") {
        if(pVal.COMP_TYPE == "1" && pVal.COMP_RST == "Y") {
          console.log("$$$$$$111");
          custInfo.COMP_TYPE    = "1";
          custInfo.COMP_RST     = "Y";
          custInfo.SRCH_ACCOUNT = pVal.ACCT_NO;
          custInfo.ACCT_NM      = pVal.ACCT_NM;
          custInfo.ACCT_PWD     = pVal.ACCT_PWD;
          custInfo.STYPE        = "1";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
          //this.setCustMode    = "search";
        } else
        if(pVal.COMP_TYPE == "2" && pVal.COMP_RST == "Y") {
          console.log("$$$$$$222");
          custInfo.COMP_TYPE   = "2";
          custInfo.COMP_RST    = "Y";
          custInfo.SRCH_ACCOUNT = pVal.ACCT_NO;
          custInfo.ACCT_NM      = pVal.ACCT_NM;
          custInfo.ACCT_PWD     = pVal.ACCT_PWD;
          custInfo.CLNT_NO      = pVal.RRNO;
          custInfo.STYPE       = "3";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
          //this.setCustMode   = "search2";
        } else
        if(pVal.COMP_TYPE == "X" || pVal.COMP_RST == "N") {
          console.log("$$$$$$333");
          custInfo.COMP_TYPE    = pVal.COMP_TYPE;
          custInfo.COMP_RST     = pVal.COMP_RST;
          custInfo.SRCH_ACCOUNT = pVal.ACCT_NO;
          custInfo.ACCT_NM      = pVal.ACCT_NM;
          custInfo.ACCT_PWD     = pVal.ACCT_PWD;
          custInfo.STYPE        = "1";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
        } else {
          console.log("$$$$$$444_1");
          custInfo.CL_CID      = "999999-9999999";
          custInfo.SRCH_CL_CID = "9999999999999";
          custInfo.CNM         = "미인증고객";
          custInfo.STYPE       = "setCustInf";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
          //this.setCustMode   = "setCustInf";
        }
      } else if(pVal.COMP_TYPE == "E") {
        console.log("$$$$$$EEE-1");
        custInfo.CL_CID      = "999999-9999999";
        custInfo.SRCH_CL_CID = "9999999999999";
        custInfo.CNM         = "미인증고객";
        custInfo.STYPE       = "setCustE";  //신통미처리
        this.$store.commit("callStore/MU_CUST_INFO", custInfo);
        this.$refs.callCustInf.setCustMM(custInfo);
      } else {
        if(!this.mixin_isEmpty(pVal.ACCT_NO)) {
          console.log("$$$$$$444_2");
          custInfo.SRCH_ACCOUNT = pVal.ACCT_NO;
          custInfo.ACCT_NM      = pVal.ACCT_NM;
          custInfo.ACCT_PWD     = pVal.ACCT_PWD;
          custInfo.CLNT_NO      = pVal.RRNO;
          custInfo.STYPE        = "1";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
          //this.setCustMode   = "setCustInf";
        } else {
          console.log("$$$$$$555");
          custInfo.CL_CID      = "999999-9999999";
          custInfo.SRCH_CL_CID = "9999999999999";
          custInfo.CNM         = "미인증고객";
          custInfo.STYPE       = "setCustInf";
          this.$store.commit("callStore/MU_CUST_INFO", custInfo);
          this.$refs.callCustInf.setCustMM(custInfo);
          //this.setCustMode   = "search";
        }
      }

      if(!this.mixin_isEmpty(pVal.ivrInCd) && pVal.ivrInCd.length == 4){
        console.log("호전환 셋팅 : "+pVal.ivrInCd);
      }
      // if(!this.mixin_isEmpty(pVal.ivrInCd) && pVal.ivrInCd.length == 4) {
      //   this.ivrInPath = pVal.ivrInCd;
      // }else{
      //   this.ivrInPath = pVal.ivrInPath;
      // }
      // this.ivrInNo   = pVal.ivrInNo;
      // this.stdTime   = pVal.stdTime;
      // this.errMent   = pVal.errMent;
      // alert("222222222222222222222222222222[" + this.ivrInNo +"]");
      
      this.$refs.callSangdam.setIvrData(pVal);
      console.log("$$$$$$666");
    });
    
    eventBus.$on('clrCallInf', (pVal) => {
      console.log("$$$$$$clrCallInf"+ pVal);

      if(pVal == "schSangdam") {
        if(!this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
          this.selectCnslHistInf(this.geCustInfo.CLNT_NO);
        } else {
          console.log("===================고객번호없음!!!");
        }
        return false;
      }

      if(this.$refs.callCustInf) {
        this.$refs.callCustInf.clrCusPwd(pVal);
      }
      // this.ivrInNo   = "";
      // this.ivrInPath = "";
      // this.stdTime   = "";
      // this.errMent   = "";
      this.$store.commit('callStore/MU_CTI_INFO', {});
    });

    eventBus.$on('sendOnRecvRsvPop', (state) => {
      this.onRecvRsvPopFromOB(state);
    });
  },
  methods: {
    
    printMessage(val) {
      if(val) 
      this.common_toast(val, "done");
    },
    evtCloseWinPopup(val) {
      console.log("=====>clsPop" + val);
    },
    /* 초기 고객정보 인입시 정보 조회 */
    serchCustInf() {
      let item = {};
      if(!this.mixin_isEmpty(this.geCustInfo.SRCH_ACCOUNT)) {
        item.ACCT_NO = this.geCustInfo.SRCH_ACCOUNT;
        this.setCust(item);
      } else if(!this.mixin_isEmpty(this.geCustInfo.CUST_ACTNO)) {
        item.ACCT_NO = this.geCustInfo.CUST_ACTNO;
        this.setCust(item);
      } else if(!this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
        item.SRCH_CL_CID = this.geCustInfo.SRCH_CL_CID;
        this.setCust(item);
      } else if(!this.mixin_isEmpty(this.geCustInfo.CUST_NO)) {
        item.CUST_NO = this.geCustInfo.CUST_NO;
        this.setCust(item);
      }
    },
    /* 고객정보 조회 */
    setCust(item) {
      if(item.ACCT_NO) eventBus.cusSearch('1', item.ACCT_NO);
      else if(item.SRCH_CL_CID) eventBus.cusSearch('2', item.SRCH_CL_CID);
      else if(item.CUST_NO) eventBus.cusSearch('3', item.CUST_NO);
      //"Call-Cust-History": CustHistory,
      //this.$CustHistory.$props('selectCusSpst', item.CUST_NO);
    },
    initEtc(){
      this.cnslhist     = [];
      this.accInfo      = {
        HOME_ADDR_TYPE : "",
        OFFC_ADDR_TYPE : ""
      };
      this.repAccInfo   = {
        HOME_ADDR_TYPE : "",
        OFFC_ADDR_TYPE : ""
      };
		  this.custSpstList = [];
		  this.lastPageYn   = false;
      //this.srchCustNo   = "";
		  this.$refs.callSangdam.initCnslInfo();
      this.$refs.callCnslHist.initData();
    },
    initMainInfo(item) {
      console.log(">>>>initMainInfo:[" + item + "]");

      try{

          this.$store.dispatch('callStore/AC_PLAY_YN', "N");
          this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "");

          if(item == "SEL") {
            console.log(">>>>initMainInfo geCallStt:[" + this.geCallStt  + "]");
            if(this.geCallStt != "TALKING" && this.geCallStt != "HOLD") {
              // this.ivrInNo      = "";
              // this.ivrInPath    = "";
              // this.stdTime      = "";
              // this.errMent      = "";
              this.$refs.callSangdam.setIvrClr();
              this.geCtiInfo.CALL_ID = "";
              this.geCtiInfo.RDWT_ID = "";
              this.geCtiInfo.CALL_TYPE = "";
              this.$store.commit('callStore/MU_CTI_INFO', this.geCtiInfo);
            } 
            return;
          }
          //this.setCustMode = "";

          this.cnslhist     = [];
          this.accInfo      = {
            HOME_ADDR_TYPE : "",
            OFFC_ADDR_TYPE : ""
          };
          this.repAccInfo   = {
            HOME_ADDR_TYPE : "",
            OFFC_ADDR_TYPE : ""
          };
          this.custSpstList = [];
          //`this.setCustMode  = item; //초기화
          this.lastPageYn   = false;
          this.srchCustNo   = "";

          //IVR인입정보
          if(item == "INFCLR") {
            this.fnCtiClr();
          } 
          
          if(item != "initEtc") {
            this.$refs.callCustInf.initCustInfo();
          }
          
          if(this.mixin_isEmpty(this.geCtiInfo.CALL_ID)) {
            this.fnCtiClr();
          }

          this.$refs.callSangdam.initCnslInfo();
          this.$refs.callCnslHist.initData();
      }catch(e) {
       
        console.log("=============초기화 실패:"+e) ;
        return;
      }finally{
       console.log("=============초기화 성공") ;
      }

      return;
    },
    fnCtiClr() {
      this.geCtiInfo.RDWT_ID = "";
      this.geCtiInfo.CALL_TYPE = "";
      this.geCtiInfo.IVR_IN_NO    = "";
      this.geCtiInfo.IVR_SERV_NO  = "";
      this.geCtiInfo.IVR_IN_NO    = "";
      this.geCtiInfo.IVR_IN_PATH  = "";
      this.geCtiInfo.ERR_MENT     = "";
      this.geCtiInfo.BEGIN_DT     = "";
      this.geCtiInfo.BEGIN_TM     = "";
      this.geCtiInfo.IVR_STD_TIME  = "";  
      this.geCtiInfo.END_DT    = "";
      this.geCtiInfo.END_TM    = "";
      this.$store.commit('callStore/MU_CTI_INFO', this.geCtiInfo); 
    },
    /* 계좌주소, 특이내역, 상담이력 조회 */
    setAddr(item) {

      this.srchCustNo = item.CLNT_NO;
      this.$refs.callSangdam.initCnslInfo();

      this.$refs.callCustHist.initData();
      this.$refs.callAccount.initData();
      this.$refs.callCnslHist.initData();


      //this.setCustMode = "";
      if(this.mixin_isEmpty(item.ACCOUNT)) {
        this.initEtc();
        this.setAccInfo();
        this.setRepAccInfo();
        this.selectCusSpstCntn(item.CLNT_NO);
        this.selectCnslHistInf(item.CLNT_NO);
        return;
      }
      if(!this.mixin_isEmpty(item.CNSL_HIST_NO)) this.cnslHistNo = item.CNSL_HIST_NO;
      this.srchAcntNo = item.ACCOUNT;
      this.accountPw = item.ACCOUNT_PW;
      if(!this.mixin_isEmpty(item.ACCOUNT)) this.srchAcntNo = this.srchAcntNo.replaceAll("-","");
      this.selectAccountInfo('1', this.srchAcntNo, this.srchCustNo, this.accountPw, item.acctConf,item.HirnkAcntNo, item.AcntStatCode);
      //this.selectAccountInfo('2', this.srchAcntNo, this.srchCustNo, this.accountPw, item.acctConf,item.HirnkAcntNo);
      this.setRepAccInfo();
      this.selectCusSpstCntn(this.srchCustNo);
      this.selectCnslHistInf(this.srchCustNo);

      try {
        if(this.geCallStt == 'TALKING') 
          this.$refs.callSangdam.CNSL_CNTN = item.CNSL_CNTN;
      } catch(ex) {
        console.log("상담정보 처리중 에러발생!! - "+ex);
      }
    },
    setAccInfo() {
        // this.repAccInfo.ACCOUNT  = acntNo;
        // this.repAccInfo.ACCOUNT_PW = acntPw;
        // this.repAccInfo.HIRNK_ACNT_NO = hirnkAcntNo;
        this.accInfo.MOB_TNO  = this.geCustInfo.MOVE_TNO;
        this.accInfo.HOME_TNO = this.geCustInfo.HOME_TNO;
        this.accInfo.FAX_NO   = this.geCustInfo.OFFC_FAXN;
        
        this.accInfo.OFFC_TNO   = this.geCustInfo.OFFC_TNO;
        this.accInfo.EMAIL_ADDR = this.geCustInfo.EMAIL_ADDR;
        
        if(this.geCustInfo.SRCH_ACCOUNT == this.geCustInfo.DIRECT_ACNT_NO) {
          this.accInfo.PHONE_NO   = this.geCustInfo.DIRECT_TNO;
        } else {
          this.accInfo.PHONE_NO   = "";
        }

        this.accInfo.HOME_ADDR_TYPE = this.geCustInfo.HOME_ADDR_TYPE;
        this.accInfo.HOME_POST_NO   = this.geCustInfo.HOME_PSNO;
        this.accInfo.HOME_ADDR      = this.geCustInfo.HOME_ADDR;
        this.accInfo.HOME_ADDR_DTL  = this.geCustInfo.HOME_ADDR_DETAIL;

        this.accInfo.OFFC_ADDR_TYPE = this.geCustInfo.OFFC_ADDR_TYPE;
        this.accInfo.OFFC_POST_NO   = this.geCustInfo.OFFC_PSNO;
        this.accInfo.OFFC_ADDR      = this.geCustInfo.OFFC_ADDR;
        this.accInfo.OFFC_ADDR_DTL  = this.geCustInfo.OFFC_ADDR_DETAIL;
    },
    setRepAccInfo() {
        // this.repAccInfo.ACCOUNT  = acntNo;
        // this.repAccInfo.ACCOUNT_PW = acntPw;
        // this.repAccInfo.HIRNK_ACNT_NO = hirnkAcntNo;
        this.repAccInfo.MOB_TNO  = this.geCustInfo.MOVE_TNO;
        this.repAccInfo.HOME_TNO = this.geCustInfo.HOME_TNO;
        this.repAccInfo.FAX_NO   = this.geCustInfo.OFFC_FAXN;
        
        this.repAccInfo.OFFC_TNO   = this.geCustInfo.OFFC_TNO;
        this.repAccInfo.EMAIL_ADDR = this.geCustInfo.EMAIL_ADDR;
        
        if(this.geCustInfo.SRCH_ACCOUNT == this.geCustInfo.DIRECT_ACNT_NO) {
          this.repAccInfo.PHONE_NO   = this.geCustInfo.DIRECT_TNO;
        } else {
          this.repAccInfo.PHONE_NO   = "";
        }

        this.repAccInfo.HOME_ADDR_TYPE = this.geCustInfo.HOME_ADDR_TYPE;
        this.repAccInfo.HOME_POST_NO   = this.geCustInfo.HOME_PSNO;
        this.repAccInfo.HOME_ADDR      = this.geCustInfo.HOME_ADDR;
        this.repAccInfo.HOME_ADDR_DTL  = this.geCustInfo.HOME_ADDR_DETAIL;

        this.repAccInfo.OFFC_ADDR_TYPE = this.geCustInfo.OFFC_ADDR_TYPE;
        this.repAccInfo.OFFC_POST_NO   = this.geCustInfo.OFFC_PSNO;
        this.repAccInfo.OFFC_ADDR      = this.geCustInfo.OFFC_ADDR;
        this.repAccInfo.OFFC_ADDR_DTL  = this.geCustInfo.OFFC_ADDR_DETAIL;
    },
    /* 고객정보 재조회 */
    selCustInfo(item) {
      let custInfo = {};
      custInfo.SRCH_ACCOUNT = item.ACCOUNT;
      custInfo.STYPE = "1";
      this.$store.commit("callStore/MU_CUST_INFO", custInfo);

      this.$refs.callCustInf.setCustMM(custInfo);
    },
    /* 상담이력 재조회 */
    setCnslHist(item) {
      this.$refs.callCnslHist.initData();
      this.selectCnslHistInf(item.CLNT_NO);
    },
    /* 계좌 정보 상세 조회 */
    acctDtlSrch(item) {
      //console.log("acctDtlSrch" + item.acntNo + "/" + item.accountPw + "/" + item.HirnkAcntNo  + "/STT:" + item.AcntStatCode);
      this.$refs.callAccount.initData();
      this.$refs.callCnslHist.initData();
      this.selectAccountInfo(item.type, item.acntNo, this.srchCustNo, item.accountPw, item.acctConf, item.HirnkAcntNo, item.AcntStatCode);
    },
    /* 계좌 정보 상세 조회 */
    acctDtlSrch2(item) {
      //console.log("acctDtlSrch2" + item.acntNo + "/" + item.accountPw + "/" + item.HirnkAcntNo  + "/STT:" + item.AcntStatCode);
      this.selectAccountInfo(item.type, item.acntNo, this.srchCustNo, item.accountPw, item.acctConf, item.HirnkAcntNo, item.AcntStatCode);
    },
     /* 상담내용 상세조회 */
    setSangdam(item) {
      // console.log("SELSANGDAM!!!" + item);
      // if(!this.mixin_isEmpty(item)) {
      //   console.log("SELSANGDAM START!!~~~~~~~~~~~~~~~~~~~");
      //   // this.$refs.callSangdam.selectCnslHistDtl(item);
      // }
    },
  vmsPopOpen(item) {
    if (item.scrId == 'P100701') {
      this.openWinPop('P100701', item, '800', '545');
    } else if (item.scrId == 'P100801') {
      this.openWinPop('P100801', '', '1280','690');
    }
  },
  rsvCallPopOpen(item) {
    const uri = '/' + item.scrId;
    const width = item.width;
    const height = item.height;
    if(item.scrId == "PT100201")  this.$refs.pT100201Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100301")  this.$refs.pT100301Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100401")  this.$refs.pT100401Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100501")  this.$refs.pT100501Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100601")  this.$refs.pT100601Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100701")  this.$refs.pT100701Pop.openRsvPop(uri, width, height);
    else if(item.scrId == "PT100801")  this.$refs.pT100801Pop.openRsvPop(uri, width, height);
    else this.$refs.vmsPopup.openRsvPop(uri, width, height);
  },
  /* finesse 서비스 요청처리  */
  fnSendFnss(item) {        
    //eventBus.ctiSendReq('consultCall', "65130");
    console.log("fnSendFnss&&&&&&&&&&&&&&&&&&&&&&&&&&&&&" + item.sval);
    eventBus.ctiSendReq(item.stype, item.sval);
  },
  openWinPop(scrId, item, width, height) {
    let uri = '';
    
    if(scrId == 'P100701') {
      uri = '/' + scrId + '?cd=' + item.param + '&custNo='+ item.custNo + '&callId=' + item.callId;

    } else if (scrId == 'P100801') {
      uri = '/'+scrId;
    }

    this.$refs.vmsPopup.openVMSPop(uri, width, height);
  },
  onRecvWinPop( recvObj ) {
    let pNum = "";
    //console.log(recvObj);

    if(recvObj.evt != null) {
      switch(recvObj.evt) {
        case "message" :
          this.common_alert(recvObj.Val1, 'error');
          break;
        case "CONSULT" :
          this.$store.commit('callStore/MU_PLAY_YN', recvObj.plagYN);
          this.$refs.vmsPopup.sendEvtToChild( { msg: recvObj.plagYN} );
          pNum = recvObj.Val1;
          if(this.mixin_isEmpty(pNum)) {
            this.common_toast("전환 요청 번호가 없습니다!!", 'info');
            console.log("전환 요청 번호가 없습니다!!");
            //this.sendToChild(1); // 3자통화
            return false;
          }
          let reqParam = {
            stype: "consultCall"
           ,sval: pNum
          }
          this.fnSendFnss(reqParam);
          break;
        case "CONSULT2" :
          pNum = recvObj.Val1;
          if(this.mixin_isEmpty(pNum)) {
            this.common_toast("전환 요청 번호가 없습니다!!", 'info');
            console.log("전환 요청 번호가 없습니다!!");
            //this.sendToChild(1); // 3자통화
            return false;
          }
          let reqParam2 = {
            stype: "consultCall2"
           ,sval: pNum
          }

          if(pNum == '65130' || pNum == '65131' || pNum == '65132') {
            this.$store.dispatch("callStore/AC_CUST_INOUT", this.geCtiInfo.CALL_TYPE);
          }
          this.fnSendFnss(reqParam2);
          break;
      }
    }
  },
  async fnCnsltHistCheck(val) {
      //console.log(">>>>>>>>>>>>>>>>>>>>>>JJJJJJJ");

      let updateYN = false;

      let requestData1 = {
        headers: {
          SERVICE: 'phone.main.cnslt-enddttm',
          METHOD: 'inquire',
          TYPE: "BIZ_SERVICE",
        },
        sendData: {}
      };

      requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
      requestData1.headers["METHOD"] = "select";
      requestData1.headers["ASYNC"] = false;
      
      requestData1.sendData['CNSL_BEGIN_DATE'] = this.geCtiInfo.END_DT;
      requestData1.sendData['ASP_CUST_KEY'] = '001';
      requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
      requestData1.sendData['END_YN']  = val;
      requestData1.sendData['REG_ID']  = this.geUserRole.userId;

      const response1 = await this.common_postCall_noPB(requestData1);

      if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
        updateYN = true;
      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>KKKKKK"+ updateYN);
      return updateYN;
    },          
  async onRecvRsvPopFromOB(recvObj) {
      var cnsltHistCheck = await this.fnCnsltHistCheck("Y")

    console.log('E010101 >>>> onRecvRsvPopFromOB');
    console.log('OB팝업에서 넘어왔을 때');
    if (this.geCallStt == 'TALKING') {
      this.common_alert(`통화중입니다.`, 'info');
    } else if (this.geCallStt == 'A_SETACW' && this.geCnslSaveYn == 'N') {
        if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || cnsltHistCheck == false){
          this.common_alert(`상담이력을 저장해주세요.`, 'info');
          return false;
        }else{
          this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
        }
    } else {
      recvObj.ACCT_NO = recvObj.SRCH_ACCOUNT;
      recvObj.POP_YN = 'Y';
  
      // IVR 정보 셋팅
      let pVal = {
        ivrInPath : recvObj.IVR_IN_PATH
        ,ivrInNo : recvObj.IVR_IN_NO
        ,stdTime : recvObj.IVR_STD_TIME
        ,errMent : recvObj.IVR_MENT
      }
      // this.ivrInNo = recvObj.IVR_IN_NO;
      // this.ivrInPath = recvObj.IVR_IN_PATH;
      // this.stdTime = recvObj.IVR_STD_TIME;
      // this.errMent = recvObj.IVR_MENT;

      this.$refs.callSangdam.setIvrData(pVal);

      // callStore 커밋
      this.$store.commit('callStore/MU_CUST_INFO', recvObj);

      // this.geCtiInfo.ARS_YN = "Y";
      // this.geCtiInfo.ARS_IN_PATH  = recvObj.IVR_IN_PATH;
      // this.geCtiInfo.ARS_IN_NO    = recvObj.IVR_IN_NO;
      // this.geCtiInfo.ARS_STD_TIME = recvObj.IVR_STD_TIME;
      // this.geCtiInfo.ARS_MENT     = recvObj.IVR_MENT;
      // this.$store.commit('callStore/MU_CTI_INFO', this.geCtiInfo);
  
      // callSangdam init
      this.$refs.callSangdam.initMain();
      this.$refs.callSangdam.initCnslInfo();
      
      this.setCust(recvObj);
    }
  },
  sendToChild(val) {
    if(val == 1) {
      this.$refs.winPopup.sendEvtToChild( { msg: 'consult'} );
    } else if(val == 2) {
      this.$refs.winPopup.sendEvtToChild( { msg: 'transfer'} );
    } else if(val == 3) {
      this.$refs.winPopup.sendEvtToChild( { msg: 'retrieve'} );
    } else if(val == 4) {
      this.$refs.winPopup.sendEvtToChild( { msg: 'abcde4'} );
    } 
  },
    /* 우편번호 조회 */
    searchAddrInfo: async function (item) {
      //console.log("searchAddrInfo!!!" + JSON.stringify(item));

      // header 세팅
      let requestData = {
            headers: {
            ASYNC: false,
            METHOD: "inqire",
            SERVICE: "phone.main.post-addr",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/main/post-addr/inqire"
            },
            sendData: {},
        };

        requestData.sendData["ASP_CUST_KEY"] = this.ASP_CUST_KEY;
        requestData.sendData["POST_NO"] = item.POST_NO;

        this.common_postCall(requestData).then(res => {
        if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
          if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
            //console.log("searchAddrInfo DATA!!!" + JSON.stringify(res.DATA));

            if(item.ADDR_TYPE == "1") {
              accInfo.HOME_ADDR = res.DATA.zAbroadAddr;
              accInfo.HOME_ADDR_DTL = res.DATA.zBlwroadAddr;
            } else if(item.ADDR_TYPE == "2") {
              accInfo.OFFC_ADDR = res.DATA.zAbroadAddr;
              accInfo.OFFC_ADDR_DTL = res.DATA.zBlwroadAddr;
            } else if(item.ADDR_TYPE == "3") {
              repAccInfo.HOME_ADDR = res.DATA.zAbroadAddr;
              repAccInfo.HOME_ADDR_DTL = res.DATA.zBlwroadAddr;
            } else if(item.ADDR_TYPE == "4") {
              repAccInfo.OFFC_ADDR = res.DATA.zAbroadAddr;
              repAccInfo.OFFC_ADDR_DTL = res.DATA.zBlwroadAddr;
            }
          }
          this.isLoading = false;   
        } else {
          this.common_toast(`우편번호 조회 중 \n 오류가 발생하였습니다.`, 'error')
        }
          this.common_toast("정상 처리되었습니다.", "done");
      });

    },
    /* CnslHistory 상담이력 목록 조회 */
    selectCnslHistInf: async function (custNo) {
      // header 세팅
        let requestData = {
            headers: {
            ASYNC: false,
            METHOD: "list",
            SERVICE: "phone.history.cnsl-list",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/history/cnsl/list"
            },
            sendData: {},
        };

      requestData.sendData["ASP_CUST_KEY"] = this.ASP_CUST_KEY;
      // requestData.sendData["CNSL_BEGIN_DATE"] = '20220801';
      // requestData.sendData["CNSL_END_DATE"]   = '20221022';
      requestData.sendData["CLNT_NO"]   = custNo;
      requestData.sendData["MAIN_YN"]   = "Y";
      requestData.sendData["CONTT_CD"]  = "01";
      requestData.sendData["SORT_DRCT"] = "desc";
      requestData.sendData["SORT_ORDR"] = "BEGIN_DTTM";
      requestData.headers["ROW_CNT"]   = 50;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.sendData["CALL_TIME"] = 0;
      this.isLoading = true;
      this.cnslhist = [];
      this.common_postCall_noPB(requestData, false).then(res => {
        if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
          if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
            for(let d of res.DATA) {
              for(let k of Object.keys(d)) {
                if(k === 'CNSL_BDATE') d[k] = this.mixin_dateFormat(d[k])
                else if(k === 'CNSL_BEGIN_TM') d[k] = this.mixin_timeFormat(d[k])
                else if(k === 'CNSL_END_TM') d[k] = this.mixin_timeFormat(d[k])
                else if(k === 'CNSL_CNTN') {
                  d[k] = this.restoreXSS2(d[k]);
                  d[k] = d[k].replaceAll("<BR>", "\r");
                }
                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
              }
              this.cnslhist.push(d);
            }
          }
          eventBus.busReRoadCnslTblRowClick();
          this.isLoading = false;   
        } else {
          this.common_toast(`상담이력 목록 조회 중 \n 오류가 발생하였습니다.`, 'error')
        }
        this.lastPageYn = false;
        this.common_toast("정상 처리되었습니다.", "done");
      });
    },

    /* CallAccount 상담고객 계좌정보 조회 */
    selectAccountInfo: async function(type, acntNo, custNo, acntPw, acctConf, sHirnkAcntNo, sAcntSttCd){
      // if(this.mixin_isEmpty(acntNo)) {
      //   this.common_alert(`계좌번호를 입력해주세요.`, 'error');
      //   return false;
      // }
                
      let params = {}
      params.ASP_CUST_KEY = this.ASP_CUST_KEY;
      params.ACNT_NO  = acntNo;
      params.HIRNK_ACNT_NO = sHirnkAcntNo;
      params.ACNT_PWD = acntPw;
      params.ACCT_CONF = acctConf;
      params.CUST_NO  = custNo;
      params.USER_ID  = this.geUserRole.userId;
      params.USER_NM  = this.geUserRole.userName;
      params.CUST_KEY = "001";

      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "inqire",
          SERVICE: "phone.main.acnt-addr",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/acnt-addr/inqire"
        },
        sendData: params
      };

      this.common_postCall(requestData).then((response) => {
        let resAccData = JSON.parse(JSON.stringify(response.DATA[0]));

        resAccData.PHONE_NO = this.mixin_newPhoneFormat(resAccData.PHONE_NO);

        //2 : 대표계좌
        if(type == "2") {
          this.repAccInfo = resAccData;
          this.repAccInfo.ACCOUNT = acntNo;
          this.repAccInfo.ACCOUNT_PW = acntPw;
          this.repAccInfo.ACCT_CONF  = acctConf;
        } else {
          this.accInfo = resAccData;
          this.accInfo.ACCOUNT    = acntNo;
          this.accInfo.ACCOUNT_PW = acntPw;
          this.accInfo.ACCT_CONF  = acctConf;
          this.accInfo.ACNT_STT_CD  = sAcntSttCd;

          this.geCustInfo.HOME_TNO = this.accInfo.HOME_TNO;
          this.geCustInfo.OFFC_TNO = this.accInfo.OFFC_TNO;
          this.geCustInfo.MOVE_TNO = this.accInfo.MOB_TNO;
          this.$store.dispatch("callStore/AC_CUST_INFO", this.geCustInfo);

          let dlgYn = this.accInfo.DLG_YN;

          if(dlgYn == "1") this.selectAccountInfo('2', acntNo, custNo, acntPw, acctConf, sHirnkAcntNo, "");
        }
        //this.common_alert("성공적으로 조회 했습니다.");
      })
      .catch((err) => {
        this.common_alert('상담고객 계좌정보 조회 중 <br> 오류가 발생하였습니다..<br>잠시후에 다시 시도해주세요.', 'error');
        console.log(err);
      });
    },

    /* CustHistory 상담고객 특이내역 조회 */
    selectCusSpstCntn: async function(custNo){
      if(this.mixin_isEmpty(custNo) ) {
        //this.common_alert(`고객번호를 입력해주세요.`, 'error');
        return false;
      }

      // header 세팅
        let requestData = {
            headers: {
            ASYNC: false,
            METHOD: "list",
            SERVICE: "phone.main.cus-spst",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/main/cus-spst/inqire"
            },
            sendData: {
              ASP_CUST_KEY: this.ASP_CUST_KEY
            },
        };
              
      requestData.sendData["CLNT_NO"]   = custNo;
      this.custSpstList = [];
      this.common_postCall(requestData).then(res => {
        if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
          if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
            for(let d of res.DATA) {
              for(let k of Object.keys(d)) {
                if(k === 'zRegDt') d[k] = this.mixin_dateFormat(d[k])
                 d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
              }
              d['ROW_NUMBER'] = this.custSpstList.length + 1
              this.custSpstList.push(d)
            }
          }
          this.isLoading = false;   
        } else {
          this.common_toast(`상담고객 특이내역 조회 중 \n 오류가 발생하였습니다.`, 'error')
        }
          this.common_toast("정상 처리되었습니다.", "done");
      });
    },

    sendVmsPop(val) {
      this.$refs.vmsPopup.sendEvtToChild( { msg: val} );
    }

  }
};
</script>

<style></style>