<template>
  <div class="jh-cols is-space-10">
    <div class="jh-card">
        <div class="jh-card-header is-jh-10">
            <div class="is-left">
                <v-btn class="jh-btn is-main" @click="openVms01Pop" :disabled="VMS_YN">업무 VMS</v-btn>
                <v-btn class="jh-btn is-del is-border-red" @click="openVmsPop" :disabled="VMS_YN">문제행동 VMS</v-btn>
                <v-tooltip content-class="jh-tooltip" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn is-icon is-vms-control" :class="gePlayYN == 'Y' ? 'is-active' : ''" v-bind="attrs" v-on="on" @click="clickVMS"><i class="jh-icon-check-bold"></i></v-btn>
                  </template>
                  <span v-if="gePlayYN == 'Y'">VMS 실행중</span>
                  <span v-else>VMS 미실행</span>
                </v-tooltip>
            </div>
            <div class="is-right">
              <p>{{BEGIN_TM}}</p>
              <p>{{END_TM}}</p>
            </div>
            <transition name="slide-down">
                <div class="jh-drop-layer" style="top:35px;width:300px;max-height: 365px;" v-show="VMS01">
                  <div class="jh-drop-layer-header">
                    <h2>업무 VMS</h2>
                    <div class="is-right">
                      <v-btn class="jh-btn is-icon" @click="VMS01=false"><i class="jh-icon-close"></i></v-btn>
                    </div>
                  </div>
                  <div class="jh-drop-layer-body">
                    <ul class="jh-list-grid">
                        <li v-for="item in VMS">
                            <p>{{item.CD_NM}}</p>
                            <v-btn class="jh-btn is-sm is-fill" @click="openP100701(item.CD)">선택</v-btn>
                        </li>
                    </ul>
                  </div>
                </div>
            </transition>
            <div class="is-right">
                <v-btn class="jh-btn is-round is-main" @click="fnClearBtnClick">Clear</v-btn>
            </div>
        </div>
        <div class="jh-card-body">
            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="100px">
                    <col width="120px">
                    <col width="100px">
                    <col>
                </colgroup>
                <tbody>
                    <!-- <tr>
                        <th><label>최초인입번호</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(IVR_IN_NO)" readonly></td>
                        <th><label>인입경로</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="IVR_IN_PATH" readonly></td>
                    </tr>
                    <tr>
                        <th><label>연결대기시간</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="STD_TIME" readonly></td>
                        <th><label>최종멘트</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="ERR_MENT" readonly></td>
                    </tr> -->
                    <!-- <tr>
                        <th><label>최초인입번호</label></th>
                        <td> <input type="text" class="jh-form is-trns" v-model="IVR_IN_NO1" readonly></td>
                        <th><label>인입경로</label></th>
                        <td> <input type="text" class="jh-form is-trns" v-model="IVR_IN_PATH1" readonly></td>
                    </tr>
                    <tr>
                        <th><label>연결대기시간</label></th>
                        <td> <input type="text" class="jh-form is-trns" v-model="STD_TIME1" readonly></td>
                        <th><label>최종멘트</label></th>
                        <td> <input type="text" class="jh-form is-trns" v-model="ERR_MENT1" readonly></td>
                    </tr> -->
                    <tr>
                      <th><label>최초인입번호</label></th>
                      <td> <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(geCtiInfo.IVR_IN_NO)" readonly></td>
                      <th><label>인입경로</label></th>
                      <td> <input type="text" class="jh-form is-trns" :value="geCtiInfo.IVR_IN_PATH" readonly></td>
                    </tr>
                    <tr>
                        <th><label>연결대기시간</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="geCtiInfo.IVR_STD_TIME" readonly></td>
                        <th><label>최종멘트</label></th>
                        <td> <input type="text" class="jh-form is-trns" :value="geCtiInfo.ERR_MENT" readonly></td>
                    </tr>    
                </tbody>
            </table>
  
            <div class="jh-ui-header is-mt-10">
                <div class="is-left jh-cols is-space-5" style="width: 560px;">
                    <label class="jh-label">MY메뉴</label>
                    <v-select class="jh-form" v-model="myMenu" :title="myMenu" :items="repeatList" item-value="ID" item-text="text" @change="changeCallType" @click="onMenuClick"></v-select>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(1)">1</v-btn>
                        </template>
                        <span v-if="repeatList.length > 1">{{repeatList[1].text}}</span>
                        <span v-else>유형없음</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(2)">2</v-btn>
                        </template>
                        <span v-if="repeatList.length > 2">{{repeatList[2].text}}</span>
                        <span v-else>유형없음</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(3)">3</v-btn>
                        </template>
                        <span v-if="repeatList.length > 3">{{repeatList[3].text}}</span>
                        <span v-else>유형없음</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(4)">4</v-btn>
                        </template>
                        <span v-if="repeatList.length > 4">{{repeatList[4].text}}</span>
                        <span v-else>유형없음</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(5)">5</v-btn>
                        </template>
                        <span v-if="repeatList.length > 5">{{repeatList[5].text}}</span>
                        <span v-else>유형없음</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="jh-btn is-main has-icon-only" v-bind="attrs" v-on="on" @click="openPopup('P100601')"><i class="jh-icon-setting is-blue"></i></v-btn>
                        </template>
                        <span>REPEAT 즐겨찾기 설정</span>
                    </v-tooltip>                  
                    <span class="jh-split is-mr-0"></span> 
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="jh-btn is-main has-icon-only" v-bind="attrs" v-on="on" :disabled="!mixin_isEmpty(smsWinPopup)" @click="openE030101P01"><i class="jh-icon-sms is-blue"></i></v-btn>
                        </template>
                        <span>SMS발송</span>
                    </v-tooltip>
                    <v-tooltip content-class="jh-tooltip" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="jh-btn is-main has-icon-only" v-bind="attrs" v-on="on" :disabled="!mixin_isEmpty(winPopup)" @click="openE010501P01"><i class="jh-icon-apply is-blue"></i></v-btn>
                        </template>
                        <span>캘린더/상장책자 신청</span>
                    </v-tooltip>
                </div>
                <div class="is-right">
                    <v-btn class="jh-btn is-main is-ml-10" :loading="isLoading" @click="cnsltHistRegist">저장</v-btn>
                </div>
            </div>
  
            <!--상담저장-->
            <div class="jh-form-cnsl-call">
                <table class="jh-tbl-inner">
                    <colgroup>
                        <col width="55px">
                        <col width="15%">
                        <!-- <col width="11%"> -->
                        <col>
                        <col width="2%">
                        <col width="55px">
                        <col width="29%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>상담유형</label></th>
                            <td>
                                <v-select class="jh-form"
                                   v-model="cnslInfo.CNSL_TY_LCLS" 
                                   :items="CNSL_TY_CODE_1"
                                   item-text="CD_NM"
                                   item-value="CD"
                                   :disabled="isObCall"
                                   no-data-text="조회된 값이 없습니다"
                                   @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_LCLS, '1')"
                                ></v-select>
                            </td>
                            <td>
                              <div class="jh-cols">
                              <v-select class="jh-form" 
                                  v-model="cnslInfo.CNSL_TY_MDCLS" 
                                  :items="CNSL_TY_CODE_2"
                                  item-text="CD_NM"
                                  item-value="CD"
                                  no-data-text="조회된 값이 없습니다"
                                  @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_MDCLS, '2')"
                              ></v-select>
                              <v-select class="jh-form"
                                    v-model="cnslInfo.CNSL_TY_SCLS" 
                                    :items="CNSL_TY_CODE_3"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    no-data-text="조회된 값이 없습니다"
                                ></v-select>
                              </div>
                            </td>
                            <td></td>
                            <td colspan="2">
                              <div class="jh-selection-row">
                                <v-checkbox class="jh-check is-circle is-sm is-bold" v-model="isCallBack" label="2차상담" :disabled="isCallBYn"></v-checkbox>
                                <v-checkbox class="jh-check is-circle is-sm is-bold" v-model="isReserveCall" label="MY예약콜" :disabled="isRsvYn"></v-checkbox>
                              </div>
                            </td>
                        </tr>
                        <tr>
                          <th><label>통화결과</label></th>
                          <td><v-select class="jh-form" 
                                  v-model="TEL_RSLT_CD"
                                  :items="mixin_common_code_get(this.common_code, 'CRS000', '선택')"
                                  :disabled="IN_YN"
                                  no-data-text="조회된 값이 없습니다"
                              ></v-select></td>
                              <td class="is-jh-10">
                                <v-checkbox class="jh-check is-bold" v-model="ISSUE_YN" :label="ISSUE" :disabled="ISSUE=='ISSUE'"></v-checkbox>
                              </td>
                                <td></td>
                            <th>예약일시</th>
                            <td>
                                <div class="jh-cols">
                                    <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y :disabled="isReserveYn">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="inputDate" v-bind="attrs" v-on="on" class="jh-form" append-icon="jh-icon-calendar is-blue" @keyup.enter="menu_date = false"
                                            maxlength="10" @input="dateOnInput" @click:clear="cnslInfo.RESV_DATE = ''"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');" :disabled="isReserveYn"></v-text-field>
                                        </template>
                                        <v-date-picker class="jh-calendar" v-model="cnslInfo.RESV_DATE" @input="menu_date = false" @click="menu_date = true" no-title locale="ko-KR" :day-format="mixin_getDate" @change="chgResvDate"></v-date-picker>
                                    </v-menu>
                                    <v-select class="jh-form is-col-fix" style="width:48px" v-model="cnslInfo.RESV_TIME" :items="mixin_common_code_get(this.common_code, 'CRS008', '선택')" :disabled="isReserveYn"></v-select>
                                    <span class="jh-unit is-bold">:</span>
                                    <v-select class="jh-form is-col-fix" style="width:48px" v-model="cnslInfo.RESV_MIN" :items="mixin_common_code_get(this.common_code, 'CRS009', '선택')" :disabled="isReserveYn"></v-select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                          <th><label>고객반응</label></th>
                          <td><v-select class="jh-form" 
                                  v-model="CUST_RECP_CD" 
                                  :items="mixin_common_code_get(this.common_code, 'CRS014', '선택')"
                                  no-data-text="조회된 값이 없습니다"
                              ></v-select></td>
                          <td class="is-jh-10">
                              <div class="jh-cols jh-align-middle" style="max-width: 210px;">
                                  <v-checkbox class="jh-check is-bold is-mr-5" v-model="PRB_YN" label="문제행동"></v-checkbox>
                                  <v-select class="jh-form" 
                                      v-model="PRBM_CD" 
                                      :items="mixin_common_code_get(this.common_code, 'CRS015', '선택')"
                                      :disabled="!PRB_YN"
                                      no-data-text="조회된 값이 없습니다"
                                  ></v-select>
                              </div>
                          </td>
                          <td></td>
                          <th>예약번호</th>
                          <td><input type="text" class="jh-form" maxlength="13" v-model="RESV_TEL_NO" @keyup="keyUpTelInp(RESV_TEL_NO)" :disabled="isReserveYn"></td>
                        </tr>
                        <tr> 
                            <td colspan="4"><v-textarea class="jh-form" style="height: 190px;" v-model="CNSL_CNTN" placeholder="상담내용을 입력하세요."></v-textarea></td>
                            <td colspan="2"><v-textarea class="jh-form" style="height: 190px;" v-model="RESV_CNTN" placeholder="예약메모를 입력하세요." :disabled="isReserveYn"></v-textarea></td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <!-- 즐겨쓰는상담소 모달 -->
          <v-dialog max-width="700" persistent v-if="dialogP100601" v-model="dialogP100601" content-class="square-modal">
            <dialog-P100601
              ref="dialogP100601ref"
              headerTitle="REPEAT 즐겨찾기 설정"
              :params=dialogParamP100601
              @hide="hideDialog('P100601')"
              @submit="submitDialog('P100601')"
            ></dialog-P100601>
          </v-dialog>
            <!--//상담저장-->
        </div>
    </div>
    <div class="jh-form-wrap is-p-0" style="max-width: 60px;">
      <ul class="jh-quick-list is-color">        
        <li
          v-for="(item, index) in menu01"
          :key="item.index"
          link
          :ripple="false"
          @click.stop="btnActive(item.action)"
        >
          <p>
            <i :class="[item.class]"></i>     
            <span>{{item.title}}</span>
          </p>     
        </li>
      </ul>
      <div class="jh-quick-divider"></div>
      <ul class="jh-quick-list">        
        <li
          v-for="(item, index) in menu02"
          :key="item.index"
          link
          :ripple="false"
          @click.stop="btnActive(item.action)"
        >
          <p><i :class="[item.class]"></i></p>          
          <span>{{item.title}}</span>
        </li>
      </ul>
    </div>
  </div>
  </template>
  
  <script>
  import { mixin } from "@/mixin/mixin.js";
  import api from "../../store/apiUtil.js";
  import { apiCall } from "@/store/callUtil.js";
  import { mapGetters } from "vuex";
  import { eventBus } from "@/store/eventBus.js";
  
  import DialogP100601 from "@/views/page/P100601.vue"
  
  export default {
  name: "CallSangdam",
  mixins: [mixin],
  props: {
    cnslInfoM:{
      type: [ Object ],
      default: {}
    },
    cnslHistNo:{
      type: String,
      default: ""
    },
    // IVR_IN_NO:{
    //   type: String,
    //   default: ""
    // },
    // IVR_IN_PATH:{
    //   type: String,
    //   default: ""
    // },
    // STD_TIME:{
    //   type: String,
    //   default: ""
    // },
    // ERR_MENT:{
    //   type: String,
    //   default: ""
    // },
  },
  components: {
    DialogP100601,  // 즐겨쓰는 상당유형 팝업
  },
  data() {
      return {
        VMS_Y_N: '',
        dialogP100601: false, //즐겨쓰는 상당유형
        VMS01: false,
        isLoading: false,
        VMS: [
            // { text: '유상증자 공매도 제한안내' },
            // { text: '투자설명서 교부 거절' },
            // { text: '미인가 신청' },
            // { text: '통폐합 해지' },
            // { text: '기타' },
        ],
  
        myMenu: '',
  
        cnslInfo: {}, //상담 정보 객체
        ivrInfo: {},  //IVR인입 정보
  
        dialogParamP100601: {}, //즐겨쓰는상담소
        BEGIN_TM: '',
        END_TM: '',
  
        ASP_CUST_KEY: '001',
        asp_cust_key_nm: 'HKCTALKMNG',
        USER_ATTR_A: '',
        USER_ATTR_B: '',
        USER_ID: '', //로그인한 사용자 ID
        USER_NM: '', //로그인한 사용자 이름
        INLINE_NO: '', //내선번호
        CURR_CNSL_HIST_NO: '',
        CURR_CNSL_HIST_DTL_NO: '',
  
        selected_channel: '001',
  
        OB_YN: '',      //아웃바운드 여부
        IN_YN: false,   //인바운드/아웃바운드 상태값
        CB_YN: false,   //콜백 상태값
        RSV_YN: false,  //예약콜(스케줄) 상태값
        VMS_YN: false,   //VMS버튼 상태값
  
        KEY_WD_YN: false,  //키워드
        PRB_YN: false,  // 문제행동
        ISSUE_YN: false, // 이슈여부
        ISSUE: 'ISSUE', // 이슈 CRS030
  
        isObCall: false,       //예약 여부
        isReserveCall: false,  //예약콜여부
        isCallBack: false,     //2차상담
        isReserveYn: true,
        isCallBYn: false,
        isRsvYn: false,

        CNSL_CNTN: '',
        RESV_CNTN: '',
        RESV_TEL_NO: '',
  
        CNSL_TY_CODE_1: [], // 상담유형(대)
        CNSL_TY_CODE_2: [], // 상담유형(중)
        CNSL_TY_CODE_3: [], // 상담유형(소)
  
        repeatList: [
          {text: ''}
        ],
  
        cnslTypeTop5: {
                     REPEAT1: '0101'
                   , REPEAT2: '0102'
                   , REPEAT3: '0201'
                   , REPEAT4: '0202'
                   , REPEAT5: '0201'
        },
  

        IVR_IN_NO: "", 
        IVR_IN_PATH: "", 
        STD_TIME: "", 
        ERR_MENT: "", 

        IVR_IN_NO1: "1", 
        IVR_IN_PATH1: "2", 
        STD_TIME1: "3", 
        ERR_MENT1: "4",         

        beginTime: '',
        endTime: '',
  
        common_code: [], //공통코드
  
        menu_date: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        resvDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        inputDate: "",
  
        smsWinPopup: null,
        isInitSmsWinPopup: true,
  
        winPopup: null,
        isInitWinPopup: true,
        rsvCallId: '',
        myCallYN: '',

        TEL_RSLT_CD: '',
        CUST_RECP_CD: '',
        PRBM_CD: '',
  
        menu01: [
          {
            id: "menu0101",
            title: "2차상담",
            class: "jh-icon-quick-cnsl",
            active: false,
            action: "PT100601",
          },
          {
            id: "menu0102",
            title: "MY예약",
            class: "jh-icon-quick-rsv",
            active: false,
            action: "PT100801",
          },
          {
            id: "menu0103",
            title: "ARS예약",
            class: "jh-icon-quick-ars",
            active: false,
            action: "PT100701",
          },
        ],
        menu02: [
          {
            id: "menu0201",
            title: "유상청약",
            class: "jh-icon-quick-subs",
            active: false,
            action: "PT100201",
          },
          {
            id: "menu0202",
            title: "반대의사",
            class: "jh-icon-quick-no",
            active: false,
            action: "PT100301",
          },
          {
            id: "menu0203",
            title: "매수청구",
            class: "jh-icon-quick-buy",
            active: false,
            action: "PT100401",
          },
          {
            id: "menu0204",
            title: "TM",
            class: "jh-icon-quick-tm",
            active: false,
            action: "PT100501",
          },
        ],
      }
  },
  mounted() {
      this.ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ASP_CUST_KEY"];
      this.USER_ID = this.$store.getters["userStore/GE_USER_ID"];
      this.USER_NM = this.$store.getters["userStore/GE_USER_NAME"];

      eventBus.$on('saveCnslHist', () => {
        this.cnsltHistRegist();
      });
  
      this.initMain();
      this.initCnslInfo();
      this.callCommonCode();
      this.getSelVMSList();
  },
  beforeDestroy(){
      this.initIvrInfo();
  },
  methods: {
    async callCommonCode(){
       let codeName = ['CRS008','CRS009','CRS000',"CRS014",'CRS015'];
       this.common_code = await this.mixin_common_code_get_all(codeName);
       this.ISSUE = await this.getCodeNm("CRS030");
       this.getRepeatList();
    },
  
    async initMain() {
      let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];
  
      this.USER_ID= get_user_role.userId, //로그인한 사용자 ID
      this.USER_NM= get_user_role.userNm, //로그인한 사용자 이름
      this.USER_ATTR_A= get_user_role.USER_ATTR_A,
      this.USER_ATTR_B= get_user_role.USER_ATTR_B,
      this.INLINE_NO = get_user_role.IPCC_NO
      this.ASP_CUST_KEY = '001';
  
      let prevDate = await this.mixin_getSvrPrevDate("%Y%m%d");
      let prevTime = await this.mixin_getSvrDate("HH24MISS");
  
      this.isLoading = false,
      //console.log(" * svrprevDate:" + JSON.stringify(prevDate));
  
       this.ivrInfo = this.$store.getters["callStore/GE_CTI_INFO"];
  
       if(!this.mixin_isEmpty(this.geCustInfo.SCDL_ID)) {
          this.ivrInfo.IVR_IN_NO    = this.geCustInfo.IVR_IN_NO;
          this.ivrInfo.IVR_IN_PATH  = this.geCustInfo.IVR_IN_PATH;
          this.ivrInfo.IVR_STD_TIME = this.geCustInfo.IVR_STD_TIME;
          this.ivrInfo.IVR_MENT     = this.geCustInfo.IVR_MENT;
       }
  
       this.changeCounselTypeKey(this.ASP_CUST_KEY, "*", "0");
       //this.changeCallType("010100");
    },
    setIvrClr(){
      this.IVR_IN_PATH1 = "";
        this.IVR_IN_NO1   = "";
        this.STD_TIME1  = "";
        this.ERR_MENT1   = "";
    },
    setIvrData(pVal){

        console.log("호전환 셋팅 : "+this.geCtiInfo);
        console.log("호전환 셋팅 pVal : "+this.geCtiInfo);

      if(!this.mixin_isEmpty(this.geCtiInfo.val9)){
        this.IVR_IN_PATH1 = this.geCtiInfo.IVR_IN_CD;
        this.IVR_IN_NO1   = this.mixin_newPhoneFormat(this.geCtiInfo.IVR_IN_NO)
        this.STD_TIME1   = this.geCtiInfo.IVR_STD_TIME;
        this.ERR_MENT1   = this.geCtiInfo.ERR_MENT;  
      }else{
        this.IVR_IN_PATH1 = this.geCtiInfo.IVR_IN_PATH;
        this.IVR_IN_NO1   = this.mixin_newPhoneFormat(this.geCtiInfo.IVR_IN_NO)
        this.STD_TIME1   = this.geCtiInfo.IVR_STD_TIME;
        this.ERR_MENT1   = this.geCtiInfo.ERR_MENT;        
      }



    }, 
    initIvrInfo() {
      this.ERR_MENT = "";
      this.ivrInfo  = {};
      this.$store.commit('callStore/MU_CTI_INFO', this.ivrInfo);
    },
    //상담정보 초기 설정
    initCnslInfo() {
      this.cnslInfo = {};
      this.cnslInfo.CALL_TY = '01';
      this.cnslInfo.CHANNEL = '001';
      this.cnslInfo.PROC_TY = '';
      this.cnslInfo.CNSL_TY_LCLS = '';
      this.cnslInfo.CNSL_TY_MDCLS = '';
      this.cnslInfo.CNSL_TY_SCLS = '';
      this.cnslInfo.CMPL_YN = 'N';
      this.cnslInfo.RESV_YN = 'N';
      this.cnslInfo.RESV_DATE = this.inputDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.cnslInfo.RESV_TIME = '00';
      this.cnslInfo.RESV_MIN = '00';
      this.cnslInfo.RESV_TEL_NO='';
      this.cnslInfo.CNSL_CNTN = '';
      this.cnslInfo.RESV_CNTN = '';
      this.rsvCallId = '';    // 전화예약콜ID init

      if(this.mixin_isEmpty(this.geCtiInfo.CALL_ID)) {
        this.CUST_RECP_CD = ''; // 고객반응 초기화
        this.TEL_RSLT_CD='';    // 통화결과 초기화
      }
      this.PRBM_CD='';
      this.cnslInfo.CUST_NM = this.geCustInfo.CNM;

      this.myMenu   = "";
      this.ISSUE_YN = false;
      this.PRB_YN   = false;

      this.CNSL_CNTN = '';
      this.RESV_CNTN = '';
      this.cnslInfo.RESV_YN = 'N'    //예약여부
      this.isReserveYn      = true;  //예약Disable 여부
      this.isCallBack       = false; //2차상담체크
      this.isReserveCall    = false; //My예약콜체크
      this.changeCounselTypeKey(this.ASP_CUST_KEY, "*", "0");

      if(this.geCallStt == 'TALKING') {
        if (this.geCtiInfo.CALL_TYPE == 'IN') {
          this.TEL_RSLT_CD = '01';
        } else {
          this.TEL_RSLT_CD = '';
        }
        this.CUST_RECP_CD = '02';
      }
      //this.changeCallType("010100");
    },
    async fnCnsltHistCheck(val) {
      let updateYN = false;

      let requestData1 = {
        headers: {
          SERVICE: 'phone.main.cnslt-enddttm',
          METHOD: 'inquire',
          TYPE: "BIZ_SERVICE",
        },
        sendData: {}
      };

      requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
      requestData1.headers["METHOD"] = "select";
      requestData1.headers["ASYNC"] = false;
      
      requestData1.sendData['CNSL_BEGIN_DATE'] = this.geCtiInfo.END_DT;
      requestData1.sendData['ASP_CUST_KEY'] = '001';
      requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
      requestData1.sendData['END_YN']  = val;
      requestData1.sendData['REG_ID']  = this.geUserRole.userId;

      const response1 = await this.common_postCall_noPB(requestData1);

      if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
        updateYN = true;
      }
      return updateYN;
    },        
    /* 상단 Clear 버튼 클릭 */
    async fnClearBtnClick() {
      if(this.geCallStt == "TALKING") {
        this.common_alert(`통화중에는 사용할 수 없습니다.`, 'info');
        return false;
      }
      console.log("this.geCnslSaveYn " , this.geCnslSaveYn )
      console.log("this.geCtiInfo.CALL_ID " , this.geCtiInfo.CALL_ID )
      console.log("this.geCallStt " , this.geCnslSaveYn )

      var cnsltHistCheck = await this.fnCnsltHistCheck("Y")

      //후처리중 상담저장이 안된경우
      if(this.geCallStt == "A_SETACW" && !this.mixin_isEmpty(this.geCtiInfo.CALL_ID) && this.geCnslSaveYn == "N") {
        if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || cnsltHistCheck == false){
          this.common_alert(`상담이력을 저장해주세요.`, 'info');
          return false;
        }else{
          this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
        }
      }

      let geSintongClear  = this.$store.getters["callStore/GE_SINTONG_CLEAR"];
  
      /* 신통초기화 */
      if(!geSintongClear) {
        this.mixin_sintongReset();
      }
      
      eventBus.ctiStt("CLEAN");
      this.$store.dispatch('callStore/AC_PLAY_YN', "N");
	    this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
      this.$store.commit('callStore/MU_CALL_BDTTM', "00000000"); //상담시작시간 초기화
      this.$store.dispatch("callStore/AC_CUST_INOUT", " ");
      this.isCallBack = false;
      this.initIvrInfo();
      this.initCnslInfo();
      this.$emit('initScr', "INFCLR");
    },
    /* 업무 VMS요청 팝업 */
    openVms01Pop() {
      // if(this.geCallStt != "TALKING")  {
      //   this.common_alert(`통화중 상태에서만 업무 VMS \n 요청이 가능합니다.`, 'info');
      //   //return;
      // }
      this.VMS01=true;
    },
    /* 문제행동 VMS요청 팝업 */
    openVmsPop() {
      // if(this.geCallStt != "TALKING")  {
      //   this.common_alert(`통화중 상태에서만 문제행동 VMS \n 요청이 가능합니다.`, 'info');
      //   //return;
      // }
      this.$emit('openVmsPop', { scrId: 'P100801', param: '' });
    },
    /* ISSUE 코드명 가져오기 */
    async getCodeNm(sCd, pVal) {
      let cdNm = "";
      api
          .post(
            "/api/code/common/code-book/selectCodeNm", //api url입력
            {
              GROUP_CD: sCd,
              CODE: pVal,
              USE_YN: "Y"
            },
            {
              head: {
                SERVICE: "api.palette-main.auth",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            if (this.mixin_isEmpty(response.data) || response.data.HEADER.COUNT == 0) {
              let errMsg = response.data.HEADER.ERROR_MSG;
              errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
              console.log("getCodeNm:[" + errMsg +"]");
              return false;
            }
            cdNm = response.data.DATA[0].CD_NM;
            if(sCd == "CRS030") this.ISSUE = response.data.DATA[0].CD_NM;
  
            if(sCd == "IVR04") {
              this.ivrInfo.IVR_IN_PATH = response.data.DATA[0].CD_NM;
              this.IVR_IN_PATH = response.data.DATA[0].CD_NM;
            }
          })
          .catch((err) => {
            this.common_toast(err);
          });
      return cdNm;
    },
    convDateFormat(sDate) {
      if (sDate.length == 14) {
        sDate = sDate.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6');
      }
  
      return sDate;
    },
    getDateTime(){
      var str     = '';
      var Stamp   = new Date();
      var yyyy    = Stamp.getYear();
      var mm, dd;
      var Hours, Mins, Sec;
      if (yyyy < 2000) yyyy = 1900 + yyyy;
      mm = (Stamp.getMonth() + 1);
      if(mm < 10) mm = "0" +mm;
      dd = Stamp.getDate();
      if(dd < 10) dd = "0" +dd;
      str = yyyy + "-" + mm  + "-" + dd;
  
      Hours = Stamp.getHours();
      Mins = Stamp.getMinutes();
      Sec = Stamp.getSeconds();
  
      if (Hours < 10) Hours = "0" + Hours;
      if (Mins < 10) Mins = "0" + Mins;
      if (Sec < 10) Sec = "0" + Sec;
  
      str += ' ' + Hours + ":" + Mins + ":" + Sec;
      return str; 
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      if(type == "P100601") this.getRepeatList()
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    addDialog(type) {
      this[`dialog${type}`] = true
    },
    openPopup(popNm, evtNm) {
      switch(popNm) {
        case 'P100601':  //사용자근태
          this.dialogParamP100601 = {}
          this.dialogParamP100601['ASP_NEWCUST_KEY'] = this.ASP_CUST_KEY
          break
      }
      this.showDialog(popNm)
    },
    openP100601(){
      this.$openWindow('P100601','P100601','800','800');
    },
    openP100701(CD){
      this.$emit('openVmsPop', { scrId: 'P100701', param: CD, callId: this.geCtiInfo.CALL_ID, custNo: this.geCustInfo.CLNT_NO, state: this.geCallStt });
    },
    openE030101P01(){
      if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
        this.common_alert(`고객정보를 조회해주세요.`, 'info');
        return false;
      } 
      if (!this.smsWinPopup) {
        this.smsWinPopup = this.$openWindow('E030101P01','_blank','1100','908');
        this.smsWinPopup.addEventListener("unload", this.closedSendSMS);
        if (this.geCustInfo && this.geCustInfo.CLNT_NO) {
          window.onmessage = this.loadedWinPopup;
        }
      }
    },
    openE010501P01(){
      if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
        this.common_alert(`고객정보를 조회해주세요.`, 'info');
        return false;
      } 
      if (!this.winPopup) {
        this.winPopup = this.$openWindow('E010501P01','_blank','1390','900');
        this.winPopup.addEventListener("unload", this.closedAplyWork);
      }
    },
    loadedWinPopup: function(e) {
      if (typeof e.data == "string") {
        let data = JSON.parse(e.data);
        if (data.evt == "smsPopupLoaded") {
          window.smsPopup.setCustomer(this.geCustInfo.CLNT_NO);
          window.onmessage = null;
        }
      }
    },
    closedSendSMS: function(e) {
      if (!this.isInitSmsWinPopup && this.smsWinPopup){
        this.smsWinPopup.close();
        this.smsWinPopup = null;
        this.isInitSmsWinPopup = true;
      }
      else this.isInitSmsWinPopup = false;
    },
    closedAplyWork: function(e) {
      if (!this.isInitWinPopup && this.winPopup){
        this.winPopup.close();
        this.winPopup = null;
        this.isInitWinPopup = true;
      }
      else this.isInitWinPopup = false;
    },
    keyUpTelInp(val) {
      let numPttn = /[^0-9]/g;
      val = val.replace(numPttn, "");
      this.cnslInfo.RESV_TEL_NO = val;
      if(val.length > 8)  {
        val = this.mixin_newPhoneFormat(val);
      }
      this.RESV_TEL_NO = val;
    },
    // 선택시
    setCnslTyCd(num) {
      if(this.repeatList.length <= num) return;
  
      let cnslTy = this.repeatList[num].value;
      let ID = this.repeatList[num].ID;
  
      this.myMenu = ID;
      this.CNSL_CNTN = this.repeatList[num].CONTENT;
      if(cnslTy.length == 4) cnslTy = cnslTy + "00";
      this.changeCallType(ID, 'id');
    },
    async changeCallType(ID, type){

      let cnslTy = '';

      if(ID.length < 8 && type == 'val') {
        cnslTy = ID;
      } else {
        this.repeatList.forEach(item => {
          if(item.ID == ID) {
            cnslTy = item.value;
            this.CNSL_CNTN = item.CONTENT;
          }
        });
      }

      if(this.mixin_isEmpty(cnslTy)) {
        this.cnslInfo.CNSL_TY_LCLS = "";
        this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "선택" }];
        this.cnslInfo.CNSL_TY_MDCLS = '';
		    this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "선택" }];
        this.cnslInfo.CNSL_TY_SCLS = '';
        return;
      }
      if(this.isCallBack && cnslTy != "3701") {
        return;
      }
  
      let cnslTyL = (cnslTy.length>=2?cnslTy.substr(0, 2):'');
      let cnslTyM = (cnslTy.length>=4?cnslTy.substr(0, 4):'');
      let cnslTyS = (cnslTy.length==6?cnslTy:'');
  
      if(cnslTy.length  == 4)  cnslTyS = cnslTy + "00";

      this.CNSL_TY_CODE_1 = await this.mixin_counsel_type_code_div_get(
        "Y",
        this.ASP_CUST_KEY,
        "P",
        "*",
        "선택"
      );
  
      this.CNSL_TY_CODE_2 =  await this.mixin_counsel_type_code_div_get(
        "Y",
        this.ASP_CUST_KEY,
        "P",
        cnslTyL,
        "선택"
      );
  
      
      this.CNSL_TY_CODE_3 = await this.mixin_counsel_type_code_div_get(
          "Y",
          this.ASP_CUST_KEY,
          "P",
          cnslTyM,
          "선택"
      );
      


      // for(let rpt of this.repeatList){
      //  if (rpt.value == cnslTy){
      //    this.CNSL_CNTN = rpt.CONTENT;
      //  }
      // }
      
      // if(cnslTy == "010100") {
      //   this.cnslInfo.CNSL_TY_LCLS  = "";
      //   this.cnslInfo.CNSL_TY_MDCLS = "";
      //   this.cnslInfo.CNSL_TY_SCLS  = "";
      // } else {
        this.cnslInfo.CNSL_TY_LCLS  = cnslTyL;
        this.cnslInfo.CNSL_TY_MDCLS = cnslTyM;
        this.cnslInfo.CNSL_TY_SCLS  = cnslTyS;
      // }
    },
    async changeCounselTypeKey(ASP_CUST_KEY, spst_counsel_typ_cd, data_type) {
      let CNSL = await this.mixin_counsel_type_code_div_get(
        "Y",
        this.selected_channel,
        "P",
        spst_counsel_typ_cd,
        "선택"
      );
      if (data_type === "0") {
        this.CNSL_TY_CODE_1 = [{ CD: "", CD_NM: "선택" }];
        this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "선택" }];
        this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "선택" }];
        this.cnslInfo.CNSL_TY_LCLS  = '';
        this.cnslInfo.CNSL_TY_MDCLS = '';
        this.cnslInfo.CNSL_TY_SCLS  = '';
  
        this.CNSL_TY_CODE_1 = CNSL;
      } else if (data_type === "1") {
        if(this.cnslInfo.CNSL_TY_LCLS == "" || this.cnslInfo.CNSL_TY_LCLS == undefined) {
          this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "선택" }];
          this.cnslInfo.CNSL_TY_MDCLS = '';
        } else {
          this.CNSL_TY_CODE_2 = CNSL;
          this.cnslInfo.CNSL_TY_MDCLS = this.CNSL_TY_CODE_2[1].CD;
          this.changeCounselTypeKey(ASP_CUST_KEY, this.cnslInfo.CNSL_TY_MDCLS, '2');
        }
  
        this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "선택" }];
        this.cnslInfo.CNSL_TY_SCLS = '';
      } else if (data_type === "2") {
  
        if(this.cnslInfo.CNSL_TY_MDCLS == "" || this.cnslInfo.CNSL_TY_MDCLS == undefined) {
          this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "선택" }];
          this.cnslInfo.CNSL_TY_SCLS = '';
        } else {
          this.CNSL_TY_CODE_3 = CNSL;
          this.cnslInfo.CNSL_TY_SCLS = this.CNSL_TY_CODE_3[1].CD;
        }
      }
    },
    /* 이전 상담이력 조회 */
    selectCnslHistDtl: async function(cnslHNo){
      if(this.mixin_isEmpty(cnslHNo) ) {
        this.common_alert(`상담이력번호를 입력해주세요.`, 'error');
        return false;
      }
  
      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "inqire",
          SERVICE: "phone.history.cnsldtl",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/history/cnsldtl/inqire"
        },
        sendData: {
          ASP_CUST_KEY: this.ASP_CUST_KEY
        },
      };
                  
      requestData.sendData["CNSL_HIST_NO"] = cnslHNo;
          this.custSpstList = [];
          this.common_postCall(requestData).then(res => {
            if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
              if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
                //this.cnslInfo.CNSL_TY_LCLS = res.DATA[0].CNSL_TY_LCLS;
                //this.cnslInfo.CNSL_TY_MDCLS = res.DATA[0].CNSL_TY_MDCLS;
                //this.cnslInfo.CNSL_TY_SCLS = res.DATA[0].CNSL_TY_SCLS;
  
                if(!this.mixin_isEmpty(res.DATA[0].CNSL_TY_SCLS)) {
                  this.changeCallType(res.DATA[0].CNSL_TY_SCLS, 'val');
                }
                else if(!this.mixin_isEmpty(res.DATA[0].CNSL_TY_MDCLS)) {
                  this.changeCallType(res.DATA[0].CNSL_TY_MDCLS, 'val');
                } else {
                  this.cnslInfo.CNSL_TY_LCLS = res.DATA[0].CNSL_TY_LCLS;
                  changeCounselTypeKey(this.ASP_CUST_KEY, this.cnslInfo.CNSL_TY_LCLS, '1');
                }

                let cnslCntn = res.DATA[0].CNSL_CNTN;
                if(!this.mixin_isEmpty(cnslCntn)) {
                  cnslCntn = this.restoreXSS2(cnslCntn);
                  cnslCntn = cnslCntn.replaceAll("<BR>", "\r");
                }

                this.cnslInfo.CNSL_CNTN = cnslCntn;
                this.CNSL_CNTN = cnslCntn;
                this.cnslInfo.TEL_RSLT_CD  = res.DATA[0].TEL_RSLT_CD; //통화결과
                this.cnslInfo.CUST_RECP_CD = res.DATA[0].CUST_RECP_CD; //고객반응
                this.cnslInfo.ISSUE_YN  = false;
                this.cnslInfo.PRBM_CD   = false;
              }
            } else {
              this.common_toast(`상담내용 조회 중 오류가 발생하였습니다.`, 'error')
            }
              this.common_toast("정상 처리되었습니다.", "done");
          });
    }, 
    /* 상담저장 */
    async cnsltHistRegist() {
      
      if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO) && this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
         this.common_alert(`고객정보를 조회해주세요.`, 'info');
         return false;
      }
  
      if(this.mixin_isEmpty(this.geCustInfo.MOVE_TNO) && this.mixin_isEmpty(this.geCtiInfo.FROM_TNO)) {
        this.common_alert(`고객 전화번호가 없습니다.`, 'info');
        //return false;
      }
  
      // if(this.mixin_isEmpty(this.INLINE_NO)) {
      //   this.common_alert(`내선번호 정보가 없습니다.`, 'info');
      //   return false;
      // }
  
      if(this.mixin_isEmpty(this.cnslInfo.CNSL_TY_LCLS)) {
        this.common_alert(`상담유형(대) 선택 해주세요.`, 'info');
        return false;
      }
  
      if(this.mixin_isEmpty(this.cnslInfo.CNSL_TY_MDCLS)) {
        this.common_alert(`상담유형(중) 선택 해주세요.`, 'info');
        return false;
      }

      if(this.mixin_isEmpty(this.cnslInfo.CNSL_TY_SCLS)) {
        this.common_alert(`상담유형(소) 선택 해주세요.`, 'info');
        return false;
      }

      if(this.PRB_YN && this.mixin_isEmpty(this.PRBM_CD)) {
        this.common_alert(`문제행동 유형을 선택하세요.`, 'info');
        return false;
      }
  
      if(this.isCallBack && (this.cnslInfo.CNSL_TY_LCLS != "07"  && this.mixin_isEmpty(this.cnslInfo.CNSL_TY_MDCLS))) {
        this.common_alert(`상담유형은 컨텐츠 또는 업무심화로 선택 해주세요.`, 'error');
        return false;
      }

      if(this.geCtiInfo.CALL_TYPE == 'OUT' && this.mixin_isEmpty(this.TEL_RSLT_CD)) {
        this.common_alert(`통화결과 값을 선택해주세요.`, 'info');
        return false;
      }

      if(this.geCtiInfo.CALL_TYPE == 'OUT' && this.mixin_isEmpty(this.CUST_RECP_CD)) {
        this.common_alert(`고객반응 값을 선택해주세요.`, 'info');
        return false;
      }
  
      let ls_one_char = "";
      let re = /[.,<>/?;:{}~!@\#$%^&*\()\=+_']/gi;
      
      if(!this.mixin_isEmpty(this.CNSL_CNTN))
        ls_one_char = this.CNSL_CNTN.replace(re,"");
  
      if(this.mixin_isEmpty(ls_one_char) || ls_one_char.length < 1) {
        this.common_alert(`상담내용은 특수문자 제외 1자 이상 적으세요.`, 'info');
        return false;
      }

      this.cnslInfo.CNSL_CNTN = this.CNSL_CNTN;
      this.cnslInfo.RESV_CNTN = this.RESV_CNTN;
  
      this.common_confirm('상담이력을 저장 하시겠습니까?', async () => {
        this.isLoading = true;
        let URLData = '/api/phone/main/cnslt-hist/regist';
        let HEADER_SERVICE = 'phone.main.cnslt-hist';
        let HEADER_METHOD = 'regist';
        let HEADER_TYPE = 'BIZ_SERVICE';
  
        if(this.geCustInfo.SRCH_CL_CID == "9999999999999") this.cnslInfo.CUST_NO = "9999999999999";
        else this.cnslInfo.CUST_NO      = this.geCustInfo.CLNT_NO;
  
        this.cnslInfo.ASP_CUST_KEY = this.ASP_CUST_KEY;
        this.cnslInfo.CSLT_ID      = this.USER_ID;
        this.cnslInfo.REGR_ID      = this.USER_ID;
        this.cnslInfo.CHNG_ID      = this.USER_ID;
        this.cnslInfo.REG_DEPT_CD  = this.USER_ATTR_A;
        this.cnslInfo.UPD_DEPT_CD  = this.USER_ATTR_A;
        this.cnslInfo.CENT_TY      = this.USER_ATTR_A;
        this.cnslInfo.USER_ATTR_B  = this.USER_ATTR_B;
        this.cnslInfo.CUST_NM      = this.geCustInfo.CNM;
        if(!this.mixin_isEmpty(this.geCustInfo.SRCH_ACCOUNT)) this.cnslInfo.ACCT_NO = this.geCustInfo.SRCH_ACCOUNT;
        this.cnslInfo.CUST_LEV     =  this.geCustInfo.CUS_LEVEL;    //고객등급
        this.cnslInfo.CUS_LEVEL_CD =  this.geCustInfo.CUS_LEVEL_CD;    //고객등급코드
  
        this.cnslInfo.CNSL_PATH_CD = '05';  //상담경로코드_CS01(05:수동)
        this.cnslInfo.CALL_KIND_CD = '';  //통화종류코드_CS14
        this.cnslInfo.CALL_ID = '';
        this.cnslInfo.CALL_TY = '';  //콜유형_CO003
  
        // if(this.IN_YN == "Y") {
        //   this.cnslInfo.SEND_MAN_NO = this.geCustInfo.FROM_TNO;//발신자번호
        //   this.cnslInfo.RESV_MAN_NO = this.INLINE_NO;//수신자번호
        // } else {
        //   this.cnslInfo.SEND_MAN_NO = this.INLINE_NO;;//발신자번호
        //   this.cnslInfo.RESV_MAN_NO = this.geCustInfo.MOVE_TNO;//수신자번호
        // }
  
        this.cnslInfo.CNSL_BEGIN_DATE = "";
        this.cnslInfo.BEGIN_TIME      = "";
        this.cnslInfo.CNSL_EOT_DATE   = "";
        this.cnslInfo.EOT_TIME        = "";
        this.cnslInfo.TEL_TIME        = 0  //통화시간
  
        this.cnslInfo.RDWT_ID = ''  //녹취ID
  
        //호전환여부
        this.cnslInfo.TRNS_CON_GUBUN =  '';
        this.cnslInfo.TRNS_YN =  '';
        this.cnslInfo.TRNS_CSLT_ID =  '';
        this.cnslInfo.TRNS_NO =  '';

        this.cnslInfo.CUST_RECP_CD = this.CUST_RECP_CD;
        this.cnslInfo.TEL_RSLT_CD  = this.TEL_RSLT_CD; 

        this.cnslInfo.CUST_NO == '9999999999999' ? this.cnslInfo.CUST_NO = '00000000' : this.cnslInfo.CUST_NO;
  
        if(this.ISSUE_YN) {   // 이슈여부
          this.cnslInfo.ISSUE =  this.ISSUE;
        } else {
          this.cnslInfo.ISSUE =  "";
        }

        if(!this.PRB_YN || this.cnslInfo.CUST_NO == '00000000') {    // 문제행동
          this.cnslInfo.PRBM_CD = "";
          this.cnslInfo.PRB_REG_YN = "N";
        } else {
          this.cnslInfo.PRBM_CD = this.PRBM_CD;
          this.cnslInfo.PRB_REG_YN = "Y";
        }
        if (this.isReserveCall) { //예약콜 등록시
          this.cnslInfo.RSV_CALL_KIND_CD = "01";
          this.cnslInfo.RESVE_CALL = 'Y'; //예약콜 여부
          this.cnslInfo.RESV_DATE = this.cnslInfo.RESV_DATE.replaceAll("-", ""); // 예약콜일시
          this.cnslInfo.RSV_CALL_ID = "";
          this.cnslInfo.MY_CALL_YN = '';
          if(this.cnslInfo.CUST_NO == '9999999999999' || this.cnslInfo.CUST_NO == '' || this.cnslInfo.CUST_NO == undefined) this.cnslInfo.CUST_NO = '00000000';
          if(this.cnslInfo.CUST_NO == '00000000') this.cnslInfo.ACCT_NO = '';
        }
        else if (this.isCallBack) { //2차상담 등록시
          this.cnslInfo.RSV_CALL_KIND_CD = "02";
          this.cnslInfo.RESVE_CALL = 'Y'; //예약콜 여부
          this.cnslInfo.RESV_DATE = this.cnslInfo.RESV_DATE.replaceAll("-", ""); // 예약콜일시
          this.cnslInfo.RSV_CALL_ID = "";
          this.cnslInfo.MY_CALL_YN = '';
          if(this.cnslInfo.CUST_NO == '9999999999999' || this.cnslInfo.CUST_NO == '' || this.cnslInfo.CUST_NO == undefined) this.cnslInfo.CUST_NO = '00000000';
          if(this.cnslInfo.CUST_NO == '00000000') this.cnslInfo.ACCT_NO = '';
        }
        else {
          this.cnslInfo.RESVE_CALL = "N"; //예약콜 여부
          this.cnslInfo.RESV_DATE  = ""; // 예약콜일시
          this.cnslInfo.RESV_TIME  = "";
          this.cnslInfo.RESV_MIN   = "";
          console.log(">>>callsangdam rsvCallId" + this.rsvCallId);
          this.cnslInfo.RSV_CALL_ID = (this.rsvCallId != '') ? this.rsvCallId : '';
          if((this.cnslInfo.CUST_NO == '9999999999999'|| this.cnslInfo.CUST_NO == '' || this.cnslInfo.CUST_NO == undefined) 
            && this.cnslInfo.RSV_CALL_ID != '') this.cnslInfo.CUST_NO = '00000000';
          if(this.cnslInfo.CUST_NO == '00000000') this.cnslInfo.ACCT_NO = '';
          this.cnslInfo.RSV_CALL_KIND_CD = '';
          this.cnslInfo.MY_CALL_YN = this.myCallYN;
        }
  
        // 아웃바운드 처리결과 수정
        if (!this.mixin_isEmpty(this.geCustInfo.ID) && this.geCustInfo.ID.length > 0) { 
          this.cnslInfo.ID = this.geCustInfo.ID;
          this.cnslInfo.OB_YN = "Y";
          this.cnslInfo.NTFCTN_ID = this.USER_ID;
  
          // 통화결과
          if (this.mixin_isEmpty(this.geCustInfo.NTFCTN_1st)) {
            this.cnslInfo.NTFCTN_1st = this.TEL_RSLT_CD   //통화결과 1차
          } else {
            this.cnslInfo.NTFCTN_1st = this.geCustInfo.NTFCTN_1st  //통화결과 1차
            this.cnslInfo.NTFCTN_Nth = this.TEL_RSLT_CD   //통화결과 N차
          }
        }
  
        // ARS예약콜 처리결과 수정
        if (!this.mixin_isEmpty(this.geCustInfo.SCDL_ID) && this.geCustInfo.SCDL_ID.length > 0) { 
          this.cnslInfo.SCDL_ID = this.geCustInfo.SCDL_ID;
          // this.cnslInfo.RSV_CALL_KIND_CD = "";
          this.cnslInfo.ARS_YN = "Y";
          this.cnslInfo.RESVE_CALL = 'Y'; //예약콜 여부
          this.cnslInfo.RESV_DATE = this.cnslInfo.RESV_DATE.replaceAll("-", ""); // 예약콜일시
  
          if(!this.isReserveCall && !this.isCallBack) {
            this.cnslInfo.RSV_CALL_KIND_CD = "03";
          }
        }
  
        //this.ivrInfo = this.$store.getters["callStore/GE_CTI_INFO"];
        /**
         * IVR 값 처리  
         **/
        if(!this.mixin_isEmpty(this.geCtiInfo.CALL_ID)) {

          var cnslSaveFlag = this.$store.getters["callStore/GE_CNSL_SAVE_FLAG"];
          var cnslCustPrev = this.$store.getters["callStore/GE_CNSL_CUST_PREV"];


          console.log("cnslSaveFlag    ", cnslSaveFlag);
          console.log("cnslCustPrev    ", cnslCustPrev);
          console.log("this.geCustInfo.CLNT_NO  ", this.geCustInfo.CLNT_NO)
          console.log("this.geCallStt  ", this.geCallStt)


          //if (this.geCallStt == 'A_SETACW' && (this.geCustInfo.CLNT_NO != this.geLastCustID)) {
          if (this.geCallStt == 'A_SETACW' && cnslSaveFlag) {
            console.log("후처리중 저장 처리 1");
            let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

            let svrDate = svrDateTime.substr(0, 8);
            let svrTime = svrDateTime.substr(8, 16);

            this.cnslInfo.IVR_SERV_NO = '';
            this.cnslInfo.IVR_IN_NO = '';
            this.cnslInfo.SEND_MAN_NO = '';

            this.cnslInfo.CNSL_BEGIN_DATE = svrDate;
            this.cnslInfo.BEGIN_TIME      = svrTime;
            this.cnslInfo.CNSL_EOT_DATE   = svrDate;
            this.cnslInfo.EOT_TIME        = svrTime;
            this.cnslInfo.TEL_TIME        = 0;

          //} else if((this.geCallStt == 'A_SETACW' && !cnslSaveFlag) || (this.geCallStt == 'A_SETACW' && !cnslSaveFlag && (this.geCustInfo.CLNT_NO == cnslCustPrev))){
          } else if(this.geCallStt == 'A_SETACW' && !cnslSaveFlag){
            this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y"); //상담저장여부
            this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', true);
            this.$store.dispatch('callStore/AC_CNSL_CUST_PREV', "");
            
            this.cnslInfo.SEND_MAN_NO = this.geCtiInfo.FROM_TNO;
            this.cnslInfo.CALL_ID = this.geCtiInfo.CALL_ID;
            this.cnslInfo.IVR_SERV_NO = this.geCtiInfo.IVR_SERV_NO;
            this.cnslInfo.IVR_IN_NO = this.geCtiInfo.IVR_IN_NO;
            this.cnslInfo.RDWT_ID = this.geCtiInfo.RDWT_ID;
            // this.cnslInfo.IVR_IN_PATH = this.geCtiInfo.IVR_IN_PATH;
            this.cnslInfo.IVR_STD_TIME = this.geCtiInfo.IVR_STD_TIME;
            this.cnslInfo.IVR_MENT = this.geCtiInfo.IVR_MENT;
            //if(this.geCtiInfo.CALL_TYPE == "OUT") this.cnslInfo.OB_YN = "Y"
            if((this.geCtiInfo.CALL_TYPE == 'OUT' || this.geCnslCustInout == 'OUT') && !this.mixin_isEmpty(this.geToTno)) {this.cnslInfo.SEND_MAN_NO = this.geToTno};
    
            if(!this.mixin_isEmpty(this.geCtiInfo.RDWT_ID) && !this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
              let param2 = this.geCustInfo.SRCH_ACCOUNT;

              if(this.mixin_isEmpty(param2)) param2 = this.geCustInfo.SRCH_CL_CID;
              //녹취저장처리
              this.rdwtOpenCall(this.geCtiInfo.RDWT_ID, param2, this.geCallStt);
            }
            
            let splAddr = "";
            if(!this.mixin_isEmpty(this.geCustInfo.IVR_IN_PATH)) splAddr  = this.geCtiInfo.IVR_IN_PATH.split('>');
            for(var i in splAddr)  {
                if(i==0) this.cnslInfo.IVR_IN_PATH1 = splAddr[i];
                if(i==1) this.cnslInfo.IVR_IN_PATH2 = splAddr[i];
                if(i==2) this.cnslInfo.IVR_IN_PATH3 = splAddr[i];
            }
    
            if(!this.mixin_isEmpty(this.geCtiInfo.BEGIN_DT)) {
              this.cnslInfo.CNSL_BEGIN_DATE = this.geCtiInfo.BEGIN_DT;
              this.cnslInfo.BEGIN_TIME      = this.geCtiInfo.BEGIN_TM;
              this.cnslInfo.CNSL_EOT_DATE   = this.geCtiInfo.END_DT;
              this.cnslInfo.EOT_TIME        = this.geCtiInfo.END_TM;
              this.cnslInfo.TEL_TIME        = this.geCtiInfo.TEL_TIME;  //통화시간
            }
    
            this.cnslInfo.SKILL_NO        = this.geCtiInfo.SKILL_NO; 
            this.cnslInfo.SKILL_NAME      = this.geCtiInfo.SKILL_NAME;
            this.cnslInfo.CISCO_ID        = this.geCtiInfo.USER_ID; //시스코ID
    
            //호전환여부
            if(this.geCtiInfo.TRNS_YN == "Y") {
              this.cnslInfo.TRNS_CON_GUBUN =  this.geCtiInfo.TRNS_CON_GUBUN;
              this.cnslInfo.TRNS_YN        =  this.geCtiInfo.TRNS_YN;
              this.cnslInfo.TRNS_CSLT_ID   =  this.geCtiInfo.TRNS_CSLT_ID;
              this.cnslInfo.TRNS_NO        =  this.geCtiInfo.TRNS_NO;
            }

            if(this.geCnslCustInout != ""){

              if(this.geCnslCustInout== "OUT") {
                this.cnslInfo.CNSL_PATH_CD = "04";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                this.cnslInfo.CALL_KIND_CD = "05";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
              } else if(this.geCnslCustInout == "IN") {
                this.cnslInfo.CNSL_PATH_CD = "03";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
              }else{
                this.cnslInfo.CNSL_PATH_CD = "05";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                // this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B

                console.log("&&&&&&  수동>  - 콜아이디 있다.*************" + this.geCtiInfo.CALL_ID );

              }              

            }else{
              if(this.geCtiInfo.CALL_TYPE == "OUT") {
                this.cnslInfo.CNSL_PATH_CD = "04";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                this.cnslInfo.CALL_KIND_CD = "05";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
              } else if(this.geCtiInfo.CALL_TYPE == "IN") {
                this.cnslInfo.CNSL_PATH_CD = "03";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
              }else{
                this.cnslInfo.CNSL_PATH_CD = "05";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
                // this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B

                console.log("&&&&&&  수동>  - 콜아이디 있다.*************" + this.geCtiInfo.CALL_ID );

              }     
            }
            this.$store.dispatch("callStore/AC_CUST_INOUT", "");

            if(this.cnslInfo.CNSL_PATH_CD == '04' || this.cnslInfo.CNSL_PATH_CD == '05') {
              // O/B 또는 수동일 때
              this.cnslInfo.IVR_SERV_NO = '';
              this.cnslInfo.IVR_IN_NO = '';
            }
          }else{
            this.$store.dispatch('callStore/AC_CNSL_CUST_PREV', this.geCustInfo.CLNT_NO);
            this.cnslInfo.SEND_MAN_NO = this.geCtiInfo.FROM_TNO;
            this.cnslInfo.CALL_ID = this.geCtiInfo.CALL_ID;
            this.cnslInfo.IVR_SERV_NO = this.geCtiInfo.IVR_SERV_NO;
            this.cnslInfo.IVR_IN_NO = this.geCtiInfo.IVR_IN_NO;
            this.cnslInfo.RDWT_ID = this.geCtiInfo.RDWT_ID;
            // this.cnslInfo.IVR_IN_PATH = this.geCtiInfo.IVR_IN_PATH;
            this.cnslInfo.IVR_STD_TIME = this.geCtiInfo.IVR_STD_TIME;
            this.cnslInfo.IVR_MENT = this.geCtiInfo.IVR_MENT;
            //if(this.geCtiInfo.CALL_TYPE == "OUT") this.cnslInfo.OB_YN = "Y"
            if(this.geCtiInfo.CALL_TYPE == 'OUT') this.cnslInfo.SEND_MAN_NO = this.geToTno;

    
            if(!this.mixin_isEmpty(this.geCtiInfo.RDWT_ID) && !this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
              let param2 = this.geCustInfo.SRCH_ACCOUNT;

              if(this.mixin_isEmpty(param2)) param2 = this.geCustInfo.SRCH_CL_CID;
              //녹취저장처리
              this.rdwtOpenCall(this.geCtiInfo.RDWT_ID, param2, this.geCallStt);
            }
            
            let splAddr = "";
            if(!this.mixin_isEmpty(this.geCustInfo.IVR_IN_PATH)) splAddr  = this.geCtiInfo.IVR_IN_PATH.split('>');
            for(var i in splAddr)  {
                if(i==0) this.cnslInfo.IVR_IN_PATH1 = splAddr[i];
                if(i==1) this.cnslInfo.IVR_IN_PATH2 = splAddr[i];
                if(i==2) this.cnslInfo.IVR_IN_PATH3 = splAddr[i];
            }
    
            if(!this.mixin_isEmpty(this.geCtiInfo.BEGIN_DT)) {
              this.cnslInfo.CNSL_BEGIN_DATE = this.geCtiInfo.BEGIN_DT;
              this.cnslInfo.BEGIN_TIME      = this.geCtiInfo.BEGIN_TM;
              this.cnslInfo.CNSL_EOT_DATE   = this.geCtiInfo.END_DT;
              this.cnslInfo.EOT_TIME        = "";
              this.cnslInfo.TEL_TIME        = this.geCtiInfo.TEL_TIME;  //통화시간
            }
    
            this.cnslInfo.SKILL_NO        = this.geCtiInfo.SKILL_NO; 
            this.cnslInfo.SKILL_NAME      = this.geCtiInfo.SKILL_NAME;
            this.cnslInfo.CISCO_ID        = this.geCtiInfo.USER_ID; //시스코ID
    
            //호전환여부
            if(this.geCtiInfo.TRNS_YN == "Y") {
              this.cnslInfo.TRNS_CON_GUBUN =  this.geCtiInfo.TRNS_CON_GUBUN;
              this.cnslInfo.TRNS_YN        =  this.geCtiInfo.TRNS_YN;
              this.cnslInfo.TRNS_CSLT_ID   =  this.geCtiInfo.TRNS_CSLT_ID;
              this.cnslInfo.TRNS_NO        =  this.geCtiInfo.TRNS_NO;
            }
    
            if(this.geCtiInfo.CALL_TYPE == "OUT") {
              this.cnslInfo.CNSL_PATH_CD = "04";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
              this.cnslInfo.CALL_KIND_CD = "05";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
            } else if(this.geCtiInfo.CALL_TYPE == "IN") {
              this.cnslInfo.CNSL_PATH_CD = "03";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
              this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B
            }else{
              this.cnslInfo.CNSL_PATH_CD = "05";   //상담경로코드(인입경로) 03:I/B, 04:O/B, 05:수동
              // this.cnslInfo.CALL_KIND_CD = "01";   //콜종류 01:I/B, 05:일반O/B, 06:리스트O/B

              console.log("&&&&&&  수동>  - 콜아이디 있다.*************" + this.geCtiInfo.CALL_ID );
              console.log(this.cnslInfo);

            }

            if(this.cnslInfo.CNSL_PATH_CD == '04' || this.cnslInfo.CNSL_PATH_CD == '05') {
              // O/B 또는 수동일 때
              this.cnslInfo.IVR_SERV_NO = '';
              this.cnslInfo.IVR_IN_NO = '';
            }
          }

          if(this.geCallStt == 'A_SETACW'){
            this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y"); //상담저장여부
          }
          let cnslSaveCnt = this.geCnslSaveCnt;
          cnslSaveCnt++;
          //CALLID가 있는 경우 상담건수 증가
          this.$store.dispatch('callStore/AC_CNSL_SAVE_CNT', cnslSaveCnt); //콜건수
          console.log("&&&&&& cnslSaveCnt:", this.geCnslSaveCnt);
        }else{
            //수동
            console.log("&&&&&&  수동 - 콜아이디 없다++++++++++++++++" + this.geCtiInfo.CALL_TYPE );
            let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

            let svrDate = svrDateTime.substr(0, 8);
            let svrTime = svrDateTime.substr(8, 16);

            this.cnslInfo.IVR_SERV_NO = '';
            this.cnslInfo.IVR_IN_NO = '';
            this.cnslInfo.SEND_MAN_NO = '';

            this.cnslInfo.CNSL_BEGIN_DATE = svrDate;
            this.cnslInfo.BEGIN_TIME      = svrTime;
            this.cnslInfo.CNSL_EOT_DATE   = svrDate;
            this.cnslInfo.EOT_TIME        = svrTime;
            this.cnslInfo.TEL_TIME        = 0;
        }
        //console.log('저장할 상담정보 = ' + JSON.stringify(this.cnslInfo));
        // header 세팅
        let requestData = {
          headers: {
            ASYNC: true,
            METHOD: "regist",
            SERVICE: "phone.main.cnslt-hist",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/main/cnslt-hist/regist"
          },
          sendData: this.cnslInfo
        };
        this.common_postCall(requestData).then(response => {
          this.isLoading = false;
          if(response.HEADER.ERROR_FLAG) {
            console.log("상담저장 ERR_MSG:["+response.HEADER.ERROR_MSG+"]");
            this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
            return false;
          }

          this.initCnslInfo();  //상담내역초기화
          this.CURR_CNSL_HIST_NO     = response.DATA[0].CNSL_HIST_NO;
          this.CURR_CNSL_HIST_DTL_NO = response.DATA[0].CNSL_HIST_DTL_NO;

          //this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y"); //상담저장여부
          this.common_alert("성공적으로 저장했습니다.");

          if (this.geCallStt == 'TALKING' || !this.mixin_isEmpty(this.geCtiInfo.CALL_ID)) {
            if (this.geCtiInfo.CALL_TYPE == 'IN') {
              this.TEL_RSLT_CD = '01';
            } else {
              this.TEL_RSLT_CD = '';
            }
            this.CUST_RECP_CD = '02';
          } else {
            this.TEL_RSLT_CD = '';
            this.CUST_RECP_CD = '02';
          }
          if(!this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
            this.$emit('selCnslHist', this.geCustInfo);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
          console.log(err);
        });
  
        //상담저장 끝낸 후에는 콜백,스케줄을 다시 조회한다.
        //EventBus.$emit('check');
      }, null, () => {
        return;
      }, null, 'confirm');
    },
    /* 통화종료시간 수정 */
    fnSaveCnslHist() {
      if(this.mixin_isEmpty(this.geCtiInfo.END_DT) || this.mixin_isEmpty(this.geCtiInfo.END_TM)) {
          //this.common_alert(`종료일시가 없어서 종료시간 수정 실패하였습니다.`, 'error');
          console.log("종료일시가 없어서 종료시간 수정 실패하였습니다.");
          return false;
      }

      if(this.mixin_isEmpty(this.CURR_CNSL_HIST_NO)) {
          //this.common_alert(`상담이력번호가 없어서 종료시간 수정 실패하였습니다.`, 'error');
          console.log("상담이력번호가 없어서 종료시간 수정 실패하였습니다.");
          return false;
      }

      let reqData = {
        ASP_CUST_KEY : this.ASP_CUST_KEY,
        CNSL_HIST_NO : this.CURR_CNSL_HIST_NO,
        CNSL_EOT_DATE : this.geCtiInfo.END_DT,
        CNSL_EOT_TIME : this.geCtiInfo.END_TM,
        TEL_TIME : this.geCtiInfo.TEL_TIME,
        CHNG_ID : this.USER_ID,
      }

      // header 세팅
      let requestData = {
        headers: {
            ASYNC: true,
            METHOD: "modify",
            SERVICE: "phone.main.cnslt-enddttm",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/main/cnslt-enddttm/modify"
        },
        sendData: reqData
      };
      this.common_postCall(requestData).then(response => {
          this.isLoading = false;
          this.dialogHistory=false;
          if(response.HEADER.ERROR_FLAG) {
            console.log("상담저장 ERR_MSG:["+response.HEADER.ERROR_MSG+"]");
            this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
            return false;
          }

          if(!this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
            this.$emit('selCnslHist', this.geCustInfo);
          }
      })
      .catch((err) => {
        this.isLoading = false;
        this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
        console.log(err);
      });
    },
    onMenuClick() {
      console.debug(this.repeatList.length);
  
      // this.getRepeatList();
    },
    async getRepeatList() {      
        let params = {
          USE_YN: 'Y',
          USER_ID: this.$store.getters["userStore/GE_USER_ID"],
          ROW_CNT: 10,
        };
  
        let URLData = '/api/phone/main/repeat-cmb/list';
        let HEADER_SERVICE = 'phone.main.repeat-cmb';
        let HEADER_METHOD = 'regist';
        let HEADER_TYPE = 'BIZ_SERVICE';
        await api
        .post(URLData, params, {
        head: {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          ASYNC: false,
        },
        })
        .then((response) => {
          let resData = [];
          let data = {ID:"", value:"", text: '선택/선택/선택'}
          resData.push(data);
          for(let d of response.data.DATA) {
            for(let k of Object.keys(d)) {
              if(k === 'CONTENT') d[k] = this.restoreXSS(d[k].replace(/<BR>/gi, "\n"));
              else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
            }
            resData.push(d)
          }
  
          this.repeatList = resData;
          this.myMenu = "";
        })
        .catch((err) => {
          this.common_alert('마이메뉴 조회 중 오류가 발생했습니다.', 'error');
          console.log(err);
        });
    },
    /* 녹취 UPDATE */
    rdwtOpenCall: async function (rdwtId, account, callStt) {
        if(this.mixin_isEmpty(rdwtId)) {
          rdwtId = this.ivrInfo.RDWT_ID;
        }
  
        if(this.mixin_isEmpty(rdwtId)) {
          this.common_toast("녹취중 녹취번호가 없습니다.", "info");
          return;
        }

        if(this.mixin_isEmpty(account)) {
          this.common_toast("녹취중 계좌번호가 없습니다.", "info");
          return;
        }
  
        let userId = this.$store.getters['userStore/GE_USER_ID']
  
        let addURL = "?station=" + this.INLINE_NO + "&agent_id=" + userId + "&overwrite=1" 
                        + "&opt1=" + rdwtId + "&opt2=" + account;
  
        let URLData1 = this.geRdwtInfo.rdwt_udt_url1 + this.geRdwtInfo.rdwt_udt_end_url + addURL;
        let URLData2 = this.geRdwtInfo.rdwt_udt_url2 + this.geRdwtInfo.rdwt_udt_end_url + addURL;
        let URLData3 = this.geRdwtInfo.rdwt_udt_url3 + this.geRdwtInfo.rdwt_udt_end_url + addURL;
        let URLData4 = this.geRdwtInfo.rdwt_udt_url4 + this.geRdwtInfo.rdwt_udt_end_url + addURL;
  
        if(callStt == "TALKING") {
          URLData1 = this.geRdwtInfo.rdwt_udt_url1 + this.geRdwtInfo.rdwt_udt_stt_url + addURL;
          URLData2 = this.geRdwtInfo.rdwt_udt_url2 + this.geRdwtInfo.rdwt_udt_stt_url + addURL;
          URLData3 = this.geRdwtInfo.rdwt_udt_url3 + this.geRdwtInfo.rdwt_udt_stt_url + addURL;
          URLData4 = this.geRdwtInfo.rdwt_udt_url4 + this.geRdwtInfo.rdwt_udt_stt_url + addURL;
        }
        
        try {
          apiCall.ipcc(URLData1);
          apiCall.ipcc(URLData2);
          apiCall.ipcc(URLData3);
          apiCall.ipcc(URLData4);
        } catch(apiEx) {
          console.log("녹취 연동중 에러 발생!! - "+apiEx);
          this.common_toast("녹취 연동중 에러 발생!!"+apiEx, "info");
        }
    },
    chgResvDate(e) {
      // this.cnslInfo.RESV_DATE = e;
      this.inputDate = e;
    },
    chgResvTelNo(e, val){
      console.log("###"+JSON.stringify(e));
      this.cnslInfo.RESV_TEL_NO= this.mixin_newPhoneFormat(val);
    },
    dateOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.inputDate) && moment(this.inputDate).isValid()) {
        this.cnslInfo.RESV_DATE = this.inputDate;
        // datepick 다시 랜더링
        this.menu_date = false;
        this.menu_date = true;
      }
    },
  
    // 업무VMS
    async getSelVMSList() {
        let requestData = {
            headers: {
                SERVICE: 'code.common.code-book',
                METHOD: 'inquire',
                TYPE: 'select',
            },
            sendData: {}
        };
  
        requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
        requestData.headers["METHOD"] = "selectCode";
        requestData.headers["ASYNC"] = false;
  
        let sendList = [];
        sendList.push("VW01"); //업무VMS 유형
  
        requestData.sendData["GROUP_CD"] = sendList
  
        const response = await this.common_postCall(requestData);
  
        //console.log('res : ', response.DATA);
  
        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
  
            for (var i = 0; i < response.DATA.length ; i++){
                if (response.DATA[i].GROUP_CD == "VW01"){
                    this.VMS.push(response.DATA[i]);
                }
            }
        }
    },
  
  btnActive(action){
    if(action === "PT100601"){
      this.$emit('openRsvCallPop', { scrId: 'PT100601', width: '1300', height: '940' });
    } else if(action === "PT100801"){
      this.$emit('openRsvCallPop', { scrId: 'PT100801', width: '1200', height: '940' });
    } else if(action === "PT100701"){
      this.$emit('openRsvCallPop', { scrId: 'PT100701', width: '1200', height: '940' });
    } else if(action === "PT100201"){
      this.$emit('openRsvCallPop', { scrId: 'PT100201', width: '1200', height: '940' });
    } else if(action === "PT100301"){
      this.$emit('openRsvCallPop', { scrId: 'PT100301', width: '1200', height: '940' });
    } else if(action === "PT100401"){
      this.$emit('openRsvCallPop', { scrId: 'PT100401', width: '1200', height: '940' });
    } else if(action === "PT100501"){
      this.$emit('openRsvCallPop', { scrId: 'PT100501', width: '1200', height: '940' });
    }
  },

  clickVMS() {
    this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    eventBus.sendVmsData('N');
  }
},
computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geCustInfo: 'callStore/GE_CUST_INFO',
      geRdwtInfo: 'callStore/GE_RDWT_INFO',
      geCtiInfo: 'callStore/GE_CTI_INFO',
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
      geToTno: 'callStore/GE_TO_TNO',
      gePlayYN: 'callStore/GE_PLAY_YN',
      geLastCustID: 'callStore/GE_LAST_CUST_ID',
      geCustInfoPrev: 'callStore/GE_CNSL_CUST_PREV',
      geCnslCustInout: 'callStore/GE_CNSL_CUST_INOUT',   
      geCnslSaveCnt: 'callStore/GE_CNSL_SAVE_CNT',   
    }),
    autoSearchDateFormat(){
      return this.searchData.START_OBND_DATE + ' ~ ' + this.searchData.END_OBND_DATE
    },
    autoBeginDateFormat(){
      let camBeginDate = this.isEmpty(this.formData.CAM_BEGIN_DATE) ? '' : this.formData.CAM_BEGIN_DATE
      let camBeginTime = this.isEmpty(this.formData.CAM_BEGIN_TIME) ? '' : (this.formData.CAM_BEGIN_TIME.length==5 ? this.formData.CAM_BEGIN_TIME+':00' : this.formData.CAM_BEGIN_TIME)
      return camBeginDate + ' ' + camBeginTime
    },
    autoEotDateFormat(){
      let camEotDate = this.isEmpty(this.formData.CAM_EOT_DATE) ? '' : this.formData.CAM_EOT_DATE
      let camEotTime = this.isEmpty(this.formData.CAM_EOT_TIME) ? '' : (this.formData.CAM_EOT_TIME.length==5 ? this.formData.CAM_EOT_TIME+':00' : this.formData.CAM_EOT_TIME)
      return camEotDate + ' ' + camEotTime
    },
  },
  watch: {
      // MY예약콜
      isReserveCall: function(nVal) {
        if(nVal) {
          let Stamp = new Date();
          let Hours = Stamp.getHours();
          let Mins = Stamp.getMinutes();
  
          if(Mins < 10)      Mins = "10";
          else if(Mins < 20) Mins = "20";
          else if(Mins < 30) Mins = "30";
          else if(Mins < 40) Mins = "40";
          else if(Mins < 50) Mins = "50";
          else { 
            Mins = "00"; 
            Hours = Hours + 1;
          }
  
          if(Hours < 10) Hours = "0" + Hours;
          else Hours = "" + Hours;
          
          this.cnslInfo.RESV_YN = 'Y' 
          this.isReserveYn = false;
          this.isCallBack = false;
          this.isReserveCall = true;
          this.cnslInfo.RESV_TIME = Hours;
          this.cnslInfo.RESV_MIN = Mins;
          this.RESV_TEL_NO = this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO);
          this.cnslInfo.RESV_TEL_NO = this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO);
        } else {
          if(!this.isCallBack) {
            this.cnslInfo.RESV_YN = 'N'
            this.isReserveYn = true;
            this.cnslInfo.RESV_TEL_NO = '';
            this.RESV_TEL_NO = '';
          }
        }
        this.cnslInfo.RESV_CNTN = '';
        this.RESV_CNTN = '';
      },
      // 2차상담
      isCallBack: function(nVal) {
        if(nVal) {
  
          if(this.cnslInfo.CNSL_TY_MDCLS != "3701") {
            this.common_toast(`상담유형은 "2차상담요청"으로 선택해주세요.`, 'info');
          }
  
          this.changeCallType("3701", 'val');
  
          this.isObCall = true;
  
          let Stamp = new Date();
          let Hours = Stamp.getHours();
          let Mins = Stamp.getMinutes();
  
          if(Mins < 10)      Mins = "10";
          else if(Mins < 20) Mins = "20";
          else if(Mins < 30) Mins = "30";
          else if(Mins < 40) Mins = "40";
          else if(Mins < 50) Mins = "50";
          else { 
            Mins = "00"; 
            Hours = Hours + 1;
          }
  
          if(Hours < 10) Hours = "0" + Hours;
          else Hours = "" + Hours;
  
          this.cnslInfo.RESV_YN = 'Y' 
          this.isReserveYn = false;
          this.isReserveCall = false;
          this.cnslInfo.RESV_TIME = Hours;
          this.cnslInfo.RESV_MIN = Mins;
          this.RESV_TEL_NO = this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO);
          this.cnslInfo.RESV_TEL_NO = this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO);
        } else {
          this.isObCall = false;
          if(!this.isReserveCall) {
            this.cnslInfo.RESV_YN = 'N'
            this.isReserveYn = true;
            this.cnslInfo.RESV_TEL_NO = '';
            this.RESV_TEL_NO = '';
          }
        }
        this.cnslInfo.RESV_CNTN = '';
        this.RESV_CNTN = '';
      },
      // 상담이력번호
      cnslHistNo: function(nVal, oCol) {
        this.selectCnslHistDtl(nVal);
      },
      geCallStt: function(nVal) {
        //if(nVal == "TALKING") this.VMS_YN = false;
        //else this.VMS_YN = true;
        console.log("&&&&callSangdam CALLSTATE=======>[" + nVal + "]/cnslHistNo:" +this.CURR_CNSL_HIST_NO);

        if(nVal == "A_SETACW") {
          // 후처리중으로 상태 변경될 때 고객번호 가져오기
          this.$store.dispatch("callStore/AC_LAST_CUST_ID", this.geCustInfo.CLNT_NO);

          //상담이력번호가 있으면 종료시간 수정처리
          if(!this.mixin_isEmpty(this.CURR_CNSL_HIST_NO)) {
            // this.fnSaveCnslHist();
          }
        }

        // 전화 받았을 때 고객반응 및 통화결과값 셋팅
        if(nVal == 'TALKING') {
          if (this.geCtiInfo.CALL_TYPE == 'IN' || (!this.mixin_isEmpty(this.geCtiInfo.IVR_IN_NO) && this.geCtiInfo.IVR_IN_NO.length > 1)) {
            this.TEL_RSLT_CD = '01';
          } else {
            this.TEL_RSLT_CD = '';
          }
          this.CUST_RECP_CD = '02';
        }
      },
      geCustInfo: function(val) {
        const obj = val;

        if (obj.RSV_CALL_ID != '') {
          this.rsvCallId = obj.RSV_CALL_ID;
        }

        if (obj.MY_CALL_YN != '') {
          this.myCallYN = obj.MY_CALL_YN;
        }

        if (obj.CLNT_NO != undefined && (obj.CLNT_NO != this.geLastCustID)) {
          if (this.geCallStt == 'A_SETACW') {
            this.TEL_RSLT_CD = '';
            this.CUST_RECP_CD = '02';
          }
        }

        if (!this.mixin_isEmpty(obj.CLNT_NO)) {
          this.CUST_RECP_CD = '02';
        }

      },
    },
  };
  </script>
  
  <style></style>