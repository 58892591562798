<template>
  <div class="jh-card is-mt-10" style="height: 350px;">
      <!--상담이력 탭-->
      <div class="jh-card-header has-tab">
          <v-tabs v-model="active_tab" slider-size="4">
              <v-tab :key=i v-for="(item,i) in tabs" @click="tabClick(item.id)">{{item.name}}</v-tab>
          </v-tabs>
          <div class="jh-tab-button is-right">
            <span v-if="!addDataYn" class="jh-info-txt is-fill is-red is-mr-5" style="padding-left: 19px; word-spacing: -2px;"><span class="is-txt-black is-bold">최근 7일 기준</span></span>
            <v-btn class="jh-btn is-fill has-icon" @click="fnNextSearch"  :loading="isLoading"><span>연속조회</span><i class="jh-icon-angle-right-sm is-white"></i></v-btn>
            <v-btn class="jh-btn" @click="fnCustBoard"><i class="jh-icon-popup"></i><span>게시판</span></v-btn>
          </div>
      </div>
      <div class="jh-card-body">
          <v-tabs-items v-model="active_tab">
              <v-tab-item>
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers"
                          :items="cnslhist"
                          fixed-header
                          item-key="ROWNUM"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded"
                          @click:row="cnslTblRowClick"
                          @dblclick:row="onDbclickRow"
                          hide-default-footer>
                          <template v-slot:item.CHANNER="{ item }">
                              <v-btn class="jh-btn is-sm" v-if="item.CHANNER === '01'" @click.stop="openP101102">채팅</v-btn>
                              <span v-else>{{ item.CNTC_PATH }}</span>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length"><v-textarea class="v-input jh-form v-textarea v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-text-field--placeholder" style="height: 90px;" v-model="item.CNSL_CNTN" readonly></v-textarea></td>
                          </template>
                      </v-data-table>
                  </div>                    
              </v-tab-item>
              <!-- SMS(CTI) -->
              <v-tab-item :key="1">
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers2"
                          :items="smsHistList"
                          fixed-header
                          item-key="ROWNUM"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded1"
                          @click:row="smsRowClick"
                          hide-default-footer>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length"><v-textarea class="v-input jh-form v-textarea v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-text-field--placeholder" style="height: 90px;" v-model="item.CONTENT" readonly></v-textarea></td>
                          </template>
                      </v-data-table>
                  </div>
              </v-tab-item>
              <!-- SMS(MINE) -->
              <v-tab-item :key="2">
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers3"
                          :items="smsMineHistList"
                          fixed-header
                          item-key="SEQ"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded2"
                          @click:row="tmsRowClick"
                          hide-default-footer>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length"><v-textarea class="v-input jh-form v-textarea v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-text-field--placeholder" style="height: 90px;" v-model="item.CONTENT" readonly></v-textarea></td>
                          </template>
                      </v-data-table>
                  </div>
              </v-tab-item>
              <!-- 이메일(CTI) -->
              <v-tab-item :key="3">
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers4"
                          :items="emailHistList"
                          fixed-header
                          item-key="ROWNUM"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded3"
                          @click:row="emailRowClick"
                          hide-default-footer>
                          <template v-slot:expanded-item="{ headers, item }">
                            <!--td :colspan="headers.length"><v-textarea class="v-input jh-form v-textarea v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-text-field--placeholder" style="height: 90px;" v-model="item.CONTENT" readonly></v-textarea></td-->
                            <td :colspan="headers.length">
                              <div class="box-ct">
                              <ckeditor v-model="item.CONTENT" :config="readEditorConf" :read-only="true"></ckeditor>
                              </div>
                            </td>
                          </template>  
                      </v-data-table>
                  </div>
              </v-tab-item>
              <!-- 캘린더신청이력 -->
              <v-tab-item>
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers5"
                          :items="aplyWorkHistList"
                          fixed-header
                          item-key="APLY_NO"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded4"
                          @click:row="aplyWorkHistRowClick"
                          hide-default-footer
                      ></v-data-table>
                  </div>
              </v-tab-item>
              <!-- 문제행동이력 -->
              <v-tab-item>
                  <div class="tab-contnets">
                      <v-data-table
                          class="jh-grid"
                          :headers="headers6"
                          :items="probBehavHistList"
                          fixed-header
                          item-key="PRBM_NO"
                          singleSelect
                          singleExpand
                          :items-per-page="5000"
                          :expanded.sync="expanded5"
                          @click:row="probBehavHistRowClick"
                          hide-default-footer
                      ></v-data-table>
                  </div>
              </v-tab-item>
          </v-tabs-items>
      </div>
      <v-dialog
          v-model="dialogHistory"
          content-class="jh-dialog-wrap"
          max-width="900">
          <div class="jh-dialog-header">
              <h1>상담이력 상세정보</h1>
              <div class="is-right">
                  <v-btn class="jh-btn is-icon" title="닫기" @click="dialogHistory=false"><i class="jh-icon-close-lg is-white"></i></v-btn>
              </div>
          </div>
          <div class="jh-dialog-body">
            <div class="jh-cols is-space-5">
              <table class="jh-tbl-detail">
                <colgroup>
                  <col width="90px">
                  <col>
                  <col width="95px">
                  <col>
                  <col width="90px">
                  <col>
                  <col width="90px">
                  <col width="125px">
                </colgroup>
                <tbody>
                  <tr>
                    <th><label>Contact일자</label></th>
                    <td><span class="is-value is-center">{{cnslHistDtl.CNSL_BDATE}}</span></td>
                    <th><label>인입대표번호</label></th>
                    <td> <span class="is-value">{{mixin_newPhoneFormat(cnslHistDtl.IVR_IN_NO)}}</span> </td>
                    <th><label>인입경로</label></th>
                    <td colspan="3"><span class="is-value">{{cnslHistDtl.IVR_IN_PATH}}</span></td>
                  </tr>
                  <tr>
                    <th><label>상담시작시간</label></th>
                    <td><span class="is-value is-center">{{cnslHistDtl.CNSL_BEGIN_TM}}</span></td>
                    <th><label>발신/수신번호</label></th>
                    <td><span class="is-value">{{mixin_newPhoneFormat(cnslHistDtl.SEND_MAN_NO)}}</span></td>
                    <th><label>계좌번호</label></th>
                    <td><span class="is-value">{{mixin_accNoFormat(cnslHistDtl.ACCT_NO)}}</span></td>
                    <th><label>고객명</label></th>
                    <td><span class="is-value">{{cnslHistDtl.CUST_NM}}</span></td>
                  </tr>
                  <tr>
                    <th><label>상담종료시간</label></th>
                    <td><span class="is-value is-center">{{cnslHistDtl.CNSL_END_TM}}</span></td>
                    <th><label>상담사</label></th>
                    <td><span class="is-value">{{cnslHistDtl.CNSL_NM}}</span></td>
                    <th><label>고객등급</label></th>
                    <td><span class="is-value">{{cnslHistDtl.CUST_LEV}}</span></td>
                    <th><label>고객반응</label></th>
                    <td>
                      <v-select class="jh-form" v-model="cnslHistDtl.CUST_RECP_CD" 
                      :items="mixin_common_code_get(this.common_code, 'CRS014', '선택')"
                      no-data-text="조회된 값이 없습니다"></v-select>
                    </td>
                  </tr>
                  <!-- <tr>
                    <th><label>고객번호</label></th>
                    <td><span class="is-value">{{cnslHistDtl.CUST_NO}}</span></td>
                  </tr> -->
                </tbody>
              </table>
            </div>              
            <table class="jh-tbl-detail is-mt-5">
              <colgroup>
                <col width="90px">
                <col>
                <col width="90px">
                <col width="125px">
              </colgroup>
              <tbody>
                <tr>
                  <th><label>상담유형</label></th>
                  <td>
                    <div class="jh-cols">
                      <v-select class="jh-form"
                               v-model="cnslHistDtl.CNSL_TY_LCLS" 
                               :items="CNSL_TY_CODE_1"
                               item-text="CD_NM"
                               item-value="CD"
                               no-data-text="조회된 값이 없습니다"
                               @change="changeCounselTypeKey(ASP_CUST_KEY, cnslHistDtl.CNSL_TY_LCLS, '1')"
                      ></v-select>
                      <v-select class="jh-form" 
                            v-model="cnslHistDtl.CNSL_TY_MDCLS" 
                            :items="CNSL_TY_CODE_2"
                            item-text="CD_NM"
                            item-value="CD"
                            no-data-text="조회된 값이 없습니다"
                            @change="changeCounselTypeKey(ASP_CUST_KEY, cnslHistDtl.CNSL_TY_MDCLS, '2')"
                        ></v-select>
                      <v-select class="jh-form is-mr-15"
                        v-model="cnslHistDtl.CNSL_TY_SCLS" 
                        :items="CNSL_TY_CODE_3"
                        item-text="CD_NM"
                        item-value="CD"
                        no-data-text="조회된 값이 없습니다"
                       ></v-select>
                    </div>
                  </td>
                  <th><label>통화결과</label></th>
                  <td><v-select class="jh-form" 
                          v-model="cnslHistDtl.TEL_RSLT_CD" 
                          :items="mixin_common_code_get(this.common_code, 'CRS000', '선택')"
                          no-data-text="조회된 값이 없습니다">
                      </v-select></td>
                </tr>
              </tbody>
            </table>
            
            <v-textarea class="jh-form is-mt-5"                              
              noResize
              placeholder="상담내용을 입력하세요."
              style="height:200px"
              v-model="cnslHistDtl.CNSL_CNTN_MDF"
            >
            </v-textarea>
          </div>
          <div class="jh-dialog-footer">
              <v-btn class="jh-btn is-md" @click="dialogHistory=false">취소</v-btn>
              <v-btn class="jh-btn is-md is-main" @click="fnSaveCnslHist">저장</v-btn>
          </div>
      </v-dialog>         
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import { eventBus } from "@/store/eventBus.js";
import { mapGetters } from "vuex"

export default {
name: "CnslHistory",
mixins: [mixin],
props: {
  srchCustNo:{
    type: String,
    default: ""
  },
  lastPageYn:{
    type: Boolean,
    default: false
  },
  cnslhist:{
    type: [ Object, Array ],
    default: ""
  },
  cnslHistPageNo:{
    type: Number,
    default: void 0
  },
},
components: {
},
create() {
  console.log('param    : ', this.param);
},
data() {
    return {
      selectedRow: [],
      selectedRow1: [],
      selectedRow2: [],
      selectedRow3: [],
      selectedRow4: [],
      selectedRow5: [],

      menuStartDate: false,
      menuEndDate: false,
      isLoading: false,
      dialogHistory: false,
      dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      CURR_DT : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      preDate: [],
      ASP_CUST_KEY: '001',
      active_tab: 0,
      tabs: [
          { id: 0, name: '상담이력' },
          { id: 1, name: 'SMS(상담APP)' },
          { id: 2, name: 'SMS(MINE)' },
          { id: 3, name: '이메일(상담APP)' },
          { id: 4, name: '캘린더신청이력' },
          { id: 5, name: '문제행동이력' },

      ],
      common_code: [], //공통코드
      smsHistList: [],
      smsMineHistList: [],
      emailHistList: [],
      aplyWorkHistList: [],
      probBehavHistList: [],

      cnslHistDtl: {
        CNSL_HIST_NO: "",
        CNSL_TY_LCLS: "",
        CNSL_TY_MDCLS: "",
        CNSL_TY_SCLS: "",
      },

      CNSL_TY_CODE_1: [], // 상담유형(대)
      CNSL_TY_CODE_2: [], // 상담유형(중)
      CNSL_TY_CODE_3: [], // 상담유형(소)

      userInfo: {},
      PAGE_NO: 1,
      PAGE_NO2: 0,
      ROW_CNT: 50,

      lastPageTab1: false,
      lastPageTab2: false,
      lastPageTab3: false,
      lastPageTab4: false,
      lastPageTab5: false,
      lastPageTab6: false,

      addDataYn: true,
      MOD_YN: false,

      tabHistory: '상담이력',
      tab: [{
              id: '1',
              name: '상담이력'
            },
            {
              id: '2',
              name: 'SMS(상담APP)'
            },
            {
              id: '3',
              name: 'SMS(MINE)'
            },
            {
              id: '4',
              name: '이메일(상담APP)'
            },
            {
              id: '5',
              name: '캘린더신청이력'
            },
            {
              id: '6',
              name: '문제행동이력'
            },
      ],
      expanded: [],
      expanded1: [],
      expanded2: [],
      expanded3: [],
      expanded4: [],
      expanded5: [],
      // 상담이력
      headers: [
          { text: 'NO', value: 'ROWNUM', align: 'center', width: '30px', sortable: false },
          { text: 'Contact일자', value: 'CNSL_BDATE', align: 'center', width: '80px', sortable: false },
          { text: '시작시간', value: 'CNSL_BEGIN_TM', align: 'center', width: '70px', sortable: false },
          { text: '종료시간', value: 'CNSL_END_TM', align: 'center', width: '70px', sortable: false },
          { text: '대분류', value: 'CNSL_TY_LCLS_NM', align: 'center', width: '140px', sortable: false },
          { text: '중분류', value: 'CNSL_TY_MDCLS_NM', align: 'center', width: '140px', sortable: false },
          { text: '소분류', value: 'CNSL_TY_SCLS_NM', align: 'center', width: '165px', sortable: false },
          { text: '상담사', value: 'CNSL_NM', align: 'center', width: '100px', sortable: false },
          { text: '통화결과', value: 'TEL_RSLT_NM', align: 'center', width: '80px', sortable: false },
          { text: '고객반응', value: 'CUST_RECP_NM', align: 'center', width: '70px', sortable: false },
          { text: 'Contact경로', value: 'CNTC_PATH', align: 'center', width: '80px', sortable: false },
      ],
      // SMS(상담APP)
      headers2: [
          { text: 'NO', value: 'ROWNUM', align: 'center', width: '40px', sortable: false },
          { text: '발송일자', value: 'REQ_DT', align: 'center', width: '120px', sortable: false },
          { text: '발송시간', value: 'REQ_TM', align: 'center', width: '100px', sortable: false },
          { text: '제목', value: 'TITLE', sortable: false },
          { text: '수신번호', value: 'RECP_CELLPHN_NO', align: 'center', width: '140px', sortable: false },
          // { text: '발송부서', value: 'type02', align: 'center', width: '100px', sortable: false },
          { text: '발송직원', value: 'SEND_NM', align: 'center', width: '140px', sortable: false },
      ],
      // SMS(MINE)
      headers3: [
          { text: 'NO', value: 'SEQ', align: 'center', width: '40px', sortable: false },
          { text: '발송일시', value: 'MAKE_DT', align: 'center', width: '130px', sortable: false },
          { text: '발송방법', value: 'SEND_MTHD', align: 'center', width: '70px', sortable: false },
          { text: '제목', value: 'SUBJECT', sortable: false },
          { text: '발송매체', value: 'SEND_MEDIA', align: 'center', width: '65px', sortable: false },
          { text: '수신번호', value: 'CELLPHONE_NO', align: 'center', width: '100px', sortable: false },
          { text: '발송결과', value: 'SEND_RSLT', align: 'center', width: '65px', sortable: false },
          { text: '발송지점', value: 'SEND_USER_DEPT_NAME', align: 'center', width: '80px', sortable: false },
          { text: '발송직원', value: 'SEND_USER_NAME', align: 'center', width: '100px', sortable: false },
      ],
      // 이메일(상담APP)
      headers4: [
          { text: 'NO', value: 'ROWNUM', align: 'center', width: '30px', sortable: false },
          { text: '발송일자', value: 'REQ_DT', align: 'center', width: '100px', sortable: false },
          { text: '발송시간', value: 'REQ_TM', align: 'center', width: '80px', sortable: false },
          { text: '제목', value: 'TITLE',  sortable: false },
          { text: '발송주소', value: 'RECP_EML_ADDR', align: 'center', width: '200px', sortable: false },
          // { text: '발송부서', value: 'colm03', align: 'center', width: '120px', sortable: false },
          { text: '발송직원', value: 'SEND_NM', align: 'center', width: '120px', sortable: false },
      ],
      // 캘린더신청이력
      headers5: [
          { text: 'NO', value: 'ROWNUM', align: 'center', width: '40px', sortable: false },
          { text: '접수일자', value: 'APLY_DT', align: 'center', width: '120px', sortable: false },
          { text: '신청유형', value: 'APLY_WORK', align: 'center', width: '120px', sortable: false },
          { text: '비고', value: 'RMRK', sortable: false },

          { text: '접수직원', value: 'CSLT_NM', align: 'center', width: '120px', sortable: false },
          { text: '처리직원', value: 'PROC_MAN_NM', align: 'center', width: '120px', sortable: false },
          { text: '처리일자', value: 'UPD_DTTM', align: 'center', width: '120px', sortable: false },
      ],
      // 문제행동이력
      headers6: [
          { text: 'NO', value: 'ROWNUM', align: 'center', width: '30px', sortable: false },
          { text: 'Contact일자', value: 'CNSL_BEGIN_DATE', align: 'center', width: '10%', sortable: false },
          { text: '시작시간', value: 'CNSL_BEGIN_TIME', align: 'center', width: '8%', sortable: false },
          { text: '종료시간', value: 'CNSL_EOT_TIME', align: 'center', width: '8%', sortable: false },
          { text: '대분류', value: 'CNSL_TY_LCLS', align: 'center', width: '15%', sortable: false },
          { text: '중분류', value: 'CNSL_TY_MDCLS', align: 'center', width: '15%', sortable: false },
          { text: '소분류', value: 'CNSL_TY_SCLS', align: 'center', sortable: false },
          { text: '상담사', value: 'CSLT_MAN', align: 'center', width: '12%', sortable: false },
          { text: '문제행동유형', value: 'PROB_BEHAV_NM', align: 'center', width: '10%', sortable: false },
      ],
      readEditorConf: {
        height: "150px",
        toolbar: [
          {name: 'insert', items: ['Table']}
        ],
        toolbarCanCollapse:  true,
        toolbarStartupExpanded: false,
      },
    }
},
computed: {
  ...mapGetters({
    geCustInfo: 'callStore/GE_CUST_INFO',
  }),
},
mounted() {
  eventBus.$on('busReRoadCnslTblRowClick', () => {
    this.reRoadCnslTblRowClick();
  });

  this.callCommonCode();
  this.initData();
},
methods: {
  async callCommonCode(){
      if(this.preDate) this.preDate = await this.mixin_getSvrPrevDate("%Y%m%d");

      this.dates[0] = this.preDate.TO_MONTH_12;
      this.dates[1] = this.preDate.TO_DAY;
      this.dates[2] = this.preDate.TO_DAY_7;

      let codeName = ['CRS014','CRS000'];
      this.common_code = await this.mixin_common_code_get_all(codeName);

     // this.selectCnslHistInf();
  },
  async initData() {
      //this.items = this.cnslhist;
      this.isLoading    = false;
      this.lastPageTab1 = this.lastPageYn;
      this.lastPageTab2 = false;
      this.lastPageTab3 = false;
      this.lastPageTab4 = false;
      this.lastPageTab5 = false;
      this.lastPageTab6 = false;

      this.active_tab = 0;
      this.expanded = [];
      this.expanded1 = [];
      this.expanded2 = [];
      this.expanded3 = [];
      this.expanded4 = [];
      this.expanded5 = [];
      //this.cnslHistPageNo = 1;

      if(!this.ASP_CUST_KEY) this.ASP_CUST_KEY = "001";
      this.changeCounselTypeKey(this.ASP_CUST_KEY, "*", "0");
  },
  cnslTblRowClick: function(item, row) {
      this.selectedRow = row;
      //this.$emit('print', '31729');
      if(this.selectedId===item.ROWNUM){
        row.select(false);
        row.expand(false);
        this.selectedId=item.name;
      }else{
        row.select(true);
        this.selectedId=item.ROWNUM;
        row.expand(true);
      }
      console.log("cnslTblRowClick item ", item)
      // if(!this.mixin_isEmpty(item.CNSL_TY_SCLS)) this.changeCallType(item.CNSL_TY_SCLS);
      // else if(!this.mixin_isEmpty(item.CNSL_TY_MDCLS)) this.changeCallType(item.CNSL_TY_MDCLS);
      // else if(!this.mixin_isEmpty(item.CNSL_TY_LCLS)) this.changeCallType(item.CNSL_TY_LCLS);
  },
  reRoadCnslTblRowClick: function() {
    if(this.selectedRow.length != 0 ){
      this.selectedRow.select(false);
      this.selectedRow.isSelected = false;
      this.selectedRow.expand(false);
      this.selectedId='';
    }
    if(this.selectedRow1.length != 0 ){
      this.selectedRow1.select(false);
      this.selectedRow1.isSelected = false;
      this.selectedRow1.expand(false);
      this.selectedId1='';
    }
    if(this.selectedRow2.length != 0 ){
      this.selectedRow2.select(false);
      this.selectedRow2.isSelected = false;
      this.selectedRow2.expand(false);
      this.selectedId2='';
    }
    if(this.selectedRow3.length != 0 ){
      this.selectedRow3.select(false);
      this.selectedRow3.isSelected = false;
      this.selectedRow3.expand(false);
      this.selectedId3='';
    }
    if(this.selectedRow4.length != 0 ){
      this.selectedRow4.select(false);
      this.selectedRow4.isSelected = false;
      this.selectedRow4.expand(false);
      this.selectedId4='';
    }
    if(this.selectedRow5.length != 0 ){
      this.selectedRow5.select(false);
      this.selectedRow5.isSelected = false;
      this.selectedRow5.expand(false);
      this.selectedId5='';
    }                              
  },    
  onDbclickRow: function (e, { item }) {
//       let recvObj = JSON.parse(item);
// JSON.parse(JSON.stringify(item));
    this.changeCallType(item.CNSL_TY_SCLS);
    // this.changeCounselTypeKey(this.ASP_CUST_KEY, item.CNSL_TY_MDCLS, "2");
    console.log("item  ", item )
    console.log("cnslhist  ", this.cnslhist )

    // this.cnslHistDtl.CNSL_TY_SCLS = "171717"
    
    this.cnslHistDtl = JSON.parse(JSON.stringify(item));
    this.cnslHistDtl.CNSL_CNTN_MDF  = item.CNSL_CNTN;
    this.cnslHistDtl.CNSL_BEGIN_DTM = item.CNSL_BDATE + " " + item.CNSL_BEGIN_TM;
    this.cnslHistDtl.CNSL_END_DTM   = this.mixin_dateFormat(item.CNSL_EDATE) + " " + item.CNSL_END_TM;
    this.dialogHistory = true;
  },
  tmsRowClick: function (item, row) { 
    //console.log(">>>>>>"+ this.selectedId+"/" +item.ROWNUM);
      this.selectedRow2 = row;

    if(this.selectedId2===item.SEQ){
      row.select(false);
      row.expand(false);
      this.selectedId2=item.name;
    }else{
      row.select(true);
      this.selectedId2=item.SEQ;
      row.expand(true);
    }
  },
  smsRowClick: function (item, row) { 
    //console.log(">>>>>>"+ this.selectedId+"/" +item.ROWNUM);
      this.selectedRow1 = row;

    if(this.selectedId1===item.ROWNUM){
      row.select(false);
      row.expand(false);
      this.selectedId1=item.name;
    }else{
      row.select(true);
      this.selectedId1=item.ROWNUM;
      row.expand(true);
    }
  },
  emailRowClick: function (item, row) { 
    //console.log(">>>>>>"+ this.selectedId+"/" +item.ROWNUM);
      this.selectedRow3 = row;

    if(this.selectedId3 == item.ROWNUM){
      this.selectedRow3.select(false);
      this.selectedRow3.expand(false);
      this.selectedId3 = item.name;
    }else{
      this.selectedRow3.select(true);
      this.selectedRow3.expand(true);
      this.selectedId3 = item.ROWNUM;
    }
  },
  aplyWorkHistRowClick: function (item, row) { 
    //console.log(">>>>>>"+ this.selectedId+"/" +item.ROWNUM);
      this.selectedRow4 = row;

    if(this.selectedId4===item.ROWNUM){
      row.select(false);
      row.expand(false);
      this.selectedId4=item.name;
    }else{
      row.select(true);
      this.selectedId4=item.ROWNUM;
      row.expand(true);
    }
  },
  probBehavHistRowClick: function (item, row) { 
    //console.log(">>>>>>"+ this.selectedId+"/" +item.ROWNUM);
      this.selectedRow5 = row;

    if(this.selectedId5===item.ROWNUM){
      row.select(false);
      row.expand(false);
      this.selectedId5=item.name;
    }else{
      row.select(true);
      this.selectedId5=item.ROWNUM;
      row.expand(true);
    }
  },    
  /* 상담이력(TAB)조회*/
  tabClick: function(id) {
    this.active_tab = id;
    let url  = "";
    let srve = "";

    this.addDataYn = true;

    this.PAGE_NO2 = 1;

    if(id == 0) {
      this.lastPageTab1 = false;
    }
    if(id == 1) {
      this.lastPageTab2 = false;
      this.smsHistList  = [];
    }
    if(id == 2) { //SMS(MINE)
      this.lastPageTab3 = false;
      this.addDataYn    = false;
      this.smsMineHistList = [];
    }
    if(id == 3) {
      this.lastPageTab4  = false;
      this.emailHistList = [];
    }
    if(id == 4) {
      this.lastPageTab5 = false;
      this.aplyWorkHistList = [];
    }
    if(id == 5) {
      this.lastPageTab6 = false;
      this.probBehavHistList = [];
    }

    if(id == 0) {
      url  = "";
      srve = "";
    }
    else if(id != 0) {
      url  = "/api/phone/main/cnslHist_tab/list";
      srve = "phone.main.cnsl-tab";
      this.selectCnslHistTab(id, url, srve, this.PAGE_NO2);
    }
  },
  /* 상담이력(TAB)조회*/
  selectCnslHistTab: async function (id, url, srve, pageNo) 
  {
    // if(this.cnslhist.length == 20) this.PAGE_NO = 2;
    //   else this.PAGE_NO = this.PAGE_NO +1;
    // header 세팅
    if(this.mixin_isEmpty(this.srchCustNo)) {
      return false;
    }

    if(id == 1  && this.smsHistList.length%this.ROW_CNT > 0) {
      this.lastPageTab2 = true;
      return false;
    } else if(id == 2  && this.smsMineHistList.length%this.ROW_CNT > 0) {
      this.lastPageTab3 = true;
      return false;
    } else if(id == 3  && this.emailHistList.length%this.ROW_CNT > 0) {
      this.lastPageTab4 = true;
      return false;
    } else if(id == 4  && this.aplyWorkHistList.length%this.ROW_CNT > 0) {
      this.lastPageTab5 = true;
      return false;
    } else if(id == 5  && this.probBehavHistList.length%this.ROW_CNT > 0) {
      this.lastPageTab6 = true;
      return false;
    }

    let requestData = {
              headers: {
              ASYNC: false,
              METHOD: "list",
              SERVICE: srve,
              TYPE: "BIZ_SERVICE",
              URL: url
              },
              sendData: {},
    };

    let strDate = this.dates[0];
    let endDate = this.dates[1];

    if(id == 1 || id == 3 ) {
      if(strDate.length == 8) strDate = this.mixin_dateFormat(strDate);
      if(endDate.length == 8) endDate = this.mixin_dateFormat(endDate);
    }

    requestData.sendData["ASP_CUST_KEY"] = this.ASP_CUST_KEY;
    requestData.sendData["CUST_NO"]   = this.srchCustNo;
    requestData.sendData["TAB_ID"]    = id;
    requestData.sendData["CHK_BL"]    = false;

    requestData.headers["ROW_CNT"]   = this.ROW_CNT;
    requestData.headers["PAGES_CNT"] = this.PAGE_NO2;

    if(id == 1 || id == 3 ) {
      requestData.sendData["CUST_NO"] = this.srchCustNo;
      requestData.sendData["FROM"] = strDate;
      requestData.sendData["TO"] = endDate;
    } else if(id == 2) {
      requestData.sendData["USER_ID"] = this.srchCustNo;
      requestData.sendData["FROM"] = this.dates[2];
      requestData.sendData["TO"] = endDate;
      requestData.sendData["START_ROW"] = this.ROW_CNT*this.PAGE_NO2;
    }
    else if(id == 4) {
      requestData.sendData["STR_APLY_DT"] = strDate;
      requestData.sendData["END_APLY_DT"] = endDate;
    } else if(id == 5) {
      requestData.sendData["STR_RECP_DT"] = strDate;
      requestData.sendData["END_RECP_DT"] = endDate;
    }

    this.isLoading = true;
    this.common_postCall(requestData).then(res => {
      this.isLoading = false;
      if (this.mixin_isEmpty(res) || res.HEADER.COUNT == 0) {
        let errMsg = res.HEADER.ERROR_MSG;
        errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
        this.common_alert(errMsg, 'error');

        if(id == 1) this.lastPageTab2 = true;
        if(id == 3) this.lastPageTab4 = true;
        if(id == 4) this.lastPageTab5 = true;
        if(id == 5) this.lastPageTab6 = true;
        return false;
      }

      if(res.HEADER.ERROR_FLAG) {
        let errMsg = res.HEADER.ERROR_MSG;
        errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
        this.common_alert(errMsg + `\n 오류가 발생하였습니다.`, 'error');
        return false;
      }

      if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
          if(id == 1) {
            for(let d of res.DATA) {
              for(let k of Object.keys(d)) {
                if(k === 'REQ_TM') {
                  let date = d[k];
                  if(date.length > 8) d[k] = date.substr(0,8);
                }
                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
              }
              this.smsHistList.push(d)
            }
          }else if(id == 2) {
            for(let d of res.DATA) {
              for(let k of Object.keys(d)) {
                if(k === 'MAKE_DT') d[k] = this.mixin_dateFormat(d[k])
                if(k === 'CELLPHONE_NO') d[k] = this.mixin_newPhoneFormat(d[k])
                if(k === 'SUBJECT') d[k] = this.restoreXSS(d[k])
                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
              }
              this.smsMineHistList.push(d)
            }
          }
          else if(id == 3) {
            for(let d of res.DATA) {
              for(let k of Object.keys(d)) {
                if(k === 'REQ_TM') {
                  let date = d[k];
                  if(date.length > 8) d[k] = date.substr(0,8);
                }
                if(k === 'TITLE') d[k] = this.restoreXSS(d[k]);
                if(k === 'CONTENT') d[k] = this.restoreXSS_CKeditor(d[k]);
                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
              }
              this.emailHistList.push(d)
            }
          }
          else if(id == 4) {
            for(let d of res.DATA) {
              this.aplyWorkHistList.push(d)
            }
          }
          else if(id == 5) {
            for(let d of res.DATA) {
              this.probBehavHistList.push(d)
            }
          }
        }
      } else {
        let sMsg = "조회 중 ";
        if(id == 1) sMsg = "SMS(상담APP) 조회 중";
        if(id == 2) sMsg = "SMS(MINE) 조회 중";
        if(id == 3) sMsg = "이메일(상담APP) 조회 중";
        if(id == 4) sMsg = "캘린더신청이력 조회 중";
        if(id == 5) sMsg = "문제행동  이력 조회 중";
        this.common_toast(sMsg + `\n 오류가 발생하였습니다.`, 'error');
      }
      this.common_toast("정상 처리되었습니다.", "done");
    });
  },
  /* 고객게시판(홈페이지) */
  fnCustBoard: function() {
    // if(this.mixin_isEmpty(this.geCustInfo.HTS_USER_ID)) {
    //   this.common_alert(`고객번호를 입력해주세요.`, 'error');
    //   return false;
    // }
    //http://192.168.22.52/adm/board/WSBBSUseList.jsp?bltn_no=20&searchKey=user_id&searchWord=etk872&gubun=1
    //http://www.etrade.co.kr/adm/board/WSBBSUseList.jsp?bltn_no=20&searchKey=user_id&searchWord=etk872&gubun=1

    //window.open('http://www.etrade.co.kr/adm/board/WSBBSUseList.jspFCSAQ02100?bltn_no=20&seabchKey=user_id&searchWord=' + this.srchCustNo);
    //window.open("http://www.etrade.co.kr/adm/board/WSBBSUseList.jsp?bltn_no=20&searchKey=user_id&gubun=1&searchWord=" + this.srchCustNo);
    //window.open("http://192.4.15.199/EtwQnaNoAdmin/List.jsp?skey=reg_id&sval=" + this.srchCustNo);
    //let SERVER_URL1 = 'http://10.100.10.60/EtwQnaNoAdmin/List.jsp';

    window.open("http://10.100.10.60/EtwQnaNoAdmin/List.jsp?skey=reg_id&sval=" + this.geCustInfo.HTS_USER_ID);
    //window.open(SERVER_URL1 + "?skey=reg_id&sval="+ this.srchCustNo, '고객게시판', 'width=780,height=360');
  },
  /* 상담이력 저장버튼 클릭 */
  fnSaveCnslHist() {
    if(this.mixin_isEmpty(this.cnslHistDtl.CNSL_HIST_NO)) {
      this.common_alert(`상담이력번호가 없습니다. 선택 후 저장 하십시오.`, 'info');
      return false;
    }

    let userId = this.$store.getters["userStore/GE_USER_ID"];

    if(userId != this.cnslHistDtl.CNSL_ID) {
      this.common_alert(`다른 직원이 입력한 상담 정보는 \n수정 할 수 없습니다.`, 'info');
      return false;
    }

    if(this.CURR_DT != this.cnslHistDtl.CNSL_BDATE) {
      this.common_alert(`당일 입력한 상담 정보만 \n수정 가능 합니다.`, 'info');
      return false;
    }

    if(this.mixin_isEmpty(this.cnslHistDtl.CNSL_TY_LCLS)) {
      this.common_alert(`상담유형(대) 선택 해주세요.`, 'info');
      return false;
    }

    if(this.mixin_isEmpty(this.cnslHistDtl.CNSL_TY_MDCLS)) {
      this.common_alert(`상담유형(중) 선택 해주세요.`, 'info');
      return false;
    }

    let ls_one_char = "";
    let re = /[.,<>/?;:{}~!@\#$%^&*\()\=+_']/gi;
        
    ls_one_char = this.cnslHistDtl.CNSL_CNTN.replace(re,"");

    if(this.mixin_isEmpty(ls_one_char) || ls_one_char.length < 1) {
      this.common_alert(`상담내용은 특수문제 제외 1자 이상 적으세요.`, 'info');
      return false;
    }

    if(!this.MOD_YN) {
      this.common_alert(`수정 사항이 없습니다.`, 'info');
      //return false;
    }

    this.common_confirm('상담이력을 저장 하시겠습니까?', async () => {
      this.cnslHistDtl.ASP_CUST_KEY = this.ASP_CUST_KEY;
      this.cnslHistDtl.CHNG_ID = this.$store.getters["userStore/GE_USER_ID"];
      this.cnslHistDtl.CNSL_CNTN = this.cnslHistDtl.CNSL_CNTN_MDF;

      // header 세팅
      let requestData = {
        headers: {
          ASYNC: true,
          METHOD: "modify",
          SERVICE: "phone.main.cnslt-hist",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/cnslt-hist/modify"
        },
        sendData: this.cnslHistDtl
      };
      this.common_postCall(requestData).then(response => {
        this.isLoading = false;
        this.dialogHistory=false;
        if(response.HEADER.ERROR_FLAG) {
          console.log("상담저장 ERR_MSG:["+response.HEADER.ERROR_MSG+"]");
          this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
          return false;
        }
        this.common_alert("성공적으로 저장했습니다.");
        let item = {CLNT_NO : this.srchCustNo};
        this.$emit('selCnslHist', item);
      })
      .catch((err) => {
        this.isLoading = false;
        this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
        console.log(err);
      });
    }, null, () => {
      return;
    }, null, 'confirm');
  },
  fnNextSearch(){
    if(this.active_tab == 0) { this.selectCnslHistInf()
    } else {

      let id = this.active_tab;

      if(id == 1  && this.lastPageTab2) {
        return false;
      } else if(id == 3  && this.lastPageTab4) {
        return false;
      } else if(id == 4  && this.lastPageTab5) {
        return false;
      } else if(id == 5  && this.lastPageTab6) {
        return false;
      }

      this.PAGE_NO2 = this.PAGE_NO2 +1;
      this.selectCnslHistTab(id,"/api/phone/main/cnslHist_tab/list", "phone.main.cnsl-tab", this.PAGE_NO2)
    }
  },
  selectCnslHistInf: async function () {

      if(this.mixin_isEmpty(this.srchCustNo)) {
        this.common_alert(`고객번호를 입력해주세요.`, 'error');
        return false;
      }

      if(this.lastPageTab1) {
        //this.common_toast("마지막 페이지입니다.", "error");
        return false;
      }

      if(this.cnslhist.length%50 > 0) {
          //this.common_toast("마지막 페이지입니다.", "error");
          this.lastPageTab1 = true;
          return false;
      }

      if(this.cnslhist.length == 50) this.PAGE_NO = 2;
      else this.PAGE_NO = this.PAGE_NO +1;

      console.log("PAGE_NO==>"+this.PAGE_NO + "/"+ this.cnslhist.length);

          // header 세팅
          let requestData = {
              headers: {
              ASYNC: false,
              METHOD: "list",
              SERVICE: "phone.history.cnsl-list",
              TYPE: "BIZ_SERVICE",
              URL: "/api/phone/history/cnsl/list"
              },
              sendData: {},
          };

          requestData.sendData["ASP_CUST_KEY"] = this.ASP_CUST_KEY;
          requestData.sendData["CALL_TIME"] = 0;
          requestData.sendData["CONTT_CD"] = "01";
          // requestData.sendData["CNSL_BEGIN_DATE"] = this.dates[0].replaceAll("-", "");
          // requestData.sendData["CNSL_END_DATE"] = this.dates[1].replaceAll("-", "");
          requestData.sendData["CLNT_NO"]   = this.srchCustNo;
          requestData.sendData["MAIN_YN"]   = "Y";
          requestData.sendData["SORT_DRCT"] = "desc";
          requestData.sendData["SORT_ORDR"] = "BEGIN_DTTM";
          requestData.headers["ROW_CNT"]   = 50;
          requestData.headers["PAGES_CNT"] = this.PAGE_NO;
          this.isLoading = true;
          this.common_postCall(requestData).then(res => {
              // if (this.selectedRow != "") {
              //     this.activeRow(this.selectedRow, false);
              //     this.selectedRow = [];
              // }
             console.log("========"+res.HEADER.TOT_COUNT);
              if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
                  if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
                      for(let d of res.DATA) {
                          for(let k of Object.keys(d)) {
                              if(k === 'CNSL_BDATE') d[k] = this.mixin_dateFormat(d[k])
                              else if(k === 'CNSL_BEGIN_TM') d[k] = this.mixin_timeFormat(d[k])
                              else if(k === 'CNSL_END_TM') d[k] = this.mixin_timeFormat(d[k])
                              else if(k === 'CNSL_CNTN') {
                                d[k] = this.restoreXSS2(d[k]);
                                d[k] = d[k].replaceAll("<BR>", "\r");
                              }
                              else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                          }
                          this.cnslhist.push(d)
                      }
                  }
                  this.isLoading = false;
              } else {
                  this.common_toast(`시스템에 오류가 발생하였습니다.`, 'error')
              }
              this.common_toast("정상 처리되었습니다.", "done");
          });
  },
  async changeCallType(cnslTy){

    console.log(" changeCallType(cnslTy)  ",cnslTy )
    if(this.mixin_isEmpty(cnslTy)) {
      this.cnslHistDtl.CNSL_TY_LCLS = "";
      return;
    }

    let cnslTyL = (cnslTy.length>=2?cnslTy.substr(0, 2):'');
    let cnslTyM = (cnslTy.length>=4?cnslTy.substr(0, 4):'');
    let cnslTyS = (cnslTy.length==6?cnslTy:'');

    if(cnslTy.length  == 4)  cnslTyS = cnslTy + "00";

    // this.CNSL_TY_CODE_1 = await this.mixin_counsel_type_code_div_get(
    //   "Y",
    //   this.ASP_CUST_KEY,
    //   "P",
    //   "*",
    //   "선택"
    // );

    this.CNSL_TY_CODE_2 =  await this.mixin_counsel_type_code_div_get(
      "Y",
      this.ASP_CUST_KEY,
      "P",
      cnslTyL,
      "선택"
    );

    
    this.CNSL_TY_CODE_3 = await this.mixin_counsel_type_code_div_get(
        "Y",
        this.ASP_CUST_KEY,
        "P",
        cnslTyM,
        "선택"
    );
    
    this.cnslHistDtl.CNSL_TY_LCLS  = cnslTyL;
    this.cnslHistDtl.CNSL_TY_MDCLS = cnslTyM;
    this.cnslHistDtl.CNSL_TY_SCLS  = cnslTyS;
  },
  changeCounselTypeKey: async function (asp_cust_key, spst_counsel_typ_cd, data_type) {
    let CNSL = await this.mixin_counsel_type_code_div_get(
      "Y",
      this.ASP_CUST_KEY,
      "P",
      spst_counsel_typ_cd,
      "전체"
    );
    if (data_type === "0") {
      this.CNSL_TY_CODE_1 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
      this.cnslHistDtl.CNSL_TY_LCLS  = '';
      this.cnslHistDtl.CNSL_TY_MDCLS = '';
      this.cnslHistDtl.CNSL_TY_SCLS  = '';
      this.CNSL_TY_CODE_1 = CNSL;
    } else if (data_type === "1") {
      if(this.cnslHistDtl.CNSL_TY_LCLS == "" || this.cnslHistDtl.CNSL_TY_LCLS == undefined) {
        this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
        this.cnslHistDtl.CNSL_TY_MDCLS = '';
      } else {
        this.CNSL_TY_CODE_2 = CNSL;
        // this.cnslHistDtl.CNSL_TY_MDCLS = spst_counsel_typ_cd + "01";
        this.cnslHistDtl.CNSL_TY_MDCLS = this.CNSL_TY_CODE_2[1].CD;
        this.changeCounselTypeKey(this.ASP_CUST_KEY, this.cnslHistDtl.CNSL_TY_MDCLS, '2');
      }

      this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
      this.cnslHistDtl.CNSL_TY_SCLS = '';
    } else if (data_type === "2") {
      if(this.cnslHistDtl.CNSL_TY_MDCLS == "" || this.cnslHistDtl.CNSL_TY_MDCLS == undefined) {
        this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
        this.cnslHistDtl.CNSL_TY_SCLS = '';
      } else {
        this.CNSL_TY_CODE_3 = CNSL;
        this.cnslHistDtl.CNSL_TY_SCLS =  this.CNSL_TY_CODE_3[1].CD;
      }
    } 
  },
  startDate(e) {
    this.dates[0] = e;
  },
  endDate(e) {
    this.dates[1] = e;
  },
  
  openP101102(){
    this.$openWindow('P101102','P101102','500','800');
  },
},
watch: {
  srchCustNo: function(nVal) {
    this.smsHistList = [];
    this.smsMineHistList = [];
    this.emailHistList = [];
    this.aplyWorkHistList = [];
    this.probBehavHistList = [];
    //this.PAGE_NO = this.cnslHistPageNo;
  }
},
};
</script>

<style></style>