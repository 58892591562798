<template>
    <div class="jh-form-wrap">
        <div class="jh-align-middle jh-align-space">
            <div class="is-left" style="width: 252px;">
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>고객성향</label></th>
                            <td>
                                <div class="jh-cols">
                                    <v-select class="jh-form" 
                                    v-model="custInfo.NOTICE_CD" 
                                    :items="mixin_common_code_get(this.common_code, 'CRS003', '선택')"
                                    no-data-text="조회된 값이 없습니다"
                                    ></v-select>
                                    <v-btn class="jh-btn has-icon-only" @click="saveCustTyp"><i class="jh-icon-disket"></i></v-btn>
                                    <!--span style="width: 0px;" class="is-value is-bold">{{message}}</span-->
                                    <!--input type="text" class="jh-form is-prma" :value="message"-->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="is-right" style="width: 260px;">
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>문제행동</label></th>
                            <td>
                                <input type="text" class="jh-form is-prma" :style="prbmStyle1" value="국내" disabled>
                                <input type="text" class="jh-form is-prma" :style="prbmStyle2" value="해주" disabled>
                                <input type="text" class="jh-form is-prma" :style="prbmStyle3" value="해선" disabled>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <table class="jh-tbl-detail is-mt-5">
            <colgroup>
                <col width="100px">
                <col>
                <col width="100px">
                <col>
            </colgroup>
            <tbody>
                <tr>
                    <th><label class="is-required">계좌번호</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="text" maxlength="13" class="jh-form is-col-fix" style="width: 120px;" :disabled="geCnslSaveYn == 'N' && geCallStt== 'A_SETACW'"  v-model="ACCOUNT" oninput="this.value = this.value.replace(/[^0-9-]/g, '');" @keyup="keyUpInp('accno', ACCOUNT)" @keyup.enter="selectCustInfo('1')">
                            <input type="text" class="jh-form is-trns is-right" v-model="custInfo.PRDT_DTL_NM" style="width: 150px;" disabled>
                            <!-- <span class="is-value is-bold" v-if="custInfo.PRDT_DTL_NM!=''">{{custInfo.PRDT_DTL_NM}}</span> -->
                            <v-btn class="jh-btn has-icon-only" @click="getPinpad(1)"><i class="jh-icon-keypad"></i></v-btn>
                        </div>
                    </td>
                    <th><label>고객명</label></th>
                    <td>
                        <input type="text" class="jh-form is-trns is-bold" style="font-size: 14px;" :value="CNM" disabled>
                    </td>
                </tr>
                <tr>
                    <th rowspan="2"><label class="is-required">주민번호</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="text" maxlength="14" class="jh-form is-bg-light-red" :disabled="geCnslSaveYn == 'N' && geCallStt== 'A_SETACW'"  v-model="CL_CID" @mousedown="LST01=true" @blur="keyUpInp('bsn', CL_CID)" oninput="this.value = this.value.replace(/[^0-9-]/g, '');" @keyup="keyUpInp('cid', CL_CID)"  @keyup.enter="selectCustInfo('2')">
                            <v-btn class="jh-btn has-icon-only" @click="getPinpad(2)"><i class="jh-icon-keypad"></i></v-btn>
                            <transition name="slide" >
                                <div class="jh-drop-layer" style="top:106px;width:160px" v-show="LST01" v-if="custList.length >= 2">
                                  <div class="jh-drop-layer-header">
                                    <h2></h2>
                                    <div class="is-right">
                                      <v-btn class="jh-btn is-icon" @click="LST01=false"><i class="jh-icon-close"></i></v-btn>
                                    </div>
                                  </div>
                                    <div class="jh-drop-layer-body" v-if="custList.length >= 0">
                                        <ul class="jh-list-grid">
                                            <li v-for="item in custList">
                                                <span class="is-value is-bold" @click="setCid(item.SRCH_CL_CID)">{{item.CL_CID}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </td>
                    <th><label>계좌비번</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="password" maxlength="8" :class="acntClassNm" v-model="ACCOUNT_PW" :disabled="pwdYn" @keyup.enter="verifyAccount(ACCOUNT, ACCOUNT_PW)">
                            <v-btn class="jh-btn has-icon-only" @click="getPinpad(3)"><i class="jh-icon-password"></i></v-btn>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="jh-cols is-space-5">
                            <span class="is-value is-bold" >{{custInfo.AGE}}</span>
                            <span class="is-value is-bold" v-if="custInfo.SEX!=''">{{custInfo.SEX}}</span>
                            <span class="is-value is-bold is-txt-red" v-if="custInfo.isMinor">미성년자</span>
                            <span class="is-value is-bold is-txt-green" v-if="custInfo.isForeigner">외국인</span>
                        </div>
                    </td>
                    <th><label>은행비번</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="password"  maxlength="8"  :class="bankClassNm" v-model="BANK_PW" @keyup.enter="verifyBankPw(ACCOUNT, BANK_PW)" disabled>
                            <v-btn class="jh-btn has-icon-only" @click="getPinpad(4)"><i class="jh-icon-password"></i></v-btn>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label class="is-required">고객번호</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="text" maxlength="10" class="jh-form is-col-fix" :disabled="geCnslSaveYn == 'N' && geCallStt== 'A_SETACW'" v-model="custInfo.CLNT_NO" style="width: 120px;" oninput="this.value = this.value.replace(/[^0-9a-z]/g, '');" @keyup="keyUpInp('clntno', custInfo.CLNT_NO)" @keyup.enter="selectCustInfo('3')">
                            <input type="text" class="is-value is-bold" v-model="custInfo.CUS_LEVEL" disabled>
                        </div>
                    </td>
                    <th><label>정보변경</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="text" class="is-value is-bold" style="width: 80px" v-model="autoBeginDateFormat" disabled>
                            <input type="text" class="is-value is-bold" v-model="custInfo.TR_LAST_CHNG_USER" disabled>
                            <!-- <v-btn class="jh-btn is-sm">조회</v-btn> -->
                            <v-btn class="jh-btn has-icon-only is-main" style="width: 38px;" @click="selectCustInfo('1')"><i class="jh-icon-refresh is-blue"></i></v-btn>
                            <!--v-btn class="jh-btn has-icon-only is-main" style="width: 38px;" @click="getIvrInptNum()"><i class="jh-icon-refresh is-blue"></i></v-btn-->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
          <!-- sort-by="AcntOpnDt"
            :sort-desc="dDESC" -->
        <v-data-table id="scroll-target"
            class="jh-grid is-mt-5"
            :headers="headers"
            :items="acntItems"
            :search="search"
            fixed-header
            item-key="index"
            height="170px"
            :items-per-page="500"
            v-scroll:#scroll-target="onScroll"
            :item-class="isActiveRow"
            :sort-by.sync="listSort"
            :sort-desc.sync="dDESC"
            hide-default-footer
            @click:row="setCnslDetail"
            @dblclick:row="onDblClickRow">
            <template v-slot:item.MgempNmPart="{ item }"><div class="is-ellips" :title="item.MgempNmPart">{{ item.MgempNmPart }}</div></template>
            <template v-slot:item.AcntNo="{ item }">
                <span class="is-txt-red is-bold" v-if="item.acctConf === 'Y'">{{ item.AcntNo }}</span>
                <span class="is-bold" v-else-if="item.selAccYn === 'Y'">{{ item.AcntNo }}</span>
                <span v-else>{{ item.AcntNo }}</span>
            </template>
            <template v-slot:header.AcntStatNm="{ header }">
                <!-- {{ header.text }} -->
                <span ref="AcntStatNm_TXT">활동</span>
                <v-menu offset-y>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn class="jh-btn is-sm is-icon" v-bind="attrs" v-on="on">
                            <i class="jh-icon-more-vert"></i>
                        </v-btn>
                    </template>
                    <v-list v-model="tabAccount">
                    <v-list-item v-for="(item, index) in filterItems" 
                        :key="item.id" 
                        @click="filterAction(item)">
                        <v-list-item-title>{{ item.ITEM_NM }}</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!--template v-slot:item.MgempNm="{ item }"><div class="is-ellips" :title="item.MgempNo">{{ item.colm03 }}</div></template-->
        </v-data-table>
        <!-- 조회 모달 -->
        <v-dialog
          max-width="810"
          persistent
          v-model="dialogM110101P02"
          content-class="square-modal maincalltrans-modal"
        >
            <dialog-M110101P02
                headerTitle="고객정보조회"
                @hide="hideDialog('M110101P02')"
            >
            </dialog-M110101P02>
        </v-dialog>
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import DialogM110101P02 from "../page/old/M110101P02.vue"; //고객정보조회
import api from "../../store/apiUtil.js";
import { eventBus } from "@/store/eventBus.js";
import { mapGetters } from "vuex"

let jsonData;

export default {
  name: "CallCustInfo",
  mixins: [mixin],
  components: {
      DialogM110101P02,
  },
  
  data() {
      return {
        search: "활동",
        searchData: {},
        searchVo: function() {
            this.ASP_CUST_KEY = '001',
            this.SRCH_TYPE    = '',
            this.SRCH_ACCOUNT = '',
            this.SRCH_CL_CID  = '',
            this.CLNT_NO      = ''
        },
        common_code: [], //공통코드

        custInfo: {},    //고객 정보 객체
        custList: [],    //고객리스트
        custVrfInfo: [], //고객계좌인증정보
        arrCusts: '',
        befCd: '2',

        LST01: false,
        pwdYn: false,
        message: '안녕하세요.',
        reverseMessage: '',
        ACCOUNT: '',
        CL_CID: '',

        listSort: "",
        dDESC: "",
        asort: "none",

        sinTongItem:{
            ACCOUNT   : '' // 계좌번호        
           ,ACCOUNT_PW: '' // 계좌비밀번호
           ,BANK_PW   : '' // 은행비밀번호
           ,CLNT_NO   : '' // 고객ID
           ,CL_CID    : '' // 실명확인번호
        },
        selectedRow: [], //선택된 로우
        scroll: 0,
        scrollTarget: null,

        TR_LAST_CHNG_DT: '',
        TR_LAST_CHNG_USER: '',
        ACCOUNT_PW: '',
        BANK_PW: '',
        VRF_ACCT_NO: '',
        VRF_ACCT_PWD: '',
        VRF_BANK_PWD: '',

        PIN_TYPE_NUM: 0, //폰패드 타입 번호
        CNS_NUM: '',

        // OB 정보
        ID: '',
        NTFCTN_1st: '',
        NTFCTN_Nth: '',
        CNSL_HIST_NO: '',
        CNM: '',

        // ARS 예약콜 정보
        SCDL_ID: '',
        IVR_IN_NO: '',
        IVR_IN_PATH: '',
        IVR_STD_TIME: '',
        IVR_MENT: '',

        // 전화예약콜 정보
        RSV_CALL_ID: '',
        POP_YN: '',
        MY_CALL_YN: '',

        prbmStyle1: {
            backgroundColor:'white',
            color: '#fff'
        },
        prbmStyle2: {
            backgroundColor:'white',
            color: '#fff'
        },
        prbmStyle3: {
            backgroundColor:'white',
            color: '#fff'
        },
        bankClassNm: 'jh-form',
        acntClassNm: 'jh-form',

        dialogM110101P02: false, //고객정보 조회 모달

        headers: [
            { text: 'NO', value: 'index', align: 'center', width: '40px', sortable: true },
            { text: '계좌번호', value: 'AcntNo', align: 'center', width: '98px', sortable: true },
            { text: '상품', value: 'PrdtDtlNm',align: 'center', width: '100px', sortable: true },
            { text: '관리점', value: 'MgmtBrnNm', align: 'center', width: '95px', sortable: false },
            { text: '관리자', value: 'MgempNmPart', align: 'left', sortable: false },
            { text: '개설일', value: 'AcntOpnDt', align: 'center', width: '85px', sortable: true },
            { text: '실명확인', value: 'InetOpnTpCdNm', align: 'center', width: '70px', sortable: false },
            { text: '활동', value: 'AcntStatNm', align: 'center', width: '85px', sortable: false },
            { text: 'P', value: 'DirectTp', align: 'center', width: '25px', sortable: false },
        ],
        acntItems: [],
        tabAccount: "tab2",
        filterItems: [
            { id: "tab1", ITEM_NM: "전체" },
            { id: "tab2", ITEM_NM: "활동" },
            { id: "tab3", ITEM_NM: "통폐합" },
            { id: "tab4", ITEM_NM: "고객폐쇄" }
        ],
      }
  },
  created() {
    this.searchData = new this.searchVo()
  },
  async mounted() { 
    this.callCommonCode();

    eventBus.$on('cusSearch', (paramKey, paramVal) => {
        
        //console.log("cusS["+paramKey+"],["+paramVal+"]");
        console.log(this.geCustInfo);

        if(paramKey=='1') this.custInfo.SRCH_ACCOUNT = paramVal;
        else if(paramKey=='2') this.custInfo.SRCH_CL_CID = paramVal;
        else if(paramKey=='3') this.custInfo.CLNT_NO = paramVal;
        else return;

        this.searchData = new this.searchVo()

        //this.initCustInfo();
        this.selectCustInfo(paramKey);
    });
    eventBus.$on('setCnslMode', (pVal) => {
        let srchTp = "";
        if(pVal == "init") {
            this.initCustInfo();
            this.$emit('initScr', "INFCLR");
        } else if(pVal == "clrCusts") {
            this.arrCusts = "";
        } else if(pVal == "search") {
            this.clearScr();
            this.custInfo = this.geCustInfo;
            this.selectCustInfo("1");
            if(!this.mixin_isEmpty(this.geCustInfo.CNSL_HIST_NO)) this.$emit('selSang', this.geCustInfo.CNSL_HIST_NO);
        } else if(pVal == "search2") {
            this.clearScr();
            this.custInfo = this.geCustInfo;
            this.selectCustInfo("2");
            if(!this.mixin_isEmpty(this.geCustInfo.CNSL_HIST_NO)) this.$emit('selSang', this.geCustInfo.CNSL_HIST_NO);
            console.log(">>geCustInfo>>>"+ JSON.stringify(this.geCustInfo));
        } else if(pVal == "search3") {
            this.clearScr();
            this.custInfo = this.geCustInfo;
            this.selectCustInfo("3");
            if(!this.mixin_isEmpty(this.geCustInfo.CNSL_HIST_NO)) this.$emit('selSang', this.geCustInfo.CNSL_HIST_NO);
        }
    });
    eventBus.$on('getIvrRcv', (pVal) => {
      console.log("$$$$$$getIvrInptNum");
      this.getIvrInptNum();
    });
  },
  beforeDestroy(){
    this.initCustInfo();
  },
  methods: {
    async callCommonCode(){
        let codeName = ['CRS003'];
        this.common_code = await this.mixin_common_code_get_all(codeName);

        this.custInfo.NOTICE_CD = '';
    },

    async initCustInfo(ADD) {
        //this.searchData = new this.searchVo()
        //console.log("------->sintong clr init SSS");
        //this.mixin_sintongReset();
        //console.log("------->sintong clr init END");
        this.custInfo = {}

        //고객정보 초기 설정
        // this.custInfo.CALL_TY = '01',
        this.custInfo.SRCH_ACCOUNT = '',
        this.custInfo.prbmCd1 = "white";
        this.custInfo.prbmCd2 = "white";
        this.custInfo.prbmCd3 = "white";
        if(ADD != 'Y') {
            this.custInfo.RSV_CALL_ID = '';
            this.RSV_CALL_ID = '';  // 전화예약콜ID init.
            this.POP_YN      = 'N'; // 2차상담팝업여부
            if(this.geCallStt != 'TALKING') {
                this.custList    = []; // 고객리스트 초기화
                this.custVrfInfo = []; //고객계좌인증정보
                this.arrCusts    = "";
            }
        }
        this.$store.commit('callStore/MU_CUST_INFO', this.custInfo);
        
        this.acntItems = []; //계좌정보리스트
        this.search = "활동";
        this.ACCOUNT= "";
        this.CL_CID= "";
        this.CNM= "";
        this.ACCOUNT_PW= "";
        this.BANK_PW= "";
        this.VRF_ACCT_NO= "";
        this.acntClassNm = 'jh-form';
        this.bankClassNm = 'jh-form';
        this.pwdYn = false;
    },
    clearScr(ADD) 
    {
        this.custInfo.prbmCd1 = "white";
        this.custInfo.prbmCd2 = "white";
        this.custInfo.prbmCd3 = "white";
        this.search = "활동";
        this.ACCOUNT= "";
        this.CL_CID= "";
        this.CNM= "";
        this.ACCOUNT_PW= "";
        this.BANK_PW= "";
        this.VRF_ACCT_NO= "";
        this.acntClassNm = 'jh-form';
        this.bankClassNm = 'jh-form';
        this.pwdYn = false;

        if(this.geCallStt != 'TALKING') {
            this.custVrfInfo = [];
            this.custList    = [];
        }
    },
    setNoCust(){
        this.initCustInfo("Y");
        this.$emit('initScr', "initEtc");
        this.ACCOUNT      = "";
        this.custInfo.SRCH_ACCOUNT = "";
        this.custInfo.NOTICE_CD = "";
        this.CL_CID       = "999999-9999999"
        this.custInfo.SRCH_CL_CID  = "9999999999999"
        this.custInfo.CLNT_NO      = ""
        this.custInfo.CNM          = "미인증고객"
        this.CNM                   = "미인증고객"
        if(!this.mixin_isEmpty(this.SCDL_ID)) this.custInfo.SCDL_ID = this.SCDL_ID;
        if(!this.mixin_isEmpty(this.POP_YN)) this.custInfo.POP_YN = this.POP_YN;
        if(!this.mixin_isEmpty(this.RSV_CALL_ID)) this.custInfo.RSV_CALL_ID = this.RSV_CALL_ID;

        this.$store.commit('callStore/MU_CUST_INFO', this.custInfo);
        // this.custInfo.SRCH_ACCOUNT = ""; // 계좌번호   
        this.custInfo.HTS_USER_ID  = ""; // 고객HTSID
        // this.custInfo.SRCH_CL_CID  = ""; // 실명확인번호
        this.custInfo.ACCOUNT_PW   = ""; // 계좌비번
        this.custInfo.BANK_PW      = ""; // 은행비번
        this.acntClassNm = 'jh-form';
        this.bankClassNm = 'jh-form';
        this.acntItems = []; //계좌정보리스트
        this.custInfo.prbmCd1 = "white";
        this.custInfo.prbmCd2 = "white";
        this.custInfo.prbmCd3 = "white";
        console.log("------->sintong clr srch SSS");
        this.sinTongSrch();

        

        try {
            if(this.geCallStt != "TALKING") {
                this.geCtiInfo.IVR_IN_NO = "";
                this.geCtiInfo.IVR_IN_PATH = "";
                this.geCtiInfo.IVR_STD_TIME = "";
                this.geCtiInfo.ERR_MENT = "";
                this.$store.commit("callStore/MU_CTI_INFO", this.geCtiInfo);
            }
            //호전환값 셋팅
            eventBus.reqTopPrc('setTrnsVal'); 
        } catch (error) {
            console.log("*************************************setTrnsVal 없다 잡아라3-22****************************************************************");
        }
    },
    clrCusPwd(item) {
        this.custInfo.PWD_TYPE   = "";
        this.custInfo.ACCT_CONF  = "";
        this.custInfo.ACCOUNT_PW = "";
        this.custInfo.BANK_CONF  = "";
        this.custInfo.BANK_PW = "";
        this.$store.commit('callStore/MU_CUST_INFO', this.custInfo);
        this.ACCOUNT_PW= "";
        this.BANK_PW= "";
        this.VRF_ACCT_NO = "";
        this.VRF_ACCT_PWD = "";
        this.acntClassNm = 'jh-form';
        this.bankClassNm = 'jh-form';
        this.pwdYn = false;
        this.custList    = []; // 고객리스트 초기화
		this.custVrfInfo = [];
        this.sendCustInfo();
        for(let d of this.acntItems) {
            d['acctConf'] = '';
            d['accountPw'] = '';
            d['bankConf'] = '';
            d['bankPw'] = '';
	    }
        let acntItemSort = this.sortJSON( this.acntItems, "index", "asc"); 
        this.acntItems = acntItemSort;
    },

    
    /**콜정보 인입 처리
     * 고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
     */
    async setCustMM(item) {
        console.log("setcust:" + item.STYPE);
        let stp = item.STYPE;
        this.VRF_ACCT_NO = "";
        this.VRF_ACCT_PWD = "";
        this.VRF_BANK_PWD = "";
        if(stp == "1" || stp == "2" || stp == "3") {
            //this.custInfo = item;
            //this.clearScr();
            this.custInfo = item;
            if(item.COMP_TYPE == "1") {
                this.VRF_ACCT_NO = item.SRCH_ACCOUNT;
                this.VRF_ACCT_PWD = item.ACCT_PWD;
                this.setVrfInfo(item);
            }
            // else if(item.COMP_TYPE == "2") {
            //     this.setVrfInfo(item);
            // } 

            await this.selectCustInfo(stp);
        }  else if(stp == "setCustE") {
            this.clearScr();
            this.custInfo = item;
            this.CNM      = this.custInfo.CNM;
            this.CL_CID   = this.custInfo.CL_CID;
            this.custInfo.CLNT_NO      = "";
            this.custInfo.NOTICE_CD    = "";
            this.custInfo.SRCH_ACCOUNT = "";
            this.ACCOUNT               = "";
            this.custInfo.prbmCd1 = "white";
            this.custInfo.prbmCd2 = "white";
            this.custInfo.prbmCd3 = "white";
            this.$store.commit("callStore/MU_CUST_INFO", this.custInfo);
            eventBus.reqTopPrc('setTrnsVal');
            this.acntItems = []; //계좌정보리스트
        } else if(stp == "setCustInf") {
            console.log("!!!setCustInf!!!");
            this.clearScr();
            this.custInfo = item;
            this.CNM      = this.custInfo.CNM;
            this.CL_CID   = this.custInfo.CL_CID;
            this.custInfo.CLNT_NO      = "";
            this.custInfo.NOTICE_CD    = "";
            this.custInfo.SRCH_ACCOUNT = "";
            this.ACCOUNT               = "";
            this.custInfo.prbmCd1 = "white";
            this.custInfo.prbmCd2 = "white";
            this.custInfo.prbmCd3 = "white";
            // console.log("------->sintong clr aSSS");
            // this.mixin_sintongReset();
            // console.log("------->sintong clr aEND");
            this.$store.commit("callStore/MU_CUST_INFO", this.custInfo);
            this.acntItems = []; //계좌정보리스트
            try {
                //호전환값 셋팅
                eventBus.reqTopPrc('setTrnsVal');
                this.sinTongSrch();
            } catch (error) {
                console.log("*************************************setTrnsVal 없다 잡아라****************************************************************");
            }

        }  else if(stp == "init") {
            this.initCustInfo();
            //this.$emit('initScr', "init");
        }
    },
    setVrfInfo(item){
        if(!this.mixin_isEmpty(item.COMP_TYPE)){
            let iType   = item.COMP_TYPE;
            let iAcntNo = item.SRCH_ACCOUNT;
            let iAcntPw = "";
            let iAcntC  = "";

            if(iType == "1") {
                iAcntPw = item.ACCT_PWD;
                iAcntC  = "Y";
            } else if(iType == "2") {
                iAcntPw = item.ACCT_PWD;
                iAcntC  = "Y";
            }
            
            let vrfInf = {
                type       : iType
                ,acntNo    : iAcntNo
                ,accountPw : iAcntPw
                ,acctConf  : iAcntC
                ,bankPw    : ''
                ,bankConf  : ''
            };

            this.custVrfInfo.push(vrfInf);
        }
    },
    
    async fnCnsltHistCheck(val) {
      let updateYN = false;

      let requestData1 = {
        headers: {
          SERVICE: 'phone.main.cnslt-enddttm',
          METHOD: 'inquire',
          TYPE: "BIZ_SERVICE",
        },
        sendData: {}
      };

      requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
      requestData1.headers["METHOD"] = "select";
      requestData1.headers["ASYNC"] = false;
      
      requestData1.sendData['CNSL_BEGIN_DATE'] = this.geCtiInfo.END_DT;
      requestData1.sendData['ASP_CUST_KEY'] = '001';
      requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
      requestData1.sendData['END_YN']  = val;
      requestData1.sendData['REG_ID']  = this.geUserRole.userId;

      const response1 = await this.common_postCall_noPB(requestData1);

      if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
        updateYN = true;
      }
      return updateYN;
    },    
    sendCustInfo() {
        let tmpCustInf = {};
        this.custList = [];
        try {
            if(!this.mixin_isEmpty(this.custInfo.CLNT_NO) && this.custInfo.SRCH_CL_CID != "9999999999999") {
                tmpCustInf.CL_CID      = this.mixin_juminNoFormat(this.custInfo.CL_CID);
                tmpCustInf.SRCH_CL_CID = this.custInfo.SRCH_CL_CID;
                tmpCustInf.CLNT_NO     = this.custInfo.CLNT_NO;
                tmpCustInf.ACCOUNT     = this.custInfo.SRCH_ACCOUNT;
                tmpCustInf.HirnkAcntNo = this.custInfo.HirnkAcntNo;
                this.custList.push(tmpCustInf);
                this.arrCusts = this.custInfo.CLNT_NO;
            }
        } catch(ex) {
            console.log("고객정보 처리 에러발생!! - "+ex);
        }
    },
    //고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
    async selectCustInfo(srchType) {
        if(this.geCallStt == "A_SETACW" && this.geCtiInfo.TRNS_GBN != "TT"){
          this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', true);
          this.geCtiInfo.IVR_IN_NO = "";
          this.geCtiInfo.IVR_IN_PATH = "";
          this.geCtiInfo.IVR_STD_TIME = "";
          this.geCtiInfo.ERR_MENT = "";               
        }


        // if(this.geCallStt == "A_SETACW"){
        //     this.geCtiInfo.IVR_IN_NO = "";
        //     this.geCtiInfo.IVR_IN_PATH = "";
        //     this.geCtiInfo.IVR_STD_TIME = "";
        //     this.geCtiInfo.ERR_MENT = "";                 
        // }        
    //   var cnsltHistCheck = await this.fnCnsltHistCheck("Y")

      //후처리중 상담저장이 안된경우
    //   if(this.geCallStt == "A_SETACW" && !this.mixin_isEmpty(this.geCtiInfo.CALL_ID) && this.geCnslSaveYn == "N") {
    //     if((this.geCustInfo.CLNT_NO != this.geCustInfoPrev && this.geCustInfoPrev != "")){
    //       this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //       return false;
    //     }else{
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //     }
    //   }
      //this.clearScr();
      this.listSort = "";
      this.dDESC = "";
      this.asort = "none";
      this.CNM = "";
      this.LST01=false;
      //this.$emit('initScr', "init");

      //예약정보
      if(!this.mixin_isEmpty(this.geCustInfo.SCDL_ID) || !this.mixin_isEmpty(this.SCDL_ID)) {
        this.custInfo.SCDL_ID = this.geCustInfo.SCDL_ID;
        this.SCDL_ID     = this.geCustInfo.SCDL_ID;
        this.IVR_IN_NO   = this.geCustInfo.IVR_IN_NO;
        this.IVR_IN_PATH = this.geCustInfo.IVR_IN_PATH;
        this.IVR_STD_TIME= this.geCustInfo.IVR_STD_TIME;
        this.IVR_MENT    = this.geCustInfo.IVR_MENT;
      }

      if(!this.mixin_isEmpty(this.geCustInfo.POP_YN)) {
        this.POP_YN = this.geCustInfo.POP_YN;
      }

      if(!this.mixin_isEmpty(this.geCustInfo.RSV_CALL_ID)) {
        this.RSV_CALL_ID = this.geCustInfo.RSV_CALL_ID;
      }

      //this.$emit('initScr', "SEL");

      //미인증고객처리
      if((srchType == "1" && this.ACCOUNT.length == 1)
      || (srchType == "2" && this.CL_CID.length == 1) 
      || (srchType == "2" && this.custInfo.SRCH_CL_CID == "9999999999999") 
      || (srchType == "3" && this.custInfo.CLNT_NO == "00000000") 
      || (srchType == "3" && this.custInfo.CLNT_NO.length == 1)){
        this.setNoCust();
        return;
      }

      //OB정보
      if(!this.mixin_isEmpty(this.geCustInfo.ID)) {
        this.ID = this.geCustInfo.ID;
        this.NTFCTN_1st = this.geCustInfo.NTFCTN_1st;
        this.NTFCTN_Nth = this.geCustInfo.NTFCTN_Nth;
      }

      if(!this.mixin_isEmpty(this.geCustInfo.MY_CALL_YN)) {
        this.MY_CALL_YN = this.geCustInfo.MY_CALL_YN;
      }

      //IVR인입시 처리
      if(!this.mixin_isEmpty(this.geCustInfo.COMP_TYPE)) {
        this.custInfo = this.geCustInfo;

        if(!this.mixin_isEmpty(this.custInfo.ACCT_PWD)) {
            this.ACCOUNT_PW = this.custInfo.ACCT_PWD;
            if(this.custInfo.COMP_RST == "Y") {
                this.VRF_ACCT_NO = this.custInfo.SRCH_ACCOUNT;
            }
        }
      }

      if(!this.mixin_isEmpty(this.geCustInfo.CNSL_HIST_NO)) {
        this.CNSL_HIST_NO = this.geCustInfo.CNSL_HIST_NO;
      }

      if(!this.mixin_isEmpty(this.custInfo.CNSL_HIST_NO)) {
        this.CNSL_HIST_NO = this.custInfo.CNSL_HIST_NO;
      }

      //계좌
      if(srchType == "1") {

        if(this.mixin_isEmpty(this.custInfo.SRCH_ACCOUNT) ) {
            this.common_alert(`계좌번호를 입력해주세요.`, 'error')
            return false
        }

        if(this.custInfo.SRCH_ACCOUNT.replaceAll("-","").length < 10) {
            this.setNoCust();
            return false
        }

        this.searchData['SRCH_TYPE'] = 'account';
        this.searchData['SRCH_ACCOUNT'] = this.custInfo.SRCH_ACCOUNT
      }

      //주민번호
      else if(srchType == "2") {
        //this.custInfo.SRCH_CL_CID = this.CL_CID;
        if(this.mixin_isEmpty(this.custInfo.SRCH_CL_CID) ) {
            this.common_alert(`주민번호를 입력해주세요.`, 'error');
            return false
        }

        if(this.custInfo.SRCH_CL_CID.replaceAll("-","").replaceAll("*","").length != 13
            && this.custInfo.SRCH_CL_CID.replaceAll("-","").replaceAll("*","").length != 10
        ) {
            this.common_alert(`주민번호 형식에 맞지 않습니다.`, 'error');
            return false
        }

        this.searchData['SRCH_TYPE'] = 'cl_cid';
        this.searchData['SRCH_CL_CID'] = this.custInfo.SRCH_CL_CID
      }

      //고객번호
      else if(srchType == "3") {
        if(this.mixin_isEmpty(this.custInfo.CLNT_NO) ) {
            this.common_alert(`고객번호를 입력해주세요.`, 'error');
            return false
        }

        if(this.custInfo.CLNT_NO.replaceAll("-","").length < 8) {
            this.setNoCust();
            return false
        }
        
        this.searchData['SRCH_TYPE'] = 'clnt_no';
        this.searchData['CLNT_NO'] = this.custInfo.CLNT_NO
        this.searchData['USER_ID'] = this.geUserRole.userId
        this.searchData['USER_DEPT_CD'] = this.geUserRole.USER_ATTR_A
      }

      let URLData = '/api/phone/main/selectCustInfo/inqire';
      let HEADER_SERVICE = 'phone.main.selectCustInfo';
      let HEADER_METHOD = 'inqire';
      let HEADER_TYPE = 'BIZ_SERVICE';

      // header 세팅
        let requestData = {
            headers: {
                ASYNC: true,
                METHOD: "inqire",
                SERVICE: "phone.main.selectCustInfo",
                TYPE: "BIZ_SERVICE",
                URL: "/api/phone/main/selectCustInfo/inqire"
            },
            sendData: this.searchData
        };

        if(this.geCallStt != "TALKING") { 
            this.custList    = []; // 고객리스트 초기화
            this.custVrfInfo = [];
        }

        this.common_postCall(requestData).then(response => {
            if(this.selectedRow.length != 0 ){
                this.activeRow(this.selectedRow, false);
            }

            this.$store.dispatch('callStore/AC_CUST_SRCH_MODE', "after");
            if(this.$refs.AcntStatNm_TXT) {
                this.$refs.AcntStatNm_TXT.innerText = "활동";
            }
            this.search = "활동";
            //tthis.arrCustshis.initCustInfo("Y");
            this.BANK_PW    = "";
            this.ACCOUNT_PW = "";
            this.bankClassNm = 'jh-form';
            this.acntClassNm = 'jh-form';

            this.custInfo = this.searchData;
            this.acntItems = [];
            
            if(this.mixin_isEmpty(response) ||  response.DATA[0].ERR_FLAG) {
                let errMsg = response.DATA[0].ERR_MSG;
                errMsg = errMsg.replaceAll("\\n", "\n");
                //this.common_alert(`조회된 고객정보가 없습니다.`, "error");
                this.CNM    = "미인증고객";
                this.CL_CID = "999999-9999999";
                this.custInfo.CNM          = "미인증고객"
                this.custInfo.SRCH_CL_CID  = "9999999999999"
                this.custInfo.NOTICE_CD    = "";
                this.ACCOUNT = "";

                this.custInfo.PWD_TYPE   = "";
                this.custInfo.ACCT_CONF  = "";
                this.custInfo.ACCOUNT_PW = "";
                this.custInfo.PRDT_DTL_NM = "";
                this.custInfo.CLNT_NO = "";
                this.custInfo.MOVE_TNO = "";

                this.custInfo.prbmCd1 = "white";
                this.custInfo.prbmCd2 = "white";
                this.custInfo.prbmCd3 = "white";
                this.$store.commit('callStore/MU_CUST_INFO', this.custInfo);

                this.custInfo.SRCH_ACCOUNT = ""; // 계좌번호   
                this.custInfo.HTS_USER_ID  = ""; // 고객HTSID
                this.custInfo.ACCOUNT_PW   = ""; // 계좌비번
                this.custInfo.BANK_PW      = ""; // 은행비번
                
                this.sinTongSrch();
                this.$emit('initScr', "initEtc");  // 정보초기화
                this.acntItems = []; //계좌정보리스트
                try {
                    if(this.geCallStt != "TALKING") {
                        this.geCtiInfo.IVR_IN_NO = "";
                        this.geCtiInfo.IVR_IN_PATH = "";
                        this.geCtiInfo.IVR_STD_TIME = "";
                        this.geCtiInfo.ERR_MENT = "";
                        this.$store.commit("callStore/MU_CTI_INFO", this.geCtiInfo);
                    }
                    //호전환값 셋팅
                    eventBus.reqTopPrc('setTrnsVal'); 
                } catch (error) {
                     console.log("*************************************setTrnsVal 없음3-33****************************************************************");
                }

                return true;
            }
            this.common_toast("조회완료 되었습니다.", "success");
            let resAccData = JSON.parse(JSON.stringify(response.DATA));
            let responseData = JSON.parse(JSON.stringify(response.custInfo[0]));

            //console.log("^^^^^" + JSON.stringify(responseData));

            this.custInfo = responseData;
            //OB정보 추가
            if(!this.mixin_isEmpty(this.ID)) {
                this.custInfo.ID = this.ID;
                this.custInfo.NTFCTN_1st = this.NTFCTN_1st;
                this.custInfo.NTFCTN_Nth = this.NTFCTN_Nth;
            }

            // ARS예약콜 정보 추가
            if(!this.mixin_isEmpty(this.SCDL_ID)) {
                this.custInfo.SCDL_ID      = this.SCDL_ID;
                this.custInfo.IVR_IN_NO    = this.IVR_IN_NO;
                this.custInfo.IVR_IN_PATH  = this.IVR_IN_PATH;
                this.custInfo.IVR_STD_TIME = this.IVR_STD_TIME;
                this.custInfo.IVR_MENT     = this.IVR_MENT;
            }

            // 전화예약콜 정보 추가
            if(!this.mixin_isEmpty(this.RSV_CALL_ID)) {
                this.custInfo.RSV_CALL_ID = this.RSV_CALL_ID;
            }

            // 마이콜 여부 추가
            if(!this.mixin_isEmpty(this.MY_CALL_YN)) {
                this.custInfo.MY_CALL_YN = this.MY_CALL_YN;
            }

            this.custInfo.ID = this.ID;
            this.custInfo.NTFCTN_1st = this.NTFCTN_1st;
            this.custInfo.NTFCTN_Nth = this.NTFCTN_Nth;

            this.custInfo.SCDL_ID = this.SCDL_ID;

            this.custInfo.RSV_CALL_ID = this.RSV_CALL_ID;
            this.custInfo.MY_CALL_YN = this.MY_CALL_YN;

            let tmpCustInf = {};
            let newYn = 'Y';

            tmpCustInf.CL_CID      = this.mixin_juminNoFormat(responseData.CL_CID);
            tmpCustInf.SRCH_CL_CID = responseData.SRCH_CL_CID;
            tmpCustInf.CLNT_NO     = responseData.CLNT_NO;
            tmpCustInf.ACCOUNT     = responseData.ACCOUNT;
            tmpCustInf.HirnkAcntNo = responseData.HirnkAcntNo;

            let currCustNo = this.custInfo.CLNT_NO.trim();
            if(this.arrCusts.indexOf(currCustNo) > -1) {
                this.arrCusts = this.arrCusts.replace(currCustNo+",", "").replace(",,", ",");                
                this.arrCusts = this.arrCusts.replace(currCustNo, "");
            }

            tmpCustInf.CNSL_CNTN   = this.arrCusts;

            if(this.mixin_isEmpty(tmpCustInf.ACCOUNT)) tmpCustInf.ACCOUNT = responseData.SRCH_ACCOUNT
            if(!this.mixin_isEmpty(this.CNSL_HIST_NO)) tmpCustInf.CNSL_HIST_NO = this.CNSL_HIST_NO;

            for(let item of this.custList) {
                if(item.SRCH_CL_CID == responseData.SRCH_CL_CID && responseData.SRCH_CL_CID != '9999999999999') {
                    newYn = 'N';
                    break;
                }
            }
            if(this.geCallStt == "TALKING") {
                if(newYn == "Y") {
                    this.custList.push(tmpCustInf);
                }

                if(this.mixin_isEmpty(this.arrCusts)) this.arrCusts = responseData.CLNT_NO;
                else this.arrCusts = this.arrCusts + ","+ responseData.CLNT_NO;
            } else {
                this.arrCusts = "";
            }

            this.CL_CID  = this.mixin_juminNoFormat(responseData.SRCH_CL_CID);
            this.ACCOUNT = this.mixin_accNoFormat(responseData.SRCH_ACCOUNT);
            this.CNM     = responseData.CNM;
            //this.acntItems = resAccData;

            // console.log("*acntItemSort>>>" + JSON.stringify(acntItemSort));
            if(!this.mixin_isEmpty(resAccData) && resAccData.length > 0 && !this.mixin_isEmpty(resAccData[0].AcntNo)) {
                // let acntItemSort = this.sortJSON( resAccData, "AcntOpnDt", "asc");
                let acntItemSort = resAccData;
                let srchAccRow = {};  //조회한 row
                let acntNo = "";
                let activeAcntNo = "";
                let activeAcntNm = "";

                this.acntClassNm = 'jh-form';
                this.pwdYn = false;

                for(let d of acntItemSort) { 
                    if(this.mixin_isEmpty(d['AcntNo'])) {
                        continue;
                    }

                    d['accountPw'] = "";
                    d['acctConf']  = "";
                    d['bankPw']    = "";
                    d['bankConf']  = "";

                    acntNo = d['AcntNo'].trim().replaceAll('','');
                    if(!this.mixin_isEmpty(this.VRF_ACCT_NO)) {
                        if(acntNo == this.VRF_ACCT_NO.trim()) {
                            console.log("인증처리!!!" + d['AcntNo']);
                            // console.log("인증처리!!!" + this.VRF_ACCT_PWD);
                            this.acntClassNm = 'jh-form is-bg-light-yellow';
                            this.pwdYn = true;
                            this.sinTongItem.ACCOUNT_PW = this.VRF_ACCT_PWD;
                            this.custInfo.ACCOUNT_PW    = this.VRF_ACCT_PWD;
                            this.ACCOUNT_PW             = this.VRF_ACCT_PWD;
                            this.custInfo.ACCT_CONF     = "Y";
                            this.custInfo.PWD_TYPE      = "1";
                            d['accountPw'] = this.VRF_ACCT_PWD;
                            d['acctConf']  = "Y";

                            tmpCustInf.acctConf = "Y";
                            tmpCustInf.ACCOUNT_PW = this.VRF_ACCT_PWD;
                        }
                    }
                    // 계좌인증정보 처리
                    for(let item of this.custVrfInfo) {
                        if(item.type == '1' && item.acntNo.trim() == d['AcntNo'].trim()) {
                            d['accountPw'] = item.accountPw;
                            d['acctConf']  = item.acctConf;
                        } else if(item.type == '2' && item.acntNo.trim() == d['AcntNo'].trim()) {
                            d['bankPw']    = item.bankPw;
                            d['bankConf']  = item.bankConf;
                        }
                    }

                    for(let k of Object.keys(d)) {
                        if(k === 'AcntNo') {
                            d[k] = this.mixin_accNoFormat(d[k]);
                        }
                        else if(k === 'AcntOpnDt') d[k] = this.mixin_dateFormat(d[k])
                        // else if(k === 'MgempNm' ) d['MgempNmPart'] =(d[k].trim().length > 8) ? d[k].trim().substr(0, 8)+'...' : (d['MgempNo'].trim().length<8) ? this.restoreXSS(d[k].trim()) + '('+ d['MgempNo'].trim()+ ')' : this.restoreXSS(d[k].trim())
                        else if(k === 'MgempNm' ) d['MgempNmPart'] = d[k].trim()
                        else if(k === 'AcntStatNm' ) d[k] = d[k].replace('(ON-LINE)', '');
                        else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                    }
                    d['index'] = this.acntItems.length + 1
                    d['selAccYn'] = "";

                    this.acntItems.push(d);

                    let tmpSrchAcnt = this.custInfo.SRCH_ACCOUNT;
                    if(!this.mixin_isEmpty(tmpSrchAcnt)) tmpSrchAcnt= tmpSrchAcnt.replaceAll('-','');
                    
                    if(acntNo == tmpSrchAcnt && srchType == "1") {
                        d['selAccYn'] = 'Y';
                        srchAccRow = d;
                    } else {
                        d['selAccYn'] = '';
                    }

                    if(srchType != "1" && activeAcntNo == "" && d['AcntStatNm'].trim() == "활동") {
                        tmpCustInf.ACNT_STT_CODE = d['AcntStatCode'].trim();
                        activeAcntNo = d['AcntNo'].trim().replaceAll('-','');
                        activeAcntNm = d['AcntPrdtDtlNm'].trim();
                        srchAccRow = d;
                    }
                } // end for
                try {
                    //console.log("^^acntNoa:" + JSON.stringify(srchAccRow));
                    if(srchType == "1" && !this.mixin_isEmpty(srchAccRow.AcntNo)) {
                        let cRow = 0;
                        cRow = this.findRow(this.acntItems, this.custInfo.SRCH_ACCOUNT);

                        let acntSttNm = "" 
                        if(!this.mixin_isEmpty(srchAccRow.AcntStatNm)) acntSttNm = srchAccRow.AcntStatNm.trim();
                        let acntSttTit = "활동";
                        if(acntSttNm != "활동") {
                            acntSttNm = "";
                            acntSttTit = "전체";
                        }

                        if(srchAccRow.acctConf == "Y") {
                            this.acntClassNm = 'jh-form is-bg-light-yellow';
                            this.pwdYn = true;
                            this.sinTongItem.ACCOUNT_PW = srchAccRow.accountPw;
                            this.custInfo.ACCOUNT_PW    = srchAccRow.accountPw;
                            this.ACCOUNT_PW             = srchAccRow.accountPw;
                        }

                        if(srchAccRow.bankConf == "Y") {
                            this.bankClassNm = 'jh-form is-bg-light-yellow';
                            this.sinTongItem.BANK_PW = srchAccRow.bankPw;
                            this.custInfo.BANK_PW    = srchAccRow.bankPw;
                            this.BANK_PW             = srchAccRow.bankPw;
                        }

                        if(this.$refs.AcntStatNm_TXT) {
                            this.$refs.AcntStatNm_TXT.innerText = acntSttTit;
                        }
                        this.search = acntSttNm;
                        if(!this.mixin_isEmpty(srchAccRow.AcntStatNm)) {
                            tmpCustInf.AcntStatCode = srchAccRow.AcntStatCode.trim();
                        }

                        if(cRow > -1) {
                            this.acntItems.splice(cRow, 1);
                            this.acntItems.unshift(srchAccRow);
                            //this.isActiveRow(srchAccRow);
                            //this.activeRow(0, true);
                        }

                        const table = this.$el.children[2].children[0];
                        table.scrollTop = 0;
                    }

                    console.log("====================================ACCOUNT====================================");
                    console.log("*activeAcntNo:" + activeAcntNo );
                    console.log("*SRCH_ACCOUNT:" + responseData.SRCH_ACCOUNT );
                    if(srchType != "1" && !this.mixin_isEmpty(activeAcntNo) && activeAcntNo != responseData.SRCH_ACCOUNT)  {
                        this.ACCOUNT = this.mixin_accNoFormat(activeAcntNo);
                        this.custInfo.PRDT_DTL_NM = activeAcntNm;
                        this.SRCH_ACCOUNT = activeAcntNo;
                        this.custInfo.SRCH_ACCOUNT = activeAcntNo;

                        let cRow = 0;
                        cRow = this.findRow(this.acntItems, activeAcntNo);
                        if(cRow > -1) {
                            this.acntItems.splice(cRow, 1);
                            this.acntItems.unshift(srchAccRow);
                            //this.isActiveRow(srchAccRow);
                            //this.activeRow(0, true);
                        }
                    }

                    let i = 0;
                    for(let d of this.acntItems) {
                        if(i==0) {
                            if(d['acctConf'] == "Y") {
                                this.acntClassNm = 'jh-form is-bg-light-yellow';
                                this.pwdYn = true;
                                this.sinTongItem.ACCOUNT_PW = d['accountPw'];
                                this.custInfo.ACCOUNT_PW    = d['accountPw'];
                                this.ACCOUNT_PW             = d['accountPw'];
                            } else {
                                this.acntClassNm = 'jh-form';
                                this.pwdYn = false;
                                this.sinTongItem.ACCOUNT_PW = "";
                                this.custInfo.ACCOUNT_PW    = "";
                                this.ACCOUNT_PW             = "";
                            }
                            if(d['bankConf'] == "Y") {
                                this.bankClassNm         = 'jh-form is-bg-light-yellow';
                                this.sinTongItem.BANK_PW = d['bankPw'];
                                this.custInfo.BANK_PW    = d['bankPw'];
                                this.BANK_PW             = d['bankPw'];
                            } else {
                                this.bankClassNm         = 'jh-form';
                                this.sinTongItem.BANK_PW = "";
                                this.custInfo.BANK_PW    = "";
                                this.BANK_PW             = "";
                            }
                            break;
                        }
                        i++;
                    }
                }catch(ex) {
                   console.log("################################################################고객계좌리스트인증처리오류!!!");
                   console.log("ACCINFO ERR===>" + ex);
                }finally {
                   console.log("#################################################################고객계좌인증처리완료!!!");
                }


                this.$store.commit('callStore/MU_CUST_INFO', this.custInfo);
                //console.log("$$$$$$sinTong SRCH:" + JSON.stringify(this.sinTongItem) + "/" + this.geSintongConn);

                try {
                    // if(this.geSintong80400) this.sintongConnect1(); //신통 80400 연결
                    // else this.sinTongSrch(); //신통연동처리
                    this.sinTongSrch();
                    eventBus.reqTopPrc('closeTrnsPop'); 
                    //호전환값 셋팅
                    eventBus.reqTopPrc('setTrnsVal'); 
                } catch (error) {
                     console.log("*************************************setTrnsVal 없음****************************************************************");
                }
               

            }else{

                console.log("*************************************custInfo 없음****************************************************************");
            }
        
            this.$store.commit('callStore/MU_CUST_INFO', this.custInfo); 
            this.$emit('selAddr'    , tmpCustInf);  //계좌정보상세조회
        })
        .catch((err) => {
            this.common_toast("고객종합정보 조회 중 오류가 발생하였습니다.\n잠시후에 다시 시도해주세요.", "error");
            console.log(err);
        });

         


    },
    sortJSON(data, key,  type) {
        if(type == undefined) type = "asc";

        return data.sort(function(a,b){
            let x = a[key];
            let y = b[key];
            if(type == "desc")  {
                return x > y ? -1 : x < y ? 1 : 0;
            } else if(type == "asc") {
                return x < y ? -1 : x > y ? 1 : 0;
            }
        });
    },
    // 신통 80400 연결
    sintongConnect1() {
        /* 신통연동처리  */
        if(!this.geSintongConn && this.geSintong80400) {
            console.log("===========>80400!!!");
            // header 세팅
            let requestData = {
            headers: {
                ASYNC  : false,
                METHOD : "sintongConnect",
                SERVICE: "api/sintong",
                TYPE   : "BIZ_SERVICE",
                URL    : "/api/sintong/sintongConnect"
            },
            sendData: {},
            };
            // sendData 세팅
            requestData.sendData.USER_ID  = this.user_id;   // 사번
            requestData.sendData.MdaTp    = "0";            // 매체구분(0:CTI(상담앱) 1:폰패드)
            requestData.sendData.TrxTp    = "3";            // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
            requestData.sendData.ScrNo    = "80400";        // 화면번호(CTI연동 화면오픈시 입력)
            requestData.sendData.ENC_TYPE = "CALL";
            requestData.sendData.AcntNo   = "";             // 계좌번호(CTI 계좌연동시 사용)
            requestData.sendData.Pwd      = "";             // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
            requestData.sendData.BnkPwd   = "";             // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
            requestData.sendData.ClntId   = "";             // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
            requestData.sendData.Rmnno    = "";             // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6) 
            
            this.common_postCall(requestData).then(response => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG || (response.DATA && response.DATA.length > 0 && response.DATA[0].TrxReslt != "0000")) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "신통오류발생."
                    //this.common_alert(msg, "error");
                    console.log(msg);
                    return false;
                }
                console.log("====sintong srch STT!!!");
                //this.sinTongSrch();
                console.log("====sintong srch END!!!");
            })
        }
    },


    saveCustTyp() {
        if(this.mixin_isEmpty(this.custInfo.CLNT_NO)) {
            this.common_alert(`고객번호가 없습니다.`, 'info');
            return false;
        }

        if(this.mixin_isEmpty(this.custInfo.NOTICE_CD)) {
            this.common_alert(`고객성향을 선택 해주세요.`, 'info');
            return false;
        }
        let noticeCd = this.custInfo.NOTICE_CD;
        let cmsg = "저장";

        if(noticeCd == "99") {
            noticeCd = "";
            cmsg = "삭제";
        }

        this.common_confirm("고객성향을 "+cmsg+" 하시겠습니까?", async ()=>{
            let URLData = '/api/phone/main/cstmr-info/insert';
            let HEADER_SERVICE = 'phone.main.cstmr-info';
            let HEADER_METHOD = 'regist';
            let HEADER_TYPE = 'BIZ_SERVICE';

            let userId = this.$store.getters["userStore/GE_USER_ID"];
            
            let params = {
                ASP_CUST_KEY: '001',
                CUST_NO: this.custInfo.CLNT_NO,
                ACCOUNT: this.ACCOUNT,
                NOTICE_CD: noticeCd,
                REGR_ID: userId,
                CHNG_ID: userId,
            };

            await api
            .post(URLData, params, {
            head: {
                SERVICE: HEADER_SERVICE,
                METHOD: HEADER_METHOD,
                TYPE: HEADER_TYPE,
                ASYNC: false,
            },
            })
            .then((response) => {
                this.common_alert("성공적으로 고객성향정보 "+cmsg+" 하였습니다.");
                this.custInfo.NOTICE_CD = noticeCd;
            })
            .catch((err) => {
                this.common_toast('고객성향정보 등록 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
                console.log(err);
            });
        })
    },
    getPinpad(iType) {
        //console.log("row:"+ JSON.stringify(this.selectedRow));

        console.log(">>>VMSPlayYn:["+ this.geVMSPlayYn + "]" + ", pinTypeNo:["+ this.gePinTypeNo + "]" + this.geCallStt );

        if(this.geCallStt == "TALKING" && (this.geVMSPlayYn == "Y" || this.gePinTypeNo == "1")) {
            this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요!!!`, 'info');
            return false;
        }
        
        if(iType == 3 && this.mixin_isEmpty(this.ACCOUNT)) {
            this.common_alert(`계좌번호를 입력 후 시도하세요!!!`, 'info');
            return false;
        }

        if(iType == 4 && this.mixin_isEmpty(this.ACCOUNT_PW)) {
            this.common_alert(`계좌비번을 입력 후 시도하세요!!!`, 'info');
            return false;
        }

        let altMsg = "폰패드";
        
        if(iType == 1){altMsg = "계좌번호"; this.CNS_NUM =  "65093"}
        if(iType == 2){altMsg = "주민번호"; this.CNS_NUM =  "65092"}
        if(iType == 3){altMsg = "계좌비밀번호"; this.CNS_NUM =  "65090"}
        if(iType == 4){altMsg = "은행비밀번호"; this.CNS_NUM =  "65091"}

        this.common_confirm(altMsg + "를 연결하시겠습니까?", async ()=>{           
            this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "1");
            this.saveRcvData(); 
            let params = {}
            if(iType == 1) {  // 계좌번호
                eventBus.ctiSendReq('consultCall', this.CNS_NUM);            
            } else if(iType == 2) {  // 주민번호
                eventBus.ctiSendReq('consultCall', this.CNS_NUM);
            } else if(iType == 3) {  // 계좌비번
                eventBus.ctiSendReq('consultCall', this.CNS_NUM);
            } else if(iType == 4) {  // 은행비번
                eventBus.ctiSendReq('consultCall', this.CNS_NUM);
            }
            this.PIN_TYPE_NUM = iType;
        })
    },
    /* IVR 폰패드 입력값 조회 */
    getIvrInptNum: async function() {        
        console.log("===============getIvrInptNum>>>>TYPE" + this.PIN_TYPE_NUM);
        this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
        api
            .post(
            "/api/phone/main/ivrRcv/inqire", //api url입력
            {
                INLNE_NO: this.geUserRole.IPCC_NO
                //,CALL_ID: this.geCtiInfo.CALL_ID
                //,CUST_NO: this.custInfo.CLNT_NO
                ,VAR1: "P"
            },
            {
                head: {
                SERVICE: "phone.main.ivrRcv",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
                },
            }
            )
            .then((response) => {
                if (this.mixin_isEmpty(response.data) || response.data.HEADER.COUNT == 0) {
                    let errMsg = response.data.HEADER.ERROR_MSG;
                    errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"인증 오류 발생!!";
                    this.common_toast(errMsg, "error");
                    return false;
                }

                let inptNum = response.data.DATA[0].INPT_NUM;
                let decNum = response.data.DATA[0].DEC_VAL;
                let var2 = response.data.DATA[0].VAR2;
                let errYn = false;

                //console.log("%%%%%%%%%%decNum::" + decNum);

                if(var2 == "E0") {
                    //this.common_toast("정상", "info");
                    console.log(">>>>>>>>>>>>>>>>>>>>폰패드정상조회");
                } else if(this.PIN_TYPE_NUM==4) {
                    console.log(">>>>>>>>>>>>>>>>>>>>폰패드은행비번조회");
                    if(this.mixin_isEmpty(decNum)) {
                        this.common_alert(`입력된 정보가 없습니다`, "noti");
                        return;
                    }
                } else if(var2 == "E1") {
                    console.log('고객 입력 없음 & 고객 끊음');
                    this.common_alert(`입력된 정보가 없습니다.`, "noti");
                    errYn = true;
                } else if(var2 == "E2") {
                    console.log('고객 지시대로 안누르고 잘못 누른경우');
                    let itype = "";
                    if(this.PIN_TYPE_NUM == 3) {
                        itype = "계좌비번";
                        this.common_alert(itype + `을 4자리 이상 입력해주세요.`, "error");
                    } else if(this.PIN_TYPE_NUM == 1 || this.PIN_TYPE_NUM == 2) {
                        this.common_alert(`조회된 정보가 없습니다.`, "noti");
                        this.setNoCust();
                    }
                    errYn = true;
                } else if(var2 == "E3") {
                    console.log('고객 입력값 가져오기 실패');
                    this.common_alert(`입력된 정보가 없습니다.`, "noti");
                    errYn = true;
                }

                if(this.PIN_TYPE_NUM==1) {
                    if(errYn) {
                        // this.ACCOUNT               = "";
                        // this.custInfo.SRCH_ACCOUNT = "";
                        // this.sinTongItem.ACCOUNT   = "";
                        // this.ACCOUNT      = this.mixin_accNoFormat(decNum);
                        // this.custInfo.SRCH_ACCOUNT = decNum;
                        //this.sinTongItem.ACCOUNT = decNum;
                        //this.PIN_TYPE_NUM =  0;
                        return false;
                    }
                    this.ACCOUNT      = this.mixin_accNoFormat(decNum);
                    this.custInfo.SRCH_ACCOUNT = decNum;
                    this.sinTongItem.ACCOUNT = decNum;
                    //고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
                    if(decNum.length == 11) this.selectCustInfo("1");
                } else if(this.PIN_TYPE_NUM==2) {
                    if(errYn) {
                        // this.CL_CID                = "";
                        // this.custInfo.SRCH_CL_CID  = "";
                        // this.sinTongItem.CL_CID    = "";
                       // this.common_alert(decNum, "error");
                        //this.setNoCust();
                        //this.$emit('initScr', "INFCLR");
                        // this.CL_CID      = "9999999999999";
                        // this.custInfo.SRCH_CL_CID = "999999-99999999";
                        //this.sinTongItem.CL_CID = decNum;
                        //this.PIN_TYPE_NUM =  0;
                        return false;
                    }
                    this.CL_CID      = this.mixin_juminNoFormat(decNum);
                    this.custInfo.SRCH_CL_CID = decNum;
                    this.sinTongItem.CL_CID = decNum;
                    //고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
                    if(decNum.length == 13 && decNum != "9999999999999") this.selectCustInfo("2");
                } else if(this.PIN_TYPE_NUM==3) {
                    if(errYn) {
                        this.ACCOUNT_PW            = "";
                        //this.PIN_TYPE_NUM =  0;
                        return false;
                    }
                    this.ACCOUNT_PW  = decNum;
                    this.verifyAccount(this.custInfo.SRCH_ACCOUNT, this.ACCOUNT_PW);
                } else if(this.PIN_TYPE_NUM==4) {
                    if(errYn) {
                        this.BANK_PW            = "";
                        //this.PIN_TYPE_NUM =  0;
                        return false;
                    }
                    this.BANK_PW     = decNum;
                    this.verifyBankPw(this.custInfo.SRCH_ACCOUNT, this.BANK_PW);
                }
            })
            .catch((err) => {
                this.common_toast(err);
            });
    },
    async saveRcvData() {

        const requestData  = {
            headers: {
                SERVICE: "phone.main.ivrInpt",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
                URL: "/api/phone/main/ivrInpt/save",
            },
            sendData:{}
        };

        // header 세팅
        requestData.sendData["INLNE_NO"] = this.geUserRole.IPCC_NO;
        requestData.sendData["INPT_NUM"] = null;
        requestData.sendData["AGR_YN"] = null;
        requestData.sendData["VAR1"] = "P";
        requestData.sendData["VAR2"] = "E3";
        requestData.sendData["VAR3"] = null;
        requestData.sendData["VAR4"] = null;
        requestData.sendData["VAR5"] = null;
        requestData.sendData["CUST_NO"] = this.custInfo.CLNT_NO;
        requestData.sendData["CALL_ID"] = this.geCtiInfo.CALL_ID;
        requestData.sendData["REG_ID"] = this.geUserRole.userId;
        requestData.sendData["UPD_ID"] = this.geUserRole.userId;

        const response = await this.common_postCall(requestData);

        console.log(response);

    },

    /* 계좌 비밀번호로 인증 */
    verifyAccount: async function(account, accountPw) {
        if(this.mixin_isEmpty(account)) {
            this.common_alert(`계좌번호를 입력해주세요.`, 'error');
            return;
        }

        if(this.mixin_isEmpty(accountPw)) {
            this.common_alert(`계좌비번을 입력해주세요.`, 'error');
            return;
        }

        if(accountPw.length < 4) {
            this.common_alert(`계좌비번을 4자리 이상 입력해주세요.`, 'error');
            return;
        }
        // header 세팅
        let requestData = {
          headers: {
            ASYNC: false,
            METHOD: "inqire",
            SERVICE: "phone.main.verfAcnt",
            TYPE: "BIZ_SERVICE",
            URL: "/api/phone/main/verf-acnt/inqire"
          },
          sendData: {
            ACCOUNT: account
            ,ACCOUNT_PW: accountPw
          }
        };
        this.common_postCall(requestData).then((response) => {
            if(this.mixin_isEmpty(response) ||  response.HEADER.ERR_FLAG) {
                let errMsg = response.DATA[0].ERR_MSG;

                if(this.mixin_isEmpty(errMsg)) { 
                    errMsg = response.HEADER.ERR_MSG;
                }
                errMsg = errMsg.replaceAll("\\n", "\n");
                this.common_alert(errMsg, "error");
                this.ACCOUNT_PW = "";
                return false;
            }

            //인증처리
            this.fnAccountConf(accountPw);    
        })
        .catch((err) => {
            this.common_toast(err);
        });
    },
    /* 은행 비밀번호로 인증 */
    verifyBankPw: async function(account, bankPw) {
        if(this.mixin_isEmpty(account)) {
            this.common_alert(`은행비밀번호를 입력해주세요.`, 'error');
            return;
        }

        if(this.mixin_isEmpty(bankPw)) {
            this.common_alert(`은행비번을 입력해주세요.`, 'error');
            return;
        }

        if(bankPw.length < 4) {
            // this.common_alert(`은행비번을 4자리 이상 입력해주세요.`, 'error');
            // return;
        }

        this.fnBankConf(bankPw);
    },
    activeRow(row, value) {
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    isActiveRow(item){
    //   return item.AcntNo == this.ACCOUNT? 'is-active':'';
        // BG 변경
        let rtnVal = '';

        if(item.acctConf == "Y") {
            rtnVal = 'is-bg-light-red is-bold';
        } else if(item.selAccYn == "Y") {
            rtnVal = 'is-bg-light-yellow is-bold';
        } else {
            rtnVal = '';
        }

        return rtnVal;
    },
    isActiveRow2(item){
    //   return item.AcntNo == this.ACCOUNT? 'is-active':'';
        // BG 변경
        return item.selAccYn == "Y"? 'is-bg-light-yellow':'';
    },
    resetCustInfo() {

    },
    changeInp(type, val) {
        if(!this.mixin_isEmpty(val)) val = val.replaceAll("-","");
        else val = "";

        if(type == 'accno' && val.length == 11) {
            this.ACCOUNT = this.mixin_accNoFormat(val);
        } else if (type == 'accno') {
            this.custInfo.PRDT_DTL_NM = "";
        }
        
        if(type == 'cid') {
            // this.custInfo.SRCH_CL_CID = this.CL_CID;
            // this.CL_CID = this.mixin_juminNoFormat(val);
        } else if(type == 'clntno') {
            // this.custInfo.CLNT_NO = val;
        }
    },
    keyUpInp(type, val) {
        if(type == 'cid' && val.replaceAll("-","").length > 13) {
            this.common_alert(`주민번호 형식에 맞지 않습니다.`, 'error');
            this.custInfo.SRCH_CL_CID = "";
            this.sinTongItem.SRCH_CL_CID = "";
            this.CL_CID = "";
            return;
        }

        if(type == 'accno' && val.replaceAll("-","").length > 11) {
            this.common_toast(`계좌번호 형식에 맞지 않습니다.`, 'info');
            let sacno = this.mixin_accNoFormat(val.replaceAll("-","").substr(0, 11));
            this.custInfo.SRCH_ACCOUNT = sacno;
            this.sinTongItem.SRCH_ACCOUNT = sacno;
            this.ACCOUNT = sacno;
            return;
        }

        if(!this.mixin_isEmpty(val)) {
            //console.log("@@aa"+ val);
            let numPttn = /[^0-9*]/g;
            val =  val.replace(numPttn, "");
            //console.log("@@bb"+ val + "/" + val.length);
            if(type == 'accno'){
                let sNo = val;
                if(sNo.length == 11) {
                    val = this.mixin_accNoFormat(val);
                    this.custInfo.SRCH_ACCOUNT = sNo;
                    this.sinTongItem.SRCH_ACCOUNT = sNo;
                    this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);
                } else {
                    this.custInfo.SRCH_ACCOUNT = sNo;
                    this.sinTongItem.SRCH_ACCOUNT = sNo;
                }
                this.ACCOUNT = val;
            } else if(type == 'clntno') {
                this.custInfo.CLNT_NO = val;
                this.sinTongItem.CLNT_NO = val;
            } else if(type == 'cid' && !val.includes("*")) {
                let scid = val;
                this.custInfo.SRCH_CL_CID = scid
                this.sinTongItem.SRCH_CL_CID = scid;
                if(scid.replaceAll("*","").length == 13) { 
                    val = this.mixin_juminNoFormat(val);
                    this.custInfo.SRCH_CL_CID = scid
                    this.sinTongItem.SRCH_CL_CID = scid;
                }
                else if(scid.length > 13) {
                    this.common_alert(`주민번호 형식에 맞지 않습니다.`, 'error');
                    this.custInfo.SRCH_CL_CID = "";
                    this.sinTongItem.SRCH_CL_CID = "";
                    this.CL_CID = "";
                    return false;
                }
                this.CL_CID = val
                this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);
            }else{
                let scid = val;
                if(scid.replaceAll("*","").length == 10) { 
                    this.CL_CID = this.mixin_juminNoFormat(val);

                }else{
                    return
                }                
                
                this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);                
            }
        }
    },
    filterAction(item){
       if(item.ITEM_NM === "전체") {
           this.search = "";
       } else {
           this.search = item.ITEM_NM;
       }
      
      if(this.$refs.AcntStatNm_TXT) {
          this.$refs.AcntStatNm_TXT.innerText = item.ITEM_NM;
      }
    },
    //계좌비번 인증처리
    fnAccountConf(accountPw){
        if(this.mixin_isEmpty(this.ACCOUNT)) {
            this.common_alert(`계좌번호를 입력해주세요.`, 'error');
            return;
        }

        for(let d of this.acntItems) {
		    d['selAccYn'] = '';
    	}

        this.pwdYn = true;
        let cRow = 0;
        // if (this.selectedRow != "") {
            //console.log(JSON.stringify(this.selectedRow));
            // cRow = this.selectedRow.index;
        // } else {
            //console.log("====================NON Selected");
        cRow = this.findRow( this.acntItems, this.custInfo.SRCH_ACCOUNT);
        // }

        //cRow = this.acntItems.indexOf(this.selectedRow);
 
        this.acntClassNm = 'jh-form is-bg-light-yellow';
        this.acntItems[cRow].accountPw = accountPw;
        this.acntItems[cRow].acctConf = "Y";

        let currRow = this.acntItems[cRow];

        this.sinTongItem.ACCOUNT_PW = accountPw;
        this.custInfo.ACCOUNT_PW = accountPw;
        this.custInfo.PWD_TYPE   = "1";
        this.custInfo.ACCT_CONF  = "Y";
        this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);

        let addrInf = {
            type      : '1'
           ,acntNo    : this.acntItems[cRow].AcntNo.replaceAll('-', '')
           ,HirnkAcntNo  : this.acntItems[cRow].HirnkAcntNo.replaceAll('-', '')
           ,AcntStatCode : this.acntItems[cRow].AcntStatCode
           ,accountPw : this.acntItems[cRow].accountPw
           ,acctConf  : 'Y'
           ,bankPw    : ''
           ,bankConf  : ''
        };
        this.acntItems.splice(cRow, 1);
        let acntItemSort = this.sortJSON( this.acntItems, "index", "asc"); 

        //let sRow = this.findRow(acntItemSort, this.custInfo.SRCH_ACCOUNT);
        this.acntItems = acntItemSort;
        //this.acntItems.splice(cRow, 1);
        this.acntItems.unshift(currRow);
        this.custVrfInfo.push(addrInf);

        console.log("VVVVVVVcustVrfCount-------->" + this.custVrfInfo.length);
        //console.log("##" + JSON.stringify(this.custVrfInfo));

        try {
            //호전환값 셋팅
            eventBus.reqTopPrc('setTrnsVal'); 
        } catch(ex) {
            console.log(">>>>계좌인증 중 호전환 데이타 처리 오류!!-->" + ex);
            return false;
        }
      
        this.$emit('selAddrDtl', addrInf);

        const table = this.$el.children[2].children[0];
        table.scrollTop = 0;

        //신통 조회
        this.sinTongSrch();
        //eventBus.reqTopPrc('closeTrnsPop'); 
        //this.sintongConnect1();
    },
    //로우 index
    findRow(items, account){
        let sRow = 0;

        for(let item of items) {
            sRow++;
            if(account.replaceAll('-', '') == item.AcntNo.replaceAll('-', ''))  {
                if(sRow>0) sRow--;
                break;
            }
        }
        return sRow;     
    },
    //은행비번 인증시
    fnBankConf(bankPw){

        for(let d of this.acntItems) {
		    d['selAccYn'] = '';
    	}

        let cRow = 0;

        this.findRow(this.acntItems, this.custInfo.SRCH_ACCOUNT);
        this.bankClassNm = 'jh-form is-bg-light-yellow';

        this.acntItems[cRow].bankPw   = bankPw;
        this.acntItems[cRow].bankConf = "Y";

        this.sinTongItem.BANK_PW = bankPw;
        this.custInfo.BANK_PW = bankPw;
        this.custInfo.BANK_CONF  = "Y";
        this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);

        let currRow = this.acntItems[cRow];

        let bankInf = {
            type      : '2'
           ,acntNo    : this.acntItems[cRow].AcntNo.replaceAll('-', '')
           ,accountPw : this.acntItems[cRow].accountPw
           ,acctConf  : ''
           ,bankPw    : bankPw
           ,bankConf  : 'Y'
        };

        //this.acntItems.splice(cRow, 1);
        //this.acntItems.unshift(currRow);

        this.acntItems.splice(cRow, 1);
        let acntItemSort = this.sortJSON( this.acntItems, "index", "asc"); 
        this.acntItems = acntItemSort;
        this.acntItems.unshift(currRow);
        this.custVrfInfo.push(bankInf);

        const table = this.$el.children[2].children[0];
        table.scrollTop = 0;

        //신통 조회
        this.sinTongSrch();
        //eventBus.reqTopPrc('closeTrnsPop'); 
        //this.sintongConnect1();

        //console.log("====================Vrf>>" + JSON.stringify(this.custVrfInfo));
    },
    //계좌목록 그리드 클릭시, 상담내용, 답변내용 세팅
    setCnslDetail(value, row) {
      if(this.geCallStt == "TALKING" && (this.geVMSPlayYn == "Y" || this.gePinTypeNo == "1")) {
        this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요!!!`, 'info');
        return false;
      }
      if(value.acctConf == "Y") {
          this.custInfo.PWD_TYPE     = "1";
          this.custInfo.ACCT_CONF    = "Y";
          this.acntClassNm = 'jh-form is-bg-light-yellow';
          this.pwdYn = true;
      } else {
        this.custInfo.PWD_TYPE     = "";
        this.custInfo.ACCT_CONF    = "";
        this.acntClassNm = 'jh-form';
        this.pwdYn = false;
      }

      for(let d of this.acntItems) {
		d['selAccYn'] = '';
	  }

      if(value.bankConf == "Y") this.bankClassNm = 'jh-form is-bg-light-yellow';
      else this.bankClassNm = 'jh-form';

      //console.log("&&&&DTL" + JSON.stringify(value));
      
      this.ACCOUNT      = value.AcntNo;
      this.custInfo.SRCH_ACCOUNT = value.AcntNo.replaceAll("-","");
      this.custInfo.PRDT_DTL_NM  = value.PrdtDtlNm;
      this.ACCOUNT_PW            = value.accountPw;
      this.BANK_PW               = value.bankPw;
      this.custInfo.acctConf     = value.bankConf;

      this.custInfo.ACCOUNT_PW = value.accountPw;
      this.custInfo.BANK_PW    = value.bankPw;
      this.$store.dispatch("callStore/AC_CUST_INFO", this.custInfo);
      this.sinTongItem = {};   //신통연동초기화
      this.sinTongSrch();      //신통연동처리

      let addrInf = {
         type      : '1'
        ,acntNo    : value.AcntNo.replaceAll('-', '')
        ,accountPw : value.accountPw
        ,acctConf  : value.acctConf
        ,HirnkAcntNo  : value.HirnkAcntNo
        ,AcntStatCode : value.AcntStatCode
      };
      
      this.$emit('selAddrDtl', addrInf);
      if (this.selectedRow != "") {
          this.activeRow(this.selectedRow, false);
          this.selectedRow = [];
      }
      this.activeRow(row, true);
      eventBus.reqTopPrc('closeTrnsPop'); 
    },
    setCid(cid) {
        this.custInfo  = {};
        this.acntItems = [];
        this.CL_CID = this.mixin_juminNoFormat(cid);
        this.custInfo.SRCH_CL_CID = cid;
        //고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
        this.selectCustInfo("2");
        this.LST01 = false;
    },
    onDblClickRow() {
      if(this.geVMSPlayYn == "Y" || this.gePinTypeNo == "1") {
        this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요!!!`, 'info');
        return false;
      }
      //console.log("############");
      //신통연동 조회
      this.sinTongSrch();
    },
    //신통연동 조회
    sinTongSrch() { 
        /* 신통연동처리 */
        if(!this.geSintongConn) {
            this.sinTongItem.ENC_TYPE   = "CALL";
            this.sinTongItem.ACCOUNT    = this.custInfo.SRCH_ACCOUNT // 계좌번호   
		    this.sinTongItem.CLNT_NO    = this.custInfo.HTS_USER_ID  // 고객HTSID
		    this.sinTongItem.CL_CID     = this.custInfo.SRCH_CL_CID  // 실명확인번호
            this.sinTongItem.ACCOUNT_PW = this.custInfo.ACCOUNT_PW   // 계좌비번
            this.sinTongItem.BANK_PW    = this.custInfo.BANK_PW      // 은행비번

            //onsole.log("------------>sintong::" + JSON.stringify(this.sinTongItem));
            this.mixin_sintongSearch(this.sinTongItem);  //신통연동조회
        }
    },
    handleScroll (e) {
      this.scrollTop = e.target.scrollTop;
      if(this.scrollTop > 50){
        // this.$refs.dropReady.isActive = false;
        // this.$refs.dropCounsel.isActive = false;
        // this.$refs.dropComplete.isActive = false;
        // this.$refs.dropGiveup.isActive = false;
        // this.$refs.dropBusy.isActive = false;
      }
    },
    onScroll (e) {
        console.log("^^^^^^^^scro^^^^^^^^1111");
        this.scroll = e.target.scrollTop;
        console.log("^^^^^^^^scro^^^^^^^^"+this.scrol);
        e.target.scrollTop = 0;
        // var scrollTop = e.target.scrollTop;
        // var innerHeight = e.target.clientHeight;
        // var scrollHeight = e.target.scrollHeight;
        // if(scrollTop + innerHeight >= scrollHeight -70){
        //     this.ToBottomButton = false;
        // }else{
        //     this.ToBottomButton = true;
        // }
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    addDialog(type) {
      this[`dialog${type}`] = true
    },
  },
  computed: {
    ...mapGetters({
        geCustInfo: 'callStore/GE_CUST_INFO'
       ,geUserRole: 'userStore/GE_USER_ROLE'
       ,geCustMode: 'callStore/GE_CUST_SRCH_MODE'
       ,gePinTypeNo: 'callStore/GE_PIN_TYPE_NO'
       ,geCtiInfo: 'callStore/GE_CTI_INFO'
       ,geCallStt: 'callStore/GE_CALL_STATE'
       ,geVMSPlayYn: 'callStore/GE_PLAY_YN'
       ,geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN'
       ,geSintongConn: 'callStore/GE_SINTONG_CLEAR'
       ,geSintong80400: 'callStore/GE_SINTONG_80400'
       ,geCustInfoPrev: 'callStore/GE_CNSL_CUST_PREV'      

    }),
    rowNum() {
      return this.acntItems.map(
        (acntItems, index) => ({
        ...acntItems,
        index: index + 1
      }))
    },
    autoBeginDateFormat(){
      let camBeginDate = this.mixin_isEmpty(this.custInfo.TR_LAST_CHNG_DT) ? '' : this.mixin_dateFormat(this.custInfo.TR_LAST_CHNG_DT)
      return camBeginDate
    },
  },
  watch: {
    "custInfo.prbmCd1": function(nCol) {
        if(!this.mixin_isEmpty(nCol)) {
            this.$data.prbmStyle1.backgroundColor=nCol;
            if(nCol === 'red' || nCol === 'RED') {
                this.$data.prbmStyle1.color= "#fff"
            } else if(nCol === 'black' || nCol === 'BLACK') {
                this.$data.prbmStyle1.color= "#fff"
            } else if(nCol === 'yellow' ||nCol === 'YELLOW') {
                this.$data.prbmStyle1.backgroundColor= "#fae719"
                this.$data.prbmStyle1.color= "#000"
            } else {
                this.$data.prbmStyle1.color= "#fff"
            }
        }
      },
    "custInfo.prbmCd2": function(nCol) {
        if(!this.mixin_isEmpty(nCol)) {
            this.$data.prbmStyle2.backgroundColor=nCol;
            if(nCol === 'red' || nCol === 'RED') {
                this.$data.prbmStyle2.color= "#fff"
            } else if(nCol === 'black' || nCol === 'BLACK') {
                this.$data.prbmStyle2.color= "#fff"
            } else if(nCol === 'yellow' ||nCol === 'YELLOW') {
                this.$data.prbmStyle2.backgroundColor= "#fae719"
                this.$data.prbmStyle2.color= "#000"
            } else {
                this.$data.prbmStyle2.color= "#fff"
            }
        }
      },
    "custInfo.prbmCd3": function(nCol) {
        if(!this.mixin_isEmpty(nCol)) {
            this.$data.prbmStyle3.backgroundColor=nCol;
            if(nCol === 'red' || nCol === 'RED') {
                this.$data.prbmStyle3.color= "#fff"
            } else if(nCol === 'black' || nCol === 'BLACK') {
                this.$data.prbmStyle3.color= "#fff"
            } else if(nCol === 'yellow' ||nCol === 'YELLOW') {
                this.$data.prbmStyle3.backgroundColor= "#fae719"
                this.$data.prbmStyle3.color= "#000"
            } else {
                this.$data.prbmStyle3.color= "#fff"
            }
        }
      },
      message: function (nMsg) {
          this.reverseMessage = nMsg.split('').reverse().join('')
      },
      geCustMode: function (nMsg) {
        console.log("geCustMode>>>>>>"+nMsg);

        if(nMsg == "init") {
            //this.initCustInfo();
            //this.$emit('initScr', "init");
        } else if(nMsg == "search") {
            this.geCtiInfo.IVR_IN_NO = "";
            this.geCtiInfo.IVR_IN_PATH = "";
            this.geCtiInfo.IVR_STD_TIME = "";
            this.geCtiInfo.ERR_MENT = "";            
            this.clearScr();
            this.custInfo = this.geCustInfo;
            //고객정보조회(1:계좌, 2:실명번호, 3:고객번호)
            this.selectCustInfo("1");
            if(!this.mixin_isEmpty(this.custInfo.CNSL_HIST_NO)) this.$emit('selSang', this.custInfo.CNSL_HIST_NO);
        }  else if(nMsg == "search2") {
            this.geCtiInfo.IVR_IN_NO = "";
            this.geCtiInfo.IVR_IN_PATH = "";
            this.geCtiInfo.IVR_STD_TIME = "";
            this.geCtiInfo.ERR_MENT = "";                 
            this.clearScr();
            this.custInfo = this.geCustInfo;
            this.selectCustInfo("2");
            if(!this.mixin_isEmpty(this.custInfo.CNSL_HIST_NO)) this.$emit('selSang', this.custInfo.CNSL_HIST_NO);
        } else if(nMsg == "search3") {
            this.geCtiInfo.IVR_IN_NO = "";
            this.geCtiInfo.IVR_IN_PATH = "";
            this.geCtiInfo.IVR_STD_TIME = "";
            this.geCtiInfo.ERR_MENT = "";                 
            this.clearScr();
            this.custInfo = this.geCustInfo;
            this.selectCustInfo("3");
            if(!this.mixin_isEmpty(this.custInfo.CNSL_HIST_NO)) this.$emit('selSang', this.custInfo.CNSL_HIST_NO);
        } else if(nMsg == "setCustInf") {
            this.geCtiInfo.IVR_IN_NO = "";
            this.geCtiInfo.IVR_IN_PATH = "";
            this.geCtiInfo.IVR_STD_TIME = "";
            this.geCtiInfo.ERR_MENT = "";                 
            this.clearScr();
            //this.$emit('initScr', "initEtc");
            this.custInfo = this.geCustInfo;
            this.CNM = this.custInfo.CNM;
            this.CL_CID = this.custInfo.CL_CID;
            //console.log(">>>>setCustInf&&&&" + JSON.stringify(this.custInfo));
        }
      },
    //   gePinTypeNo: function (nVal) {
    //     console.log("PIN_TYPE====>"+nVal);
    //     //if(nVal == "9") this.getIvrInptNum();
    //   }
  }
};
</script>

<style></style>