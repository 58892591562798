<template>
    <div class="jh-card is-mt-10">
        <!--계좌 탭-->
        <div class="jh-card-header has-tab">
            <v-tabs v-model="tabAccount" slider-size="4">
                <v-tab :key=i v-for="(item,i) in tabs" @click="tabClick(item.id)">{{item.name}}</v-tab>
            </v-tabs>
            <p class="jh-tab-button is-right">
                <v-btn class="jh-btn is-search" @click="srchAddrInf">조회</v-btn>
            </p>
        </div>
        <div class="jh-card-body">
            <v-tabs-items v-model="tabAccount">
                <!--개별계좌-->
                <v-tab-item>
                    <div class="tab-contnets">
                        <table class="jh-tbl-detail">
                            <colgroup>
                                <col width="75px">
                                <col width="130px">
                                <col width="65px">
                                <col>
                                <col width="75px">
                                <col width="200px">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><label>원장통보지</label></th>
                                    <td colspan="5"> 
                                        <div class="jh-selection-row">
                                            <v-checkbox class="jh-check" v-model="accInfo.RECV_TYPE_01" label="자택" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="accInfo.RECV_TYPE_02" label="직장" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="accInfo.RECV_TYPE_03" label="이메일" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="accInfo.RECV_TYPE_04" label="기타" disabled></v-checkbox>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th><label>휴대전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(accInfo.MOB_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(1)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>자택전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(accInfo.HOME_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(2)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>FAX</label></th>
                                    <td> 
                                        <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(accInfo.FAX_NO)" disabled>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="jh-tbl-detail is-mt-5">
                            <colgroup>
                                <col width="75px">
                                <col width="130px">
                                <col width="65px">
                                <col>
                                <col width="75px">
                                <col width="200px">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><label>직장전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(accInfo.OFFC_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(3)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>이메일</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" v-model="accInfo.EMAIL_ADDR" disabled>
                                            <v-btn class="jh-btn has-icon-only" :disabled="!mixin_isEmpty(emailWinPopup[1])" @click="sendEmail(1)"><i class="jh-icon-envelope"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><input type="checkbox" class="jh-form" v-model="accInfo.PHN_NUM_YN"  id="chk2"><label for="chk2">폰넘버</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <v-select class="jh-form is-col-fix" 
                                                v-model="accInfo.CALL_TYPE"
                                                :items="mixin_common_code_get(this.common_code, 'CRS031', '선택')"
                                                no-data-text="조회된 값이 없습니다"
                                                @change="changTelType(accInfo.CALL_TYPE)"
                                                style="width: 70px;"
                                            ></v-select>
                                            <input type="text" class="jh-form" maxlength="14" id="inpPhnNo" :value="mixin_newPhoneFormat(accInfo.PHONE_NO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" :loading="isLoading" @click="phoneNumSave('1')"><i class="jh-icon-disket"></i></v-btn>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th><label>자택주소</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <v-select class="jh-form" v-model="accInfo.HOME_ADDR_TYPE"
                                            :items="mixin_common_code_get(this.common_code, 'AW03')"  @change="changeHomeAdrType('1', accInfo.HOME_ADDR_TYPE, accInfo.HOME_ADDR)"></v-select>
                                            <input type="text" class="jh-form is-col-fix" v-model="accInfo.HOME_POST_NO" style="width: 50px;" disabled>
                                        </div>
                                    </td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="accInfo.HOME_ADDR" disabled></td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="accInfo.HOME_ADDR_DTL" disabled></td>
                                </tr>
                                <tr>
                                    <th><label>직장주소</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <v-select class="jh-form" v-model="accInfo.OFFC_ADDR_TYPE"
                                            :items="mixin_common_code_get(this.common_code, 'AW03')" @change="changeHomeAdrType('2', accInfo.OFFC_ADDR_TYPE, accInfo.OFFC_ADDR)"></v-select>
                                            <input type="text" class="jh-form is-col-fix" v-model="accInfo.OFFC_POST_NO" style="width: 50px;" disabled>
                                        </div>
                                    </td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="accInfo.OFFC_ADDR" disabled></td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="accInfo.OFFC_ADDR_DTL" disabled></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-tab-item>
                <!--//개별계좌-->

                <!--대표계좌-->
                <v-tab-item>
                    <div class="tab-contnets">
                        <table class="jh-tbl-detail">
                            <colgroup>
                                <col width="80px">
                                <col width="140px">
                                <col width="70px">
                                <col>
                                <col width="80px">
                                <col width="160px">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><label>원장통보지</label></th>
                                    <td colspan="5"> 
                                        <div class="jh-selection-row">
                                            <v-checkbox class="jh-check" v-model="repAccInfo.RECV_TYPE_01" label="자택" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="repAccInfo.RECV_TYPE_02" label="직장" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="repAccInfo.RECV_TYPE_03" label="이메일" disabled></v-checkbox>
                                            <v-checkbox class="jh-check" v-model="repAccInfo.RECV_TYPE_04" label="기타" disabled></v-checkbox>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th><label>휴대전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(repAccInfo.MOB_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(4)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>자택전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(repAccInfo.HOME_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(5)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>FAX</label></th>
                                    <td> 
                                        <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(repAccInfo.FAX_NO)" disabled>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="jh-tbl-detail is-mt-5">
                            <colgroup>
                                <col width="80px">
                                <col width="140px">
                                <col width="70px">
                                <col>
                                <col width="80px">
                                <col width="160px">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><label>직장전화</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(repAccInfo.OFFC_TNO)" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="callPop(6)"><i class="jh-icon-call"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>이메일</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" v-model="repAccInfo.EMAIL_ADDR" disabled>
                                            <v-btn class="jh-btn has-icon-only" @click="sendEmail(2)"><i class="jh-icon-envelope"></i></v-btn>
                                        </div>
                                    </td>
                                    <th><label>폰넘버</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <input type="text" class="jh-form is-trns" :value="mixin_newPhoneFormat(repAccInfo.PHONE_NO)" disabled>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th><label>자택주소</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <v-select class="jh-form" v-model="repAccInfo.HOME_ADDR_TYPE"
                                            :items="mixin_common_code_get(this.common_code, 'AW03')" @change="changeHomeAdrType('3', repAccInfo.HOME_ADDR_TYPE, repAccInfo.HOME_ADDR)"></v-select>
                                            <input type="text" class="jh-form is-col-fix" v-model="repAccInfo.HOME_POST_NO" style="width: 60px;" disabled>
                                        </div>
                                    </td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="repAccInfo.HOME_ADDR" disabled></td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="repAccInfo.HOME_ADDR_DTL" disabled></td>
                                </tr>
                                <tr>
                                    <th><label>직장주소</label></th>
                                    <td> 
                                        <div class="jh-cols">
                                            <v-select class="jh-form" v-model="repAccInfo.OFFC_ADDR_TYPE"
                                            :items="mixin_common_code_get(this.common_code, 'AW03')" @change="changeHomeAdrType('4', repAccInfo.OFFC_ADDR_TYPE, repAccInfo.OFFC_ADDR)"></v-select>
                                            <input type="text" class="jh-form is-col-fix" v-model="repAccInfo.OFFC_POST_NO" style="width: 60px;" disabled>
                                        </div>
                                    </td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="repAccInfo.OFFC_ADDR" disabled></td>
                                    <td colspan="2"><input type="text" class="jh-form" v-model="repAccInfo.OFFC_ADDR_DTL" disabled></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-tab-item>
                <!--//개별계좌-->
            </v-tabs-items>          
        </div>
        <!-- 전화걸기 -->
        <!--v-dialog
            max-width="400"
            persistent
            v-model="dialogM020102P01"
            content-class="square-modal phone-call-modal"
        >
            <dialog-M020102P01
            headerTitle="전화걸기"
            @hide="hideDialog('M020102P01')"
            @submit="submitDialog('M020102P01')"
            :makeCall="makeCall"
            @input="makeCall = $event"
            ></dialog-M020102P01>
        </v-dialog-->
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import { eventBus } from "@/store/eventBus.js";
import { mapGetters } from "vuex"

export default {
  name: "Account",
  mixins: [mixin],
  props: {
    param:{
      type: Object,
      default: {}
    },
    accInfo:{
      type: Object,
      default: {}
    },
    repAccInfo:{
      type: Object,
      default: {}
    },
  },
  components: {
  },
  data() {
      return {
        ASP_CUST_KEY: '001',
        tabAccount: 'tabAccount_1',
        isLoading: false, //directCall btn
        CALL_TYPE: '',
        makeCall: {},

        tabs: [
            { id: 'tabAccount_1', name: '개별계좌' },
            { id: 'tabAccount_2', name: '대표계좌' },
        ],
        common_code: [], //공통코드

        emailWinPopup: {
            1: null,
            2: null
        },
        isInitEmailWinPopup: {
            1: true,
            2: true
        },

        emailAddress: null
      }
  },
  mounted() {
    this.initAcntInfo("A");
  },
  methods: {
    async initAcntInfo(type) {
        let codeName = ['AW03', 'CRS031'];
        this.common_code = await this.mixin_common_code_get_all(codeName);
    },
    initData() {
        this.CALL_TYPE = "";
        this.tabClick("tabAccount_1");

        this.accInfo.CALL_TYPE = "";
        this.accInfo.HOME_ADDR_TYPE = "Y";
	    this.accInfo.OFFC_ADDR_TYPE = "Y";
        this.repAccInfo.HOME_ADDR_TYPE = "Y";
	    this.repAccInfo.OFFC_ADDR_TYPE = "Y";
    },
    tabClick(id) {
        this.tabAccount = id;
    },
    changTelType(cd) {
        //console.log(">>>>>>>>>" + JSON.stringify(this.accInfo));

        this.accInfo.PHN_NUM_YN = true;
        if(cd == "01")  this.accInfo.PHONE_NO = this.mixin_newPhoneFormat(this.repAccInfo.MOB_TNO);
        else if(cd == "02") this.accInfo.PHONE_NO = this.mixin_newPhoneFormat(this.repAccInfo.HOME_TNO);
        else if(cd == "03") this.accInfo.PHONE_NO = this.mixin_newPhoneFormat(this.repAccInfo.OFFC_TNO);
        else {
            this.accInfo.PHONE_NO = "";
            this.accInfo.PHN_NUM_YN = false;
        }
    },
    /* 이메일팝업 */
    sendEmail(type){
        if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO)) {
            this.common_alert(`고객정보를 조회해주세요.`, 'info');
            return false;
        } 
        if (!this.emailWinPopup[type]) {
            this.emailWinPopup[type] = this.$openWindow('E030201P01','_blank','1600','900');
            this.emailWinPopup[type].addEventListener("unload", e => { this.closedSendEmail(e, type) });
            if (this.geCustInfo && this.geCustInfo.CLNT_NO) {
                window.onmessage = this.loadedWinPopup;
                this.emailAddress = type == 1 ? this.accInfo.EMAIL_ADDR : (type == 2 ? this.repAccInfo.EMAIL_ADDR : null);
            }
        }
    },
    loadedWinPopup: function(e) {
        if (typeof e.data == "string") {
            let data = JSON.parse(e.data);
            if (data.evt == "emailPopupLoaded") {
                let info = {
                    custNo: this.geCustInfo.CLNT_NO,
                    custName: this.geCustInfo.CNM,
                    emailAddress: this.emailAddress,
                }
                window.emailPopup.setCustomer(info);
                window.onmessage = null;
                this.emailAddress = null;
            }
        }
    },
    closedSendEmail: function(e, type) {
        if (!this.isInitEmailWinPopup[type] && this.emailWinPopup[type]){
            this.emailWinPopup[type].close();
            this.emailWinPopup[type] = null;
            this.isInitEmailWinPopup[type] = true;
        }
        else this.isInitEmailWinPopup[type] = false;
    },
    srchAddrInf() {
        let tabId     = "";
        let account   = "";
        let accountPw = "";

        if(this.tabAccount == 0) {
           tabId = "1";
           account   = this.accInfo.ACCOUNT
           accountPw = this.accInfo.ACCOUNT_PW
        }
        if(this.tabAccount == 1) {
           tabId = "2";
           account = this.repAccInfo.ACCOUNT
           accountPw = this.repAccInfo.ACCOUNT_PW
        }

        if(this.mixin_isEmpty(account)) {
            console.log("**account" + account);
            return;
        }

        let item = {
            type: tabId
           ,acntNo: account
           ,accountPw: accountPw
        }

        this.$emit('chgAddr', item);
    },

    async fnCnsltHistCheck(val) {
      console.log(">>>>>>>>>>>>>>>>>>>>>>JJJJJJJ");

      let updateYN = false;

      let requestData1 = {
        headers: {
          SERVICE: 'phone.main.cnslt-enddttm',
          METHOD: 'inquire',
          TYPE: "BIZ_SERVICE",
        },
        sendData: {}
      };

      requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
      requestData1.headers["METHOD"] = "select";
      requestData1.headers["ASYNC"] = false;
      
      requestData1.sendData['CNSL_BEGIN_DATE'] = this.geCtiInfo.END_DT;
      requestData1.sendData['ASP_CUST_KEY'] = '001';
      requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
      requestData1.sendData['END_YN']  = val;
      requestData1.sendData['REG_ID']  = this.geUserRole.userId;

      const response1 = await this.common_postCall_noPB(requestData1);

      if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
        updateYN = true;
      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>KKKKKK"+ updateYN);
      return updateYN;
    },

    /* 전화걸기 */
    async callPop(type){
        let callNo = "";
        let confMsg = "";

        //휴대전화
        if(type==1) {
            confMsg = "휴대전화 : "
            callNo = this.accInfo.MOB_TNO;
        //자택전화
        } else if(type==2) {
            confMsg = "자택전화 : "
            callNo = this.accInfo.HOME_TNO;
        //직장전화
        } else if(type==3) {
            confMsg = "직장전화 : "
            callNo = this.accInfo.OFFC_TNO;
        } else if(type==4) {
            confMsg = "휴대전화 : "
            callNo = this.repAccInfo.MOB_TNO;
        } else if(type==5) {
            confMsg = "자택전화 : "
            callNo = this.repAccInfo.HOME_TNO;
        } else if(type==6) {
            confMsg = "직장전화 : "
            callNo = this.repAccInfo.OFFC_TNO;
        }

        if(this.mixin_isEmpty(callNo) || callNo.length < 9) {
            this.common_alert(`전화번호가 없습니다.`, 'error');
            return false;
        }
        console.log("@@CTI:"+ this.geCallStt);
        console.log(this.geCtiInfo)

        if(this.geCallStt == "LOGOUT") {
            this.common_alert(`CTI 로그아웃 상태입니다.`, 'error');
            return false;
        }

        if(this.geCallStt == 'HOLD') {
            this.common_alert(`통화중입니다.`, 'info');
            return false;
        }

        if(this.geCallStt == 'TALKING') {
            this.common_alert(`통화중입니다.`, 'info');
            return false;
        }

        var cnsltHistCheck = await this.fnCnsltHistCheck("Y")

        if(this.geCallStt == "A_SETACW" && this.geCnslSaveYn == "N") {
            if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || cnsltHistCheck == false){
            this.common_alert(`상담이력을 저장해주세요.`, 'info');
            return false;
            }else{
            this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
            }
        }

        let phnNoFrm = this.mixin_newPhoneFormat(callNo);
        this.$store.dispatch("callStore/AC_CUST_INOUT", "OUT");
        confMsg = confMsg + phnNoFrm + " 로 \n전화를 거시겠습니까?"

        this.common_confirm(confMsg, async () => {
            eventBus.makeCall(callNo);
            console.log(phnNoFrm + "전화를 걸었습니다.");
        }, null, () => {
            return;
        }, null, 'confirm');   
    },
    changeInp(type, val) {
        //val = val.replaceAll("-","");

        if(type == 'phn1') {
            //this.accInfo.PHONE_NO = this.mixin_newPhoneFormat(val);
        } else if(type == 'phn2') {
            //this.repAccInfo.PHONE_NO = this.mixin_newPhoneFormat(val);
        }
    },
    keyUpInp(type, val) {
        if(!this.mixin_isEmpty(val)) {
            let numPttn = /[a-z]|[.,<>/?;:{}~!@\#$%^&*\()\=+_']/gi;
            val =  val.replace(numPttn, "").replaceAll("-","");

            if(val.length >= 10) {
                val = this.mixin_newPhoneFormat(val);
            }

            if(type == 'phn1') this.accInfo.PHONE_NO = this.mixin_newPhoneFormat(val);
            if(type == 'phn2') this.repAccInfo.PHONE_NO = this.mixin_newPhoneFormat(val);
        }

    },
    /* 폰넘버저장 */
    phoneNumSave(type){
        let callType = this.accInfo.CALL_TYPE;
        if((this.accInfo.ACNT_STT_CD == "02" || this.accInfo.ACNT_STT_CD == "03" || this.accInfo.ACNT_STT_CD == "11") && callType != "09"  ) {

            let acntSttNm = "";
            
            if(this.accInfo.ACNT_STT_CD == "02") acntSttNm = "통폐합"
            else if(this.accInfo.ACNT_STT_CD == "03") acntSttNm = "폐쇄"
            else if(this.accInfo.ACNT_STT_CD == "11") acntSttNm = "폐쇄"

            this.common_alert(acntSttNm + `계좌는 폰넘버메모리서비스 \n등록이 불가합니다.`, 'info')
            return false
        }

        if(this.accInfo.ACCT_CONF != "Y" || this.mixin_isEmpty(this.accInfo.ACCOUNT_PW)) {
            this.common_alert(`계좌비밀번호 인증 후 등록 가능합니다.`, 'info')
            return false;
        } 

        let phnNum = this.accInfo.PHONE_NO;

        if(callType != "09"){

            if(this.mixin_isEmpty(phnNum)) {
                this.common_alert(`폰넘버를 선택 해주세요.`, 'info')
                return false
            }

            phnNum = phnNum.replaceAll("-", "");
            if(phnNum.length < 9) {
                this.common_alert(`폰넘버는 9자리 이상 입력해주세요.`, 'error')
                return false
            }
        } 

        if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO) ) {
            this.common_alert(`고객번호를 조회해주세요.`, 'error')
            return false
        }

        let addMsg = "";
        if(!this.mixin_isEmpty(this.geCustInfo.DIRECT_ACNT_NO) && this.accInfo.CALL_TYPE != "09") {
            addMsg = "계좌번호 " + this.mixin_accNoFormat(this.geCustInfo.DIRECT_ACNT_NO) + "에 이미 \n 등록 된 폰넘버가 있습니다.\n" 
            this.common_alert(addMsg, 'error');
            return false
        }

        let confMsg = "등록 하시겠습니까?";

        let phnYn = "Y";
        if(callType == "09") {
            phnYn = "N";
            confMsg = "해지 하시겠습니까?";
        }

      this.common_confirm('폰넘버메모리서비스를 ' + confMsg, async () => {
        let URLData = '/api/phone/main/phnNum/insert';
        let HEADER_SERVICE = 'phone.main.phnNum';
        let HEADER_METHOD = 'regist';
        let HEADER_TYPE = 'BIZ_SERVICE';


        this.isLoading = true;

        callType = this.accInfo.CALL_TYPE;
        if(callType == "09") callType="";
        // let phnYn = "N";
        // if(this.accInfo.PHN_NUM_YN) phnYn = "Y";

        // header 세팅
        let requestData = {
                headers: {
                ASYNC: false,
                METHOD: "save",
                SERVICE: "phone.main.phnNum",
                TYPE: "BIZ_SERVICE",
                URL: "/api/phone/main/phnNum/insert"
            },
            sendData: {},
        };
        requestData.sendData.CUST_KEY = this.ASP_CUST_KEY;
        requestData.sendData.CUSTOMER_ID = this.geCustInfo.CLNT_NO;
        requestData.sendData.ACCT_NO = this.accInfo.ACCOUNT.replaceAll("-","");
        requestData.sendData.CALL_TYPE = callType;
        requestData.sendData.DIRECT_CALL_YN = phnYn;
        requestData.sendData.DIRECT_TNO = phnNum;
        requestData.sendData.REG_ID = this.geUserRole.userId;

        //console.log('저장할 폰넘버정보 = ' + JSON.stringify(requestData.sendData));
        //폰넘버 저장 끝낸 후에는 콜백, 스케줄을 다시 조회한다.
        this.common_postCall(requestData).then(res => {
            this.isLoading = false;
            if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                this.common_alert(`폰넘버메모리서비스 등록 중 오류가 발생하였습니다.`, "error");
                return false;
            }
            //this.common_alert("정상 처리되었습니다.", "done");

            console.log(">>>" + JSON.stringify(this.accInfo));

            if(!this.mixin_isEmpty(this.accInfo.ACNT_NO)) {
                let custInfo = {};
                custInfo.ACCOUNT = this.accInfo.ACNT_NO.replaceAll("-","");
           
                this.$emit('selCust', custInfo);
            }
            //this.search();
        });
      }, null, () => {
        return;
      }, null, 'confirm');
    },
    changeHomeAdrType: async function (gbnCd, type, ADDR){
        //console.log(gbnCd + "/" + type + "/" + ADDR + "/");
        if(this.chkAddr(ADDR)) {
            if (type == 'N') {
                type = "Y";
            } else if (type == 'Y') {
                type = "N";
            }

            if (gbnCd == '1') {
                this.accInfo.HOME_ADDR_TYPE = type;
            } else if (gbnCd == "2") {
                this.accInfo.OFFC_ADDR_TYPE = type;
            } else if (gbnCd == "3") {
                this.repAccInfo.HOME_ADDR_TYPE = type;
            } else if (gbnCd == "4") {
                this.repAccInfo.OFFC_ADDR_TYPE = type;
            }
            this.common_toast(`정확한 정보를 가져올 수 없습니다.`, 'error');
            return false;
        }

        if(this.mixin_isEmpty(ADDR)) {
          this.common_toast(`주소를 확인해주세요.`, 'info');

          if(type == "N") type = "Y";
          else type = "N";

          if(gbnCd == "1") {
            this.accInfo.HOME_ADDR_TYPE = type;
          } else if(gbnCd == "2") {
            this.accInfo.OFFC_ADDR_TYPE = type;
          } else if(gbnCd == "3") {
            this.repAccInfo.HOME_ADDR_TYPE = type;
          } else if(gbnCd == "4") {
            this.repAccInfo.OFFC_ADDR_TYPE = type;
          }

          return false;
        }

        let srchAddr = this.getAddr(ADDR);

        let URLData = '/api/phone/main/post-addr/inqire';
        let HEADER_SERVICE = 'phone.main.post-addr';
        let HEADER_METHOD = 'inqire';
        let HEADER_TYPE = 'BIZ_SERVICE';
                
        let params = {}
        params.ADDR_NO = srchAddr;
        params.szCont = 'N';
        params.ASP_CUST_KEY = this.ASP_CUST_KEY;

        await api
        .post(URLData, params,
        {
            head: {
                SERVICE: HEADER_SERVICE,
                METHOD: HEADER_METHOD,
                TYPE: HEADER_TYPE,
                ASYNC: false,
            },
        })
        .then((res) => {
            if(res.data.HEADER.ERROR_FLAG) {
               // this.common_toast(res.data.HEADER.ERR_MSG, 'error');
                console.log("%%%%%changeHomeAdrType 조회 오류 발생!!" + res.data.HEADER.ERR_MSG );

                if(type == "N") type = "Y";
                else type = "N";

                if(gbnCd == "1") {
                    this.accInfo.HOME_ADDR_TYPE = type;
                } else if(gbnCd == "2") {
                    this.accInfo.OFFC_ADDR_TYPE = type;
                } else if(gbnCd == "3") {
                    this.repAccInfo.HOME_ADDR_TYPE = type;
                } else if(gbnCd == "4") {
                    this.repAccInfo.HOME_ADDR_TYPE = type;
                }                                                                                                                                                                                                                                                                                                                                                                                                                                                             

                return false;
            }

            if(!this.mixin_isEmpty(res.data.DATA) && res.data.DATA.length > 0) {

                if (res.data.DATA.length > 1) {
                    this.common_toast(`정확한 정보를 가져올 수 없습니다.`, 'error');
                } else {
                    // N:지번, Y:도로명
                    let resAddr = "";
                    
                    if(type == "N") resAddr = res.data.DATA[0].zLotNoAddr
                    if(type == "Y") resAddr = res.data.DATA[0].zAbroadAddr
    
                    let result = this.getAddr(resAddr.trimEnd());
    
                    if(gbnCd == "1") {
                        this.accInfo.HOME_ADDR = result;
                    } else if(gbnCd == "2") {
                        this.accInfo.OFFC_ADDR = result;
                    } else if(gbnCd == "3") {
                        this.repAccInfo.HOME_ADDR = result;
                    } else if(gbnCd == "4") {
                        this.repAccInfo.HOME_ADDR = result;
                    }
                }
            }
            //this.common_toast("성공적으로 조회 했습니다.", 'success');
        })
        .catch((err) => {
            this.common_alert('주소 정보 조회 중 오류.<br>잠시후에 다시 시도해주세요.', 'error');
            console.log(err);
        });
    },
    chkAddr(addr) {
        const splAddr = addr.split(' ');
        let srchNo = 0;
        let result = false;

        if(/[0-9]/.test(splAddr[splAddr.length-1])) {
            srchNo = splAddr.length-1;
        } else if (/[0-9]/.test(splAddr[splAddr.length-2])) {
            srchNo = splAddr.length-2;
        }

        if(srchNo == 0) {
            result = true;
        }

        return result;
    },
    getAddr(addr) {

        const splAddr = addr.split(' ');
        let srchAddr = '';
        let srchNo = 0;

        if(/[0-9]/.test(splAddr[splAddr.length-1])) {
            srchNo = splAddr.length-1;
        } else if (/[0-9]/.test(splAddr[splAddr.length-2])) {
            srchNo = splAddr.length-2;
        }

        splAddr.forEach((item, idx) => {
            if(idx <= srchNo) {
                srchAddr = srchAddr + item + ' ';
            }
        });

        return srchAddr;
    }
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geCustInfo: 'callStore/GE_CUST_INFO',
      geCallStt: 'callStore/GE_CALL_STATE',
      geCtiInfo: 'callStore/GE_CTI_INFO',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
      geCustInfoPrev: 'callStore/GE_CNSL_CUST_PREV',      

    }),
  },
};
</script>

<style></style>