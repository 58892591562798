<template>
  <div class="jh-form-wrap is-mt-10">
      <v-data-table
          class="jh-grid"
          :headers="headers"
          :items="custSpstList"
          :items-per-page="50"
          fixed-header
          item-key="ROW_NUMBER"
          height="88px"
          singleSelect
          @click:row="rowClick"
          hide-default-footer>            
          <template v-slot:item.contents="{ item }"><div class="is-ellips" :title="item.text">{{ item.contents }}</div></template>
      </v-data-table>
      <transition name="slide-down">
          <div class="jh-drop-layer" style="top:5px;right:5px;width:300px;height:130px;" v-show="Special">
            <div class="jh-drop-layer-header">
              <h2>특이내역수기</h2>
              <div class="is-right">
                <v-btn class="jh-btn is-icon" @click="Special=false"><i class="jh-icon-close"></i></v-btn>
              </div>
            </div>
            <div class="jh-drop-layer-body is-p-10">{{selectedContents}}</div>
          </div>
      </transition>

      <div class="jh-ui-header is-mt-10">
          <h3>고객정보(MEMO)</h3>
          <div class="is-right">
              <label :class="regManClassNm">{{REG_INF}}</label>
              <v-btn class="jh-btn is-main" :loading="isLoading" @click="saveCusMemoHst()">저장</v-btn>
              <v-btn class="jh-btn" @click="openP100901()"><i class="jh-icon-popup"></i><span>이력</span></v-btn>
          </div>
      </div> 
      <textarea class="jh-form" style="height: 60px;" v-model="custHist.CUSTOMER_MEMO" placeholder="고객정보를 입력하세요"></textarea>     
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import { mapGetters } from "vuex"

export default {
name: "CustHistory",
mixins: [mixin],
props: {
    param:{
      type: Object,
      default: {}
    },
    custSpstList:{
      type: Array,
      default: []
    },
    custId:{
      type: String,
      default: []
    },
  },
components: {
},
data() {
    return {
      headers: [
          { text: 'NO', value: 'ROW_NUMBER', align: 'center', width: '30px', sortable: false },
          { text: '특이내역 신통[8-0113] - 최근 3년 이내 수기작성 데이터', value: 'zMemoCnts', sortable: false },
          { text: '등록일자', value: 'zRegDt', align: 'center', width: '100px', sortable: false },
      ],
      gridCustMemoItems:[],
      // items: [
      //     {
      //         contents: 'ARS연결 요청 > 계좌비밀번호 연결을 의미하는 고객1',
      //         date: '2018-05-11',
      //     },
      //     {
      //         contents: 'ARS연결 요청 > 계좌비밀번호 연결을 의미하는 고객2',
      //         date: '2022-05-11',
      //     },
      //     {
      //         contents: 'ARS연결 요청 > 계좌비밀번호 연결을 의미하는 고객3',
      //         date: '2018-05-11',
      //     },
      //     {
      //         contents: 'ARS연결 요청 > 계좌비밀번호 연결을 의미하는 고객4',
      //         date: '2022-05-11',
      //     },
      // ],
      isLoading: false,
      custHist: {},
      
      USER_NM: '',
      USER_ID: '',
      REG_INF: '',
      Special: false,
      selectedContents: null,
      regManClassNm: 'jh-label',
    }
},
computed: {
  ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geCustInfo: 'callStore/GE_CUST_INFO'
  }),
  rowNum() {
    return this.items.map(
      (items, index) => ({
      ...items,
      index: index + 1
    }))
  },
},
methods: {
  async initData() {
    this.REG_INF = "";

    this.custHist = {
      CUST_NO: this.geCustInfo.CLNT_NO,
      CUSTOMER_MEMO: '',
      REG_MAN: this.USER_ID
    }
    this.selectCnslMemo(this.geCustInfo.CLNT_NO);
  },
  openP100901(){
    if(!this.custId){
      this.common_alert("조회된 고객번호가 없습니다.", "error");
      return false;
    }

    this.$openWindow('P100901','P100901','1000','650');
  },
  rowClick: function (item, row) { 
      if(this.selectedId===item.index){
          row.select(false);
          this.Special=false;
          this.selectedId=item.name;
      }else{
          this.selectedId=item.index;
          row.select(true);
          this.Special=true;
          this.selectedContents = item.contents;
      }
  },
  selectCusSpst(custHis){
    console.log("------------------------------------------------");
    console.log(custHis)

  },
  saveCusMemoHst : function() {
    if(this.mixin_isEmpty(this.custHist.CUST_NO)){
      this.common_alert("고객번호는 필수입니다.", "error");
      return false;
    }

    this.isLoading = true;

    // header 세팅
    let requestData = {
        headers: {
        ASYNC: false,
        METHOD: "save",
        SERVICE: "phone.main.cus-memo",
        TYPE: "BIZ_SERVICE",
        URL: "/api/phone/main/cus-memo/insert"
      },
      sendData: {},
    };

    requestData.sendData = this.custHist;
    requestData.sendData["REG_MAN"] = this.USER_ID;

    this.common_postCall(requestData).then(res => {
        this.isLoading = false;
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        }
        this.common_alert("정상 처리되었습니다.", "done");
        //this.search();
        this.initData();
      });
  },     
  /* 고객메모 조회 */
  selectCnslMemo: async function(custNo){
    if(this.mixin_isEmpty(custNo) ) {
      return false;
    }
    // header 세팅
    let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "inqire",
          SERVICE: "phone.cusMemo.inqire",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/cus-memo/inqire"
        },
        sendData: {
          CUST_NO: custNo
        },
    };
  
    //requestData.sendData["CNSL_HIST_NO"] = cnslHNo;
    
    this.common_postCall(requestData).then(res => {
      if (!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
          this.regManClassNm = 'jh-label has-dot';
          this.REG_INF = res.DATA[0].REG_MAN;
          this.custHist.CUSTOMER_MEMO = this.restoreXSS(res.DATA[0].CUSTOMER_MEMO).replaceAll("<BR>", "\r");
        } else {
          this.regManClassNm = 'jh-label';
          this.REG_INF = "";
          this.custHist.CUSTOMER_MEMO = "";
        }
      } else {
        this.common_toast(`시스템에 오류가 발생하였습니다.`, 'error')
      }
      this.common_toast("정상 처리되었습니다.", "done");
    });
  },
},
watch: {
  custId: function (nMsg, oMsg) {
    this.regManClassNm = 'jh-label';
    this.custHist = {};
    this.custHist.CUST_NO = nMsg;
    this.custHist.REG_MAN     = "";

    this.REG_INF="";
    this.selectCnslMemo(nMsg);
  }
},
mounted() { 
  this.ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ASP_CUST_KEY"];
  this.USER_ID = this.$store.getters["userStore/GE_USER_ID"];
  this.USER_NM = this.$store.getters["userStore/GE_USER_NAME"];

  this.initData();
}
};
</script>

<style></style>